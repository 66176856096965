@font-face {
  font-family: "trajan-pro-bold";
  src: url(../src/assest/fonts/Trajan-Pro-Bold.woff);
}

@font-face {
  font-family: "trajan-pro-regular";
  src: url(../src/assest/fonts/trajan-pro-regular.woff);
}
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

Body #vaLoans,
Body #vaLoans p {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol" !important;
}

#root {
  width: 100%;
}

body {
  font-size: 15px;
  background: #fff;
  color: #646c7a;
  margin: 0;
  font-family: "trajan-pro-bold" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-content: center;
  align-items: center;
  padding-top: 125px;
}

body p {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0;
  font-weight: normal;
  position: relative;
  padding: 0 0 10px 0;
  font-weight: normal;
  /* line-height: 120% !important; */
  color: #164686;
  margin: 0;
}

/* .btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */

/* .btn {
  font-size: 14px;
  border-radius: 2px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 20px 30px;
  font-weight: 600;
} */
.rounded {
  border-radius: 0.25rem !important;
}
/* .btn-default {
  background-color: #164686;
  color: #fff;
  border-color: #2573cb;
} */

/* .btn-default:hover {
  background-color: #fff;
  color: #164686;
  border-color: #2573cb;
} */

a
 {
  text-decoration: none !important;
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#banner-content .fl-row-content-wrap {
  padding-top: 100px;
  padding-bottom: 100px;
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  border: none;
  line-height: normal;
  vertical-align: middle;
}

.App {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}

.contact-card {
  text-align: center;
  box-sizing: border-box;
  max-width: 25%;
  display: inline-block;
  position: relative;
  padding: 10px;
}

.team-img,
.team-one .team-thumb {
  position: relative;
  overflow: hidden;
}

.team-thumb {
  box-shadow: 2px 2px 8px rgba(152, 152, 152, 0.79);
}
.team-details {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 20px 10px 10px;
}

.img-circle {
  border-radius: 50%;
}

.img-whp {
  width: 100% !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

#OurStory-wrapper h2,
#OurProcess-wrapper h2,
#leadership-wrapper h2,
#page-wrapper h2 {
  color: #212529;
  font-size: 2rem;
  font-family: "trajan-pro-bold" !important;
}

#OurStory-wrapper h3,
#OurProcess-wrapper h3,
#leadership-wrapper h3,
#page-wrapper h3 {
  color: #212529;
  font-family: "trajan-pro-bold" !important;
}

#productLoans .pp-dual-button-1 a.pp-button,
#productLoans .pp-dual-button-2 a.pp-button {
  background-color: #2d3691 !important;
}

#mainBanner {
  max-width: 100%;
  overflow-x: hidden;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter .fade-enter-active {
  opacity: 1;
  transition: opacity 150ms linear 150ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit .fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

.scrollToTop {
  /* overflow-y: scroll; */
  height: 2500px;
  bottom: 0px !important;
  background-color: #333 !important;
  border-radius: 4px 4px 0 0 !important;
  height: 32px !important;
  position: fixed;
  right: 75px;
  width: 48px !important;
  opacity: 0.9 !important;
  transition: all 0.2s ease-in-out 0s !important;
  padding: 15px !important;
  padding-top: 5px !important;
  cursor: pointer;
}
.scrollToTop:hover {
  background-color: #104fa0 !important;
}
#apply-btn-styl .pp-button {
  font-size: 20px;
  background-color: #104fa0;
  color: #fff;
  padding: 5px 45px;
  border:none;
}
#apply-btn-styl .pp-button:hover {
  background-color: #28ace2;
}

@media only screen and (max-width: 800.97px) {
  #team-wrapper {
    margin-top: 150px;
  }

  .fl-col-small {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 577.97px) {
  #team-wrapper h1 {
    font-size: 2.2rem;
  }
}

@media only screen and (max-width: 450.97px) {
  #team-wrapper h1 {
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 450.97px) {
  #team-wrapper h1 {
    font-size: 1.75rem;
  }
}
/* .btn-link {
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  text-decoration: none !important;
} */

@media only screen and (min-width: 320px) and (max-width: 365px) {
 
    #ModalWrapper .formHeader {
    text-align: left !important;
    }
    #ModalWrapper .formHeader {
      padding: 0 0px !important;
    }
}