.guiding img {
    width: 110px;
    margin-top: 5px;
    margin-bottom: 0px;
}

.guiding .fastClosing {
    width: 100px!important;
}

.guiding .infobox-title {
    font-family: Arial,Helvetica, Verdana, sans-serif;
    font-weight: 400;
    font-size: 18px!important;
    line-height: 20px;
    padding: 0;
}

.guiding p {
    color: #2d3691;
    font-size: 16px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.box-1 h4 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.box-2 h4 {
    margin-top: 21px;
    margin-bottom: 10px;
}

.box-3 h4 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.apply_today_btn .pp-button-wrap .pp-button::before, .apply_today_btn .pp-button-wrap .pp-button::after {
    content: '';
    height: 90px;
    width: 100px;
    display: block;
    background: url('../images/arrow_left.png');
    left: -130px;
    z-index: 1;
    top: -45px;
    position: absolute;
}

.apply_today_btn .pp-button-wrap .pp-button::after {
    left: auto;
    right: -130px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}