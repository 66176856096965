#subscribe-wrapper .form_title {
    font-size: 30px;
    color: #104fa0;
    font-weight: bold;
    font-family: arial;
    line-height: 42px;
    letter-spacing: 2.25px;
    margin-right: 30px;
    text-align: center;
}

.gform_wrapper select,
.gform_wrapper textarea {
    font-size: inherit;
    font-family: inherit;
    letter-spacing: normal
}

.gform_wrapper .gf_progressbar:after,
.gform_wrapper ol.validation_list:after {
    content: ""
}

.gform_wrapper {
    margin: 16px 0;
    max-width: 100%
}

.gform_wrapper form {
    text-align: left;
    max-width: 100%;
    margin: 0 auto
}

.gform_wrapper *,
.gform_wrapper :after,
.gform_wrapper :before {
    box-sizing: border-box !important
}

.gform_wrapper h1,
.gform_wrapper h2,
.gform_wrapper h3 {
    font-weight: 400;
    border: none;
    background: 0 0
}

.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    font-size: inherit;
    font-family: inherit;
    padding: 5px 4px;
    letter-spacing: normal
}

.gform_wrapper input[type=image] {
    border: none !important;
    padding: 0 !important;
    width: auto !important
}

.gform_wrapper textarea {
    padding: 6px 8px;
    line-height: 1.5;
    resize: none
}

.gform_wrapper select {
    line-height: 1.5
}

.gform_wrapper .ginput_container_multiselect select {
    background-image: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: 0
}

.gform_wrapper ul.gform_fields {
    margin: 0 !important;
    list-style-type: none;
    display: block
}

.gform_wrapper ul,
html > body .entry ul {
    text-indent: 0
}

.gform_wrapper form li,
.gform_wrapper li {
    margin-left: 0 !important;
    list-style: none !important;
    overflow: visible
}

.gform_wrapper ul li.gfield {
    clear: both
}

.gform_wrapper ul.gfield_checkbox li,
.gform_wrapper ul.gfield_radio li {
    overflow: hidden
}

.gform_wrapper form ul.gform_fields.left_label li.gfield,
.gform_wrapper form ul.gform_fields.right_label li.gfield,
.gform_wrapper ul.gform_fields.left_label li.gfield,
.gform_wrapper ul.gform_fields.right_label li.gfield {
    margin-bottom: 14px
}

.gform_wrapper form ul.left_label li ul.gfield_checkbox li,
.gform_wrapper form ul.left_label li ul.gfield_radio li,
.gform_wrapper form ul.right_label li ul.gfield_checkbox li,
.gform_wrapper form ul.right_label li ul.gfield_radio li,
.gform_wrapper ul.left_label li ul.gfield_checkbox li,
.gform_wrapper ul.left_label li ul.gfield_radio li,
.gform_wrapper ul.right_label li ul.gfield_checkbox li,
.gform_wrapper ul.right_label li ul.gfield_radio li {
    margin-bottom: 10px
}

.gform_wrapper ul li:after,
.gform_wrapper ul li:before,
.gform_wrapper ul.gform_fields {
    padding: 0;
    margin: 0;
    overflow: visible
}

.gform_wrapper select option {
    padding: 2px;
    display: block
}

.gform_wrapper .inline {
    display: inline !important
}

.gform_wrapper .gform_heading {
    width: 100%;
    margin-bottom: 18px
}

.gform_wrapper .clear-multi {
    display: flex
}

.gform_wrapper .gfield_date_day,
.gform_wrapper .gfield_date_year {
    display: -moz-inline-stack;
    display: inline-block
}

.gform_wrapper .gfield_date_day,
.gform_wrapper .gfield_date_month,
.gform_wrapper .gfield_date_year {
    flex-direction: row
}

.gform_wrapper .gfield_date_dropdown_day,
.gform_wrapper .gfield_date_dropdown_month,
.gform_wrapper .gfield_date_dropdown_year {
    vertical-align: top;
    flex-direction: row;
    margin-right: .5rem
}

.gform_wrapper .gfield_date_day,
.gform_wrapper .gfield_date_month {
    width: 4rem;
    margin-right: .5rem
}

.gform_wrapper .gfield_date_day input[type=number],
.gform_wrapper .gfield_date_month input[type=number] {
    width: calc(3rem + 8px) !important
}

.gform_wrapper .gfield_date_year input[type=number] {
    width: calc(4rem + 8px) !important
}

.gform_wrapper .gfield_date_year {
    width: 5rem
}

.gform_wrapper .gfield_date_day input,
.gform_wrapper .gfield_date_month input {
    width: calc(3rem + 8px)
}

.gform_wrapper .gfield_date_year input {
    width: 4rem
}

.gform_wrapper .field_sublabel_above div[class*=gfield_date_].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*=gfield_date_].ginput_container label {
    width: 3rem;
    text-align: center
}

.gform_wrapper .field_sublabel_above div.gfield_date_year.ginput_container label,
.gform_wrapper .field_sublabel_below div.gfield_date_year.ginput_container label {
    width: 4rem
}

.gform_wrapper .gfield_time_ampm,
.gform_wrapper .gfield_time_hour,
.gform_wrapper .gfield_time_minute {
    width: 33.333%;
    flex-direction: row
}

@media only screen and (min-width:321px) {

    .gform_wrapper .gfield_date_day,
    .gform_wrapper .gfield_date_month {
        max-width: 4rem
    }

    .gform_wrapper .gfield_date_year,
    .gform_wrapper .gfield_time_ampm {
        max-width: calc(4rem + .5rem)
    }

    .gform_wrapper .gfield_time_hour,
    .gform_wrapper .gfield_time_minute {
        max-width: 4rem
    }

    .gform_wrapper .gfield_time_ampm,
    .gform_wrapper .gfield_time_hour {
        max-width: calc(4rem + .5rem)
    }
}

.gform_wrapper ul.gform_fields:not(.top_label) .ginput_container_time span {
    float: left
}

.gform_wrapper .gfield_time_hour i {
    font-style: normal !important;
    font-family: sans-serif !important;
    width: 10px;
    text-align: center;
    float: right;
    margin-top: 9%
}

.gform_wrapper .gfield_time_minute {
    margin-right: .5rem
}

.gform_wrapper .gfield_time_hour {
    margin-right: .25rem
}

.gform_wrapper .gfield_time_ampm {
    vertical-align: top;
    display: -moz-inline-stack;
    display: inline-block
}

.gform_wrapper .gfield_time_ampm select {
    min-width: calc(2rem + 25px);
    width: calc(3rem + 25px)
}

.gform_wrapper .gfield_time_ampm_shim {
    display: block;
    font-size: .813em;
    margin: 9px 0 1px 1px
}

.gform_wrapper .gfield_time_hour input,
.gform_wrapper .gfield_time_minute input {
    width: calc(3rem + 8px)
}

.gform_wrapper .gfield_time_hour input[type=number],
.gform_wrapper .gfield_time_minute input[type=number] {
    width: calc(3rem + 8px) !important
}

.gform_wrapper .field_sublabel_above div[class*=gfield_time_].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*=gfield_time_].ginput_container label {
    width: 3rem;
    text-align: center
}

.gform_wrapper .field_hover {
    border: 1px dashed #2175A9;
    cursor: pointer
}

.gform_wrapper .field_selected {
    background-color: #DFEFFF;
    border: 1px solid #C2D7EF
}

.gform_wrapper .field_name_first,
.gform_wrapper .field_name_last,
.gform_wrapper .field_name_middle {
    width: 49.5%;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top
}

.gform_wrapper .ginput_complex.ginput_container {
    overflow: visible;
    width: 100%
}

.gform_wrapper label.gfield_label,
.gform_wrapper legend.gfield_label {
    font-weight: 700;
    font-size: inherit
}

.gform_wrapper .top_label .gfield_label,
.gform_wrapper legend.gfield_label {
    display: -moz-inline-stack;
    display: inline-block;
    line-height: 1.3;
    clear: both
}

.gform_wrapper .top_label .gfield_hide_label {
    display: none
}

.gform_wrapper .ginput_complex .ginput_left input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]),
.gform_wrapper .ginput_complex .ginput_right input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]),
.gform_wrapper .ginput_complex .ginput_right select,
.gform_wrapper .ginput_complex input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]),
.gform_wrapper .ginput_complex select {
    width: 100%
}

.gform_wrapper .hidden_label .gfield_label,
.gform_wrapper label.hidden_sub_label,
.gform_wrapper label.screen-reader-text,
.ginput_container_consent button.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden
}

.gform_wrapper .field_sublabel_above .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_above div[class*=gfield_date_].ginput_container label,
.gform_wrapper .field_sublabel_above div[class*=gfield_time_].ginput_container label,
.gform_wrapper .field_sublabel_below .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_below div[class*=gfield_date_].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*=gfield_time_].ginput_container label {
    display: block;
    font-size: .813em;
    letter-spacing: .5pt;
    white-space: nowrap
}

.gform_wrapper ul.gfield_checkbox li label,
.gform_wrapper ul.gfield_radio li label {
    display: -moz-inline-stack;
    display: inline-block;
    letter-spacing: inherit;
    vertical-align: middle;
    cursor: pointer
}

.gform_wrapper .field_sublabel_above .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_above div[class*=gfield_time_].ginput_container label {
    margin: 9px 0 1px 1px
}

.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_email label,
.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_name label,
.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_password label,
.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_time label {
    margin-top: 0
}

.gform_wrapper .field_sublabel_below .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_below div[class*=gfield_time_].ginput_container label {
    margin: 1px 0 9px 1px
}

.gform_wrapper .top_label .field_sublabel_above label.gfield_label + .ginput_complex.ginput_container,
.gform_wrapper .top_label .field_sublabel_above label.gfield_label + div[class*=gfield_time_].ginput_container {
    margin-top: 0
}

body .gform_wrapper .top_label div.ginput_container {
    margin-top: 8px
}

body.gform_wrapper div.clear-multi {
    display: -moz-inline-stack;
    display: inline-block
}

body .gform_wrapper ul li.field_description_below div.ginput_container_checkbox,
body .gform_wrapper ul li.field_description_below div.ginput_container_radio {
    margin-top: 16px;
    margin-bottom: 0
}

.gfield.left_label,
.gfield.right_label {
    padding-top: 10px
}

.gform_wrapper li.hidden_label input {
    margin-top: 12px
}

.gform_wrapper .field_sublabel_hidden_label .ginput_complex.ginput_container input[type=text],
.gform_wrapper .field_sublabel_hidden_label .ginput_complex.ginput_container select {
    margin-bottom: 12px
}

.gform_wrapper input.large,
.gform_wrapper input.medium,
.gform_wrapper input.small,
.gform_wrapper select.large,
.gform_wrapper select.medium,
.gform_wrapper select.small {
    width: 100%
}

.gform_wrapper input.datepicker_with_icon.large,
.gform_wrapper input.datepicker_with_icon.medium,
.gform_wrapper input.datepicker_with_icon.small {
    width: calc(100% - 24px)
}

.gform_wrapper .gfield_error input.large,
.gform_wrapper .gfield_error input.medium,
.gform_wrapper .gfield_error input.small,
.gform_wrapper .gfield_error select.large,
.gform_wrapper .gfield_error select.medium,
.gform_wrapper .gfield_error select.small {
    width: 100%
}

.gform_wrapper .gfield_error input.datepicker_with_icon.large,
.gform_wrapper .gfield_error input.datepicker_with_icon.medium,
.gform_wrapper .gfield_error input.datepicker_with_icon.small {
    width: calc(100% - 32px)
}

.gform_wrapper textarea.small {
    height: 80px;
    width: 100%
}

.gform_wrapper textarea.medium {
    height: 120px;
    width: 100%
}

.gform_wrapper textarea.large {
    height: 160px;
    width: 100%
}

.gform_wrapper h2.gsection_title {
    margin: 0 !important;
    padding: 0 !important;
    letter-spacing: normal !important
}

.gform_wrapper .gsection .gfield_label,
.gform_wrapper h2.gsection_title,
.gform_wrapper h3.gform_title {
    font-weight: 700;
    font-size: 1.25em
}

.gform_wrapper h3.gform_title {
    letter-spacing: normal !important;
    margin: 10px 0 6px
}

.gform_wrapper li.gfield.field_description_below + li.gsection {
    margin-top: 24px !important
}

.gform_wrapper span.gform_description {
    font-weight: 400;
    display: block;
    width: calc(100% - 16px);
    margin-bottom: 16px
}

.gform_wrapper .gsection {
    border-bottom: 1px solid #CCC;
    padding: 0 16px 8px 0;
    margin: 28px 0;
    clear: both
}

.gform_wrapper ul.gfield_checkbox li input[type=checkbox],
.gform_wrapper ul.gfield_radio li input[type=radio] {
    width: auto !important
}

.gform_wrapper ul.gfield_checkbox,
.gform_wrapper ul.gfield_radio {
    margin: 6px 0;
    padding: 0
}

.gform_wrapper .gfield_checkbox li label,
.gform_wrapper .gfield_radio li label {
    display: -moz-inline-stack;
    display: inline-block;
    padding: 0;
    width: auto;
    line-height: 1.5;
    font-size: .875em;
    vertical-align: middle;
    max-width: 85%;
    white-space: normal
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left label,
.gform_wrapper .ginput_complex .ginput_cardinfo_right label,
.gform_wrapper div.charleft {
    white-space: nowrap !important
}

.gform_wrapper .gfield_checkbox li label {
    margin: 0
}

.gform_wrapper .gfield_radio li label {
    margin: 2px 0 0 4px
}

.gform_wrapper .gfield_checkbox li input,
.gform_wrapper .gfield_checkbox li input[type=checkbox],
.gform_wrapper .gfield_radio li input[type=radio] {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    margin-left: 1px
}

.gform_wrapper .description,
.gform_wrapper .gfield_description,
.gform_wrapper .gsection_description,
.gform_wrapper .instruction {
    font-size: .813em;
    line-height: inherit;
    clear: both;
    font-family: inherit;
    letter-spacing: normal
}

.gform_wrapper .description,
.gform_wrapper .gfield_description,
.gform_wrapper .gsection_description {
    padding: 0 16px 0 0
}

.gform_wrapper .field_description_below .gfield_description {
    padding-top: 16px
}

.gform_wrapper .field_sublabel_above .description,
.gform_wrapper .field_sublabel_above .gfield_description,
.gform_wrapper .field_sublabel_above .gsection_description {
    margin-top: 9px
}

.gform_wrapper .top_label .gsection_description {
    width: 100%;
    margin-bottom: 9px
}

.gform_wrapper .gfield_description {
    width: 100%
}

.gform_wrapper .description_above .gfield_description {
    padding: 0 0 10px
}

.gfield_date_year + .gfield_description {
    padding: 0
}

.gform_wrapper .field_description_below .gfield_consent_description,
.gform_wrapper .gfield_consent_description {
    width: 100%;
    max-height: 240px;
    overflow-y: scroll;
    border: 1px solid #ddd;
    margin-top: 12px;
    padding: 6px 8px;
    font-size: .688em
}

.gform_wrapper .gfield_required {
    color: #790000;
    margin-left: 4px
}

.gform_wrapper li.gfield.gfield_error,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    background-color: rgba(255, 223, 224, .25);
    margin-bottom: 6px !important;
    border-top: 1px solid #C89797;
    border-bottom: 1px solid #C89797;
    padding-bottom: 6px;
    padding-top: 8px;
    box-sizing: border-box
}

.gform_wrapper li.gfield.gfield_creditcard_warning {
    padding: 16px 16px 14px;
    border-top: 2px solid #790000;
    border-bottom: 4px solid #790000;
    background-color: rgba(255, 223, 224, .25);
    width: calc(100% - 16px)
}

.gform_wrapper li.gfield.gfield_error.gfield_creditcard_warning {
    padding: 16px 16px 14px
}

.gform_wrapper li.gfield.gfield_creditcard_warning div.gfield_creditcard_warning_message {
    font-size: .875em;
    font-weight: 700;
    font-family: inherit;
    min-height: 25px;
    color: #790000;
    max-width: 100%;
    position: relative
}

.gform_wrapper li.gfield.gfield_creditcard_warning div.gfield_creditcard_warning_message span {
    display: block;
    padding: 0 0 14px 24px;
    margin-bottom: 16px;
    line-height: 1.5;
    letter-spacing: .1pt;
    border-bottom: 1px solid #790000;
    text-transform: uppercase;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSItMTA2NyAyODY1IDI0IDMyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IC0xMDY3IDI4NjUgMjQgMzI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiM3OTAwMDA7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0tMTA1MywyODY1Yy01LjUsMC0xMCw0LjUtMTAsMTBoNGMwLTMuMywyLjctNiw2LTZjMy4zLDAsNiwyLjcsNiw2djJoLTIwdjE0YzAsMy4zLDIuNyw2LDYsNmgxMg0KCWMzLjMsMCw2LTIuNyw2LTZ2LTE2Qy0xMDQzLDI4NjkuNS0xMDQ3LjUsMjg2NS0xMDUzLDI4NjV6IE0tMTA0OSwyODkzaC0xMmMtMS4xLDAtMi0wLjktMi0ydi0xMGgxNnYxMA0KCUMtMTA0NywyODkyLjEtMTA0Ny45LDI4OTMtMTA0OSwyODkzeiBNLTEwNTMsMjg4N2MwLDEuMS0wLjksMi0yLDJzLTItMC45LTItMmMwLTEuMSwwLjktMiwyLTJTLTEwNTMsMjg4NS45LTEwNTMsMjg4N3oiLz4NCjwvc3ZnPg0K);
    background-size: 1em auto;
    background-repeat: no-repeat;
    background-position: left center
}

.gform_wrapper li.gfield.gfield_error.gfield_creditcard_warning div.gfield_creditcard_warning_message {
    padding: 16px 16px 14px
}

li.gfield + li.gfield.gfield_creditcard_warning {
    margin-top: 16px !important
}

.gform_wrapper .top_label .gfield_error {
    width: calc(100% - 2px)
}

.gform_wrapper .top_label .gfield_error input.large,
.gform_wrapper .top_label .gfield_error select.large,
.gform_wrapper .top_label .gfield_error textarea.textarea {
    width: 100%
}

.gform_wrapper .left_label .gfield_error input.large,
.gform_wrapper .left_label .gfield_error select.large,
.gform_wrapper .left_label .gfield_error textarea.textarea,
.gform_wrapper .right_label .gfield_error input.large,
.gform_wrapper .right_label .gfield_error select.large,
.gform_wrapper .right_label .gfield_error textarea.textarea {
    width: 70%
}

.gform_wrapper .gfield_error .gfield_label {
    color: #790000
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required {
    padding-right: 0
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_time_ampm.ginput_container,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_time_minute.ginput_container {
    margin-left: 0
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_description {
    max-width: 100%;
    padding-right: 16px
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label {
    margin-top: 12px
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required fieldset legend.gfield_label {
    position: relative;
    top: 12px
}

.gform_wrapper div.validation_error {
    color: #790000;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 25px;
    border-top: 2px solid #790000;
    border-bottom: 2px solid #790000;
    padding: 16px 0;
    clear: both;
    width: 100%;
    text-align: center
}

.gform_wrapper ol.validation_list {
    clear: both;
    width: 100%;
    margin-bottom: 25px;
    color: #790000
}

.gform_wrapper ol.validation_list li {
    list-style-type: decimal !important
}

.gform_wrapper ol.validation_list li a {
    color: #790000
}

.gform_wrapper div#error {
    margin-bottom: 25px
}

.gform_wrapper table.gfield_list tbody tr td.gfield_list_icons {
    vertical-align: middle !important
}

.gform_wrapper li.gfield fieldset {
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
    width: 100%
}

.gform_wrapper li.gfield fieldset > * {
    width: auto
}

.gform_wrapper li.gfield fieldset legend.gfield_label {
    width: 100%;
    line-height: 32px
}

div.gf_page_steps + div.validation_error {
    margin-top: 16px
}

.gform_wrapper div.gfield_description.validation_error {
    color: #790000;
    font-weight: 700;
    font-size: .875em;
    line-height: 1.2;
    margin-bottom: 16px
}

.gform_wrapper .validation_message {
    color: #790000;
    font-weight: 700;
    letter-spacing: normal
}

.gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
.gform_wrapper li.gfield_error textarea {
    border: 1px solid #790000
}

.gform_wrapper li.gfield_error div.ginput_complex.ginput_container label,
.gform_wrapper li.gfield_error ul.gfield_checkbox,
.gform_wrapper li.gfield_error ul.gfield_radio {
    color: #790000
}

.gform_wrapper .gform_footer {
    padding: 16px 0 10px;
    margin: 16px 0 0;
    clear: both;
    width: 100%
}

.gform_wrapper .gform_footer input.button,
.gform_wrapper .gform_footer input[type=submit],
.gform_wrapper .gform_page_footer input.button,
.gform_wrapper .gform_page_footer input[type=submit] {
    font-size: 1em;
    width: 100%;
    margin: 0 0 16px
}

.gform_wrapper .gform_footer a.gform_save_link,
.gform_wrapper .gform_page_footer a.gform_save_link {
    font-size: 1em;
    display: block;
    text-align: center
}

.gform_wrapper .gform_footer input[type=image] {
    padding: 0;
    width: auto !important;
    background: 0 0 !important;
    border: none !important;
    margin: 0 auto 16px !important;
    display: block;
    max-width: 100%
}

.gform_wrapper .ginput_complex .ginput_full,
.gform_wrapper .ginput_complex .ginput_left,
.gform_wrapper .ginput_complex .ginput_right {
    min-height: 45px
}

.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=email],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=number],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=password],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=tel],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=text],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=url],
.gform_wrapper .gfield_error .ginput_complex .ginput_full select,
.gform_wrapper .ginput_complex .ginput_full input[type=email],
.gform_wrapper .ginput_complex .ginput_full input[type=number],
.gform_wrapper .ginput_complex .ginput_full input[type=password],
.gform_wrapper .ginput_complex .ginput_full input[type=tel],
.gform_wrapper .ginput_complex .ginput_full input[type=text],
.gform_wrapper .ginput_complex .ginput_full input[type=url] {
    width: 100%
}

.gform_wrapper .gfield_checkbox li,
.gform_wrapper .gfield_radio li {
    position: relative;
    margin: 0 0 16px;
    line-height: 1.2
}

.gchoice_select_all,
.gform_wrapper ul.gfield_checkbox li input[type=checkbox]:checked + label,
.gform_wrapper ul.gfield_radio li input[type=radio]:checked + label {
    font-weight: 700
}

.gform_wrapper input.datepicker.datepicker_with_icon {
    margin-right: 4px !important;
    display: -moz-inline-stack;
    display: inline-block
}

.gform_wrapper .gf_hidden,
.gform_wrapper .gform_hidden,
.gform_wrapper input.gform_hidden,
.gform_wrapper input[type=hidden] {
    display: none !important;
    max-height: 1px !important;
    overflow: hidden
}

.gform_wrapper .gf_invisible,
.gform_wrapper .gfield_visibility_hidden {
    visibility: hidden;
    position: absolute;
    left: -9999px
}

.gform_wrapper .ginput_full br,
.gform_wrapper .ginput_left br,
.gform_wrapper .ginput_right br {
    display: none !important
}

.gform_wrapper ul.gfield_checkbox li,
.gform_wrapper ul.gfield_radio li {
    padding: 0 !important
}

.gform_wrapper ul.gfield_radio li input + input {
    margin-left: 4px
}

.gform_wrapper ul.gfield_radio li input[value=gf_other_choice] {
    margin-right: 6px;
    margin-top: 4px
}

.gform_wrapper .gfield_description + .gform_wrapper .gfield_description.validation_message {
    margin-top: 6px
}

.gform_wrapper .ginput_container.ginput_list + .gfield_description.validation_message {
    margin-top: 0
}

.gform_wrapper li.gfield_html {
    max-width: 100%
}

.gform_wrapper .gfield_html.gfield_no_follows_desc {
    margin-top: 10px
}

body .gform_wrapper ul li.gfield.gfield_html img {
    max-width: 100% !important
}

.gform_wrapper .gform_ajax_spinner {
    padding-left: 10px
}

.gform_validation_container,
.gform_wrapper .gform_validation_container,
body .gform_wrapper .gform_body ul.gform_fields li.gfield.gform_validation_container,
body .gform_wrapper li.gform_validation_container,
body .gform_wrapper ul.gform_fields li.gfield.gform_validation_container {
    display: none !important;
    position: absolute !important;
    left: -9000px
}

.gform_wrapper .gfield_captcha_input_container {
    padding-top: 3px
}

.gform_wrapper .simple_captcha_small input {
    width: 64px
}

.gform_wrapper .simple_captcha_medium input {
    width: 96px
}

.gform_wrapper .simple_captcha_large input {
    width: 128px
}

.gform_wrapper .gform_wrapper .left_label .simple_captcha_small,
.gform_wrapper .left_label .simple_captcha_large,
.gform_wrapper .left_label .simple_captcha_medium,
.gform_wrapper .right_label .simple_captcha_large,
.gform_wrapper .right_label .simple_captcha_medium,
.gform_wrapper .right_label .simple_captcha_small {
    margin-left: 32%
}

.gform_wrapper .gfield_captcha_container img.gfield_captcha {
    border: none !important;
    background: 0 0 !important;
    float: none !important;
    margin: 0 !important;
    padding: 0 !important
}

.gform_wrapper .left_label .math_large,
.gform_wrapper .left_label .math_medium,
.gform_wrapper .left_label .math_small,
.gform_wrapper .left_label div.charleft,
.gform_wrapper .right_label .math_large,
.gform_wrapper .right_label .math_medium,
.gform_wrapper .right_label .math_small,
.gform_wrapper .right_label div.charleft {
    margin-left: 32%
}

.gform_wrapper .math_small input {
    width: 70px
}

.gform_wrapper .math_medium input {
    width: 510px
}

.gform_wrapper .math_large input {
    width: 612px
}

.gform_wrapper div.charleft {
    font-size: .688em;
    margin-top: 4px;
    color: #B7B7B7;
    width: 92% !important
}

.gform_wrapper div.charleft[style] {
    width: 92% !important
}

.gform_wrapper div.charleft.ginput_counter_tinymce {
    border: 1px solid #eee;
    border-width: 1px 0 0;
    padding: 4px 9px;
    width: 100% !important
}

.gform_wrapper div.charleft.warningTextareaInfo {
    color: #A1A1A1
}

.gform_wrapper div.charleft.ginput_counter_error {
    color: red
}

.gform_wrapper li.gf_hide_charleft div.charleft {
    display: none !important
}

.gf_submission_limit_message {
    color: #790000;
    font-size: 1.375em
}

.gform_wrapper .ginput_price {
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7
}

.gform_wrapper span.ginput_total {
    color: #060;
    font-size: inherit
}

.gform_wrapper .top_label span.ginput_total {
    margin: 8px 0
}

.gform_wrapper span.ginput_product_price_label {
    margin-right: 2px
}

.gform_wrapper span.ginput_product_price {
    color: #900
}

.gform_wrapper span.ginput_quantity_label {
    margin-left: 10px;
    margin-right: 2px
}

.gform_wrapper input.ginput_quantity[type=text] {
    width: 3rem
}

.gform_wrapper input.ginput_quantity[type=number] {
    width: 4rem
}

.gform_wrapper .gform_page_footer {
    margin: 14px 0;
    width: 100%;
    border-top: 1px dotted #CCC;
    padding: 16px 0 0;
    clear: both
}

.gform_wrapper .gform_page_footer.left_label,
.gform_wrapper .gform_page_footer.right_label {
    padding: 16px 0 0 29%
}

.gform_wrapper .gform_page_footer .button.gform_next_button,
.gform_wrapper .gform_page_footer .button.gform_previous_button {
    display: -moz-inline-stack;
    display: inline-block
}

.gform_wrapper .gform_page_footer .button.gform_previous_button {
    margin-bottom: 8px
}

.gform_wrapper .gf_progressbar_wrapper {
    clear: both;
    width: 100%;
    margin: 0 0 16px;
    padding: 0 0 16px
}

.gform_wrapper .gf_progressbar_wrapper h3.gf_progressbar_title {
    font-size: .813em;
    line-height: 1 !important;
    margin: 0 0 8px 12px !important;
    padding: 0 !important;
    clear: both;
    filter: alpha(opacity=60);
    -moz-opacity: .6;
    -khtml-opacity: .6;
    opacity: .6
}

.gform_wrapper .gf_progressbar {
    padding: 10px;
    position: relative;
    background-color: rgba(0, 0, 0, .1);
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    border-radius: 25px;
    -webkit-box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, .05);
    -moz-box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, .05);
    width: 100%
}

.gform_wrapper .gf_progressbar_percentage {
    height: 24px;
    text-align: right;
    font-family: helvetica, arial, sans-serif;
    font-size: .813em !important;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .5);
    z-index: 999;
    vertical-align: middle;
    background: repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 10px, rgba(0, 0, 0, .1) 10px, rgba(0, 0, 0, .1) 20px), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .3));
    border-radius: 20px 4px 4px 20px
}

.gform_wrapper .gf_progressbar:after {
    display: block;
    width: 100%;
    z-index: 990;
    height: 24px;
    margin-top: -24px;
    background-color: rgba(0, 0, 0, .1);
    -webkit-box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, .05);
    -moz-box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, .05);
    box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, .05);
    border-radius: 20px
}

.gform_wrapper .gf_progressbar_percentage.percentbar_100 {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px
}

.gform_wrapper .gf_progressbar_percentage span {
    display: block;
    width: auto;
    float: right;
    margin-right: 5px;
    margin-left: 5px;
    line-height: 1.8
}

.gform_wrapper .gf_progressbar_percentage.percentbar_0 span {
    color: #959595;
    text-shadow: none;
    float: none !important;
    margin-left: 12px;
    word-wrap: normal
}

.gform_wrapper .percentbar_blue {
    background-color: #036493;
    color: #FFF
}

.gform_wrapper .percentbar_gray {
    background-color: #7C7C7C;
    color: #FFF
}

.gform_wrapper .percentbar_green {
    background-color: #88B831;
    color: #FFF
}

.gform_wrapper .percentbar_orange {
    background-color: #FF7120;
    color: #FFF
}

.gform_wrapper .percentbar_red {
    background-color: #FF2A1A;
    color: #FFF
}

.gform_wrapper .gf_page_steps {
    width: 100%;
    margin: 0 0 8px;
    padding: 0 0 4px;
    border-bottom: 1px dotted #CCC
}

.gform_wrapper .gf_step {
    width: 100%;
    margin: 24px 24px 24px 0;
    font-size: .875em;
    height: 14px;
    line-height: 1.25 !important;
    filter: alpha(opacity=20);
    -moz-opacity: .2;
    -khtml-opacity: .2;
    opacity: .2;
    font-family: inherit
}

.gform_wrapper .gf_step span.gf_step_number {
    font-size: 1.25em;
    font-family: arial, sans-serif;
    margin-right: 4px
}

.gform_wrapper .gf_step span.gf_step_label,
.gform_wrapper .gf_step span.gf_step_number {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle
}

.gform_wrapper .gf_step.gf_step_active {
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1
}

.gform_wrapper .gf_step_clear {
    display: block;
    clear: both;
    height: 1px;
    overflow: hidden
}

.gform_wrapper .gfield_password_strength {
    border: 1px solid #DDD;
    margin-top: 18px;
    margin-bottom: 18px;
    line-height: 1.8;
    padding: 10px 5px;
    text-align: center;
    background-color: #EEE;
    border-bottom: 3px solid #DDD
}

.gform_wrapper ul.top_label li.gfield.gfield_error.gfield_contains_required .gfield_password_strength {
    margin-right: 16px
}

.gform_wrapper ul.left_label li.gfield .gfield_password_strength,
.gform_wrapper ul.right_label li.gfield .gfield_password_strength {
    margin-top: 18px;
    margin-bottom: 18px;
    width: 100%
}

.gform_wrapper .gfield_password_strength.bad {
    background-color: #FFB78C;
    border-color: #FF853C;
    border-bottom: 3px solid #FF853C
}

.gform_wrapper .gfield_password_strength.good {
    background-color: #FFEC8b;
    border-color: #FC0;
    border-bottom: 3px solid #FC0
}

.gform_wrapper .gfield_password_strength.mismatch,
.gform_wrapper .gfield_password_strength.short {
    background-color: #FFA0A0;
    border-color: #f04040;
    border-bottom: 3px solid #f04040
}

.gform_wrapper .gfield_password_strength.strong {
    background-color: #C3FF88;
    border-color: #8DFF1C;
    border-bottom: 3px solid #8DFF1C
}

.gform_wrapper table.gfield_list,
.gform_wrapper table.gfield_list caption,
.gform_wrapper table.gfield_list tbody,
.gform_wrapper table.gfield_list td,
.gform_wrapper table.gfield_list tfoot,
.gform_wrapper table.gfield_list th,
.gform_wrapper table.gfield_list thead,
.gform_wrapper table.gfield_list tr {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline
}

.gform_wrapper table.gfield_list {
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: auto !important
}

.gfield_repeater_buttons {
    padding-top: 15px
}

.gform_wrapper table.gfield_list thead th {
    font-weight: 700;
    text-align: left
}

.gform_wrapper table.gfield_list colgroup {
    width: 100%;
    display: block
}

.gform_wrapper li.gfield.gfield_error table.gfield_list thead th {
    color: #790000
}

.gform_wrapper table.gfield_list thead,
.gform_wrapper table.gfield_list tr {
    padding: 0;
    margin: 0
}

.gform_wrapper table.gfield_list td,
.gform_wrapper table.gfield_list th {
    padding: 4px 0
}

.gform_wrapper ul.gform_fields:not(.top_label) table.gfield_list th {
    padding: 0 0 4px
}

.gform_wrapper table.gfield_list td + td,
.gform_wrapper table.gfield_list th + th {
    padding: 0 0 0 16px
}

.gform_wrapper .gfield_list,
.gform_wrapper .gfield_list td.gfield_list_cell input {
    width: 100%
}

.gfield_icon_disabled {
    cursor: default !important;
    filter: alpha(opacity=60);
    -moz-opacity: .6;
    -khtml-opacity: .6;
    opacity: .6
}

.gform_wrapper table.gfield_list tr td.gfield_list_icons {
    width: 48px !important;
    padding: 0 0 0 4px !important;
    box-sizing: border-box
}

.gform_wrapper table.gfield_list td.gfield_list_icons img {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle
}

.gform_wrapper .gform_card_icon_container {
    margin: 8px 0 6px;
    height: 32px
}

/* .gform_wrapper div.gform_card_icon {
    margin-right: 4px;
    text-indent: -9000px;
    background-image: url(../images/gf-creditcard-icons.png);
    background-repeat: no-repeat;
    width: 36px;
    height: 32px;
    float: left
} */

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_visa {
    background-position: 0 0
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_visa {
    background-position: 0 -32px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_visa {
    background-position: 0 -64px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_mastercard {
    background-position: -36px 0
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_mastercard {
    background-position: -36px -32px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_mastercard {
    background-position: -36px -64px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_amex {
    background-position: -72px 0
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_amex {
    background-position: -72px -32px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_amex {
    background-position: -72px -64px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_discover {
    background-position: -108px 0
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_discover {
    background-position: -108px -32px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_discover {
    background-position: -108px -64px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_maestro {
    background-position: -144px 0
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_maestro {
    background-position: -144px -32px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_maestro {
    background-position: -144px -64px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_jcb {
    background-position: -180px 0
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_jcb {
    background-position: -180px -32px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_jcb {
    background-position: -180px -64px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_visa {
    background-position: 0 -192px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_visa {
    background-position: 0 -224px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_visa {
    background-position: 0 -256px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_mastercard {
    background-position: -36px -192px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_mastercard {
    background-position: -36px -224px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_mastercard {
    background-position: -36px -256px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_amex {
    background-position: -72px -192px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_amex {
    background-position: -72px -224px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_amex {
    background-position: -72px -256px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_discover {
    background-position: -108px -192px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_discover {
    background-position: -108px -224px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_discover {
    background-position: -108px -256px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_maestro {
    background-position: -144px -192px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_maestro {
    background-position: -144px -224px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_maestro {
    background-position: -144px -256px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_jcb {
    background-position: -180px -192px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_jcb {
    background-position: -180px -224px
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_jcb {
    background-position: -180px -256px
}

.gform_card_icon_container input[type=radio]#gform_payment_method_creditcard {
    float: left;
    position: relative;
    top: 4px !important
}

.gform_wrapper .ginput_complex .ginput_cardinfo_right {
    min-width: 85px !important
}

.gform_wrapper .ginput_complex span.ginput_cardextras {
    display: block;
    overflow: hidden
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left span.ginput_card_expiration_container {
    position: relative;
    display: block;
    min-width: 160px !important
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left span.ginput_card_expiration_container select {
    margin-bottom: 8px
}

.gform_wrapper .ginput_complex .ginput_cardinfo_right input.ginput_card_security_code {
    max-width: 50% !important
}

/* .gform_wrapper .ginput_complex .ginput_cardinfo_right span.ginput_card_security_code_icon {
    width: 32px;
    height: 23px;
    background-image: url(../images/gf-creditcard-icons.png);
    background-repeat: no-repeat;
    background-position: 0 -128px;
    position: relative;
    top: -1px;
    left: 6px;
    display: -moz-inline-stack;
    display: inline-block
} */

.gform_wrapper .gform_fileupload_multifile .gform_drop_area {
    padding: 24px;
    border: 1px dashed #CCC;
    text-align: center;
    color: #AAA;
    margin-bottom: 16px;
    background: rgba(0, 0, 0, .02);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

.gform_wrapper span.gform_drop_instructions {
    display: block;
    margin-bottom: 8px
}

.gform_delete {
    vertical-align: middle;
    cursor: pointer
}

.gform_wrapper .copy_values_option_container .copy_values_enabled {
    width: auto
}

.gform_wrapper li.field_sublabel_above .ginput_complex input[type=text] {
    margin-bottom: 1px
}

.gform_wrapper .form_saved_message {
    margin: 1em auto;
    padding: 3em 2em;
    width: 99%;
    font-size: 1em;
    line-height: 1.8;
    color: rgba(49, 112, 143, 1);
    background-color: rgba(227, 243, 243, 1);
    text-align: center;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px
}

.gform_wrapper .form_saved_message form {
    text-align: center
}

.form_saved_message p.resume_form_link_wrapper {
    background-color: rgba(255, 255, 255, 1);
    display: inline-block;
    padding: 1em;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    max-width: 98% !important;
    overflow: hidden;
    -webkit-box-shadow: 0 3px 2px 0 rgba(66, 103, 126, .2);
    -moz-box-shadow: 0 3px 2px 0 rgba(66, 103, 126, .2);
    box-shadow: 0 3px 2px 0 rgba(66, 103, 126, .2)
}

.form_saved_message a.resume_form_link {
    word-wrap: break-word;
    text-decoration: none;
    color: rgba(47, 99, 126, 1)
}

.form_saved_message .form_saved_message_emailform {
    margin: 1em 0 0
}

.form_saved_message label.form_saved_label {
    position: absolute;
    left: -9000px
}

div.form_saved_message div.form_saved_message_emailform form input[type=text] {
    margin-bottom: .25em
}

div.form_saved_message div.form_saved_message_emailform form input {
    margin: .75em auto 0;
    display: inline-block
}

div.form_saved_message div.form_saved_message_emailform form input[type=email] {
    padding: calc(.75em - 1px) !important;
    width: 24em;
    max-width: 98% !important;
    font-size: 1em;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(145, 175, 199, 1);
    color: rgba(66, 66, 66, 1);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-box-shadow: inset 0 0 5px 0 rgba(238, 238, 238, 1);
    -moz-box-shadow: inset 0 0 5px 0 rgba(238, 238, 238, 1);
    box-shadow: inset 0 0 5px 0 rgba(238, 238, 238, 1)
}

div.form_saved_message div.form_saved_message_emailform form input[type=submit] {
    padding: .75em 1em;
    font-size: 1em;
    line-height: 1;
    background-color: rgba(145, 175, 199, 1);
    border: 1px solid rgba(145, 175, 199, 1);
    color: rgba(255, 255, 25566, 1);
    text-transform: uppercase;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-appearance: none;
    -moz-appearance: none
}

@media only screen and (max-width:640px) {

    div.form_saved_message div.form_saved_message_emailform form input[type=email],
    div.form_saved_message div.form_saved_message_emailform form input[type=submit] {
        display: block;
        width: 98%
    }
}

.form_saved_message .form_saved_message_emailform .validation_message {
    color: #790000;
    font-weight: 700;
    padding: 2em
}

.form_saved_message_sent {
    margin: 1em auto;
    padding: 3em 2em;
    width: 99%;
    font-size: 1em;
    background-color: rgba(233, 255, 217, 1);
    color: rgba(60, 118, 61, 1);
    text-align: center;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    box-sizing: border-box
}

.form_saved_message_sent .saved_message_email,
.form_saved_message_sent .saved_message_success {
    font-weight: 700;
    display: block;
    line-height: 2
}

.form_saved_message_sent .saved_message_email {
    word-wrap: break-word;
    text-decoration: underline
}

body #content .entry-content .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container #recaptcha_widget_div #recaptcha_area .recaptchatable tbody tr td .recaptcha_input_area,
body .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container #recaptcha_widget_div #recaptcha_area .recaptchatable tbody tr td .recaptcha_input_area {
    line-height: 1 !important
}

body .gform_wrapper img.ui-datepicker-trigger {
    width: 17px !important;
    height: 16px !important;
    display: -moz-inline-stack;
    display: inline-block
}

body .gform_wrapper img.add_list_item,
body .gform_wrapper img.delete_list_item {
    display: -moz-inline-stack;
    display: inline-block;
    margin-top: -2px
}

.gform_wrapper ul li.gfield.field_sublabel_above.field_description_below + li.gfield {
    margin-top: 16px
}

.gform_wrapper li.gfield ul.gfield_radio li input[type=radio] + input[type=text] {
    width: 45.25%
}

.gform_wrapper table.recaptchatable {
    table-layout: auto
}

.gform_wrapper ul.gform_fields:not(.top_label) div.ginput_container_captcha {
    margin-left: 29%
}

.gfield .wp-editor-container {
    border: 1px solid #ddd
}

.gfield .wp-switch-editor {
    border-color: #ddd
}

.gfield .html-active .switch-html,
.gfield .tmce-active .switch-tmce {
    border-bottom-color: #f5f5f5
}

.gfield .mce-edit-area iframe {
    width: 99.9% !important;
    margin: 0 !important
}

body .gform_wrapper ul li.gfield {
    margin-top: 16px;
    padding-top: 0
}

body .gform_wrapper ul.gfields li.gfield label.gfield_label {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html {
    line-height: inherit
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ul li,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ul li {
    list-style-type: disc !important;
    margin: 0 0 8px;
    overflow: visible;
    padding-left: 0
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ul,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ul {
    list-style-type: disc !important;
    margin: 16px 0 16px 28px !important;
    padding-left: 0
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol li,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol li {
    list-style-type: decimal !important;
    overflow: visible;
    margin: 0 0 8px;
    padding-left: 0
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol {
    list-style-type: decimal !important;
    margin: 16px 0 18px 32px;
    padding-left: 0
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul li {
    list-style-type: disc !important;
    padding-left: 0
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul {
    margin: 16px 0 16px 16px
}

body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul li {
    margin: 0 0 8px
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html dl {
    margin: 0 0 18px;
    padding-left: 0
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html dl dt {
    font-weight: 700
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html dl dd {
    margin: 0 0 16px 18px
}

.gform_wrapper span.gf_clear_span {
    display: block;
    height: 1px;
    overflow: hidden;
    width: 100%;
    float: none;
    clear: both;
    margin-top: -1px
}

.gform_wrapper .chosen-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.gform_wrapper .chosen-container * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.gform_wrapper .chosen-container .chosen-drop {
    position: absolute;
    top: 100%;
    z-index: 1010;
    width: 100%;
    border: 1px solid #aaa;
    border-top: 0;
    background: #fff;
    -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
    box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
    display: none
}

.gform_wrapper .chosen-container.chosen-with-drop .chosen-drop {
    display: block
}

.gform_wrapper .chosen-container a {
    cursor: pointer
}

.gform_wrapper .chosen-container .chosen-single .group-name,
.gform_wrapper .chosen-container .search-choice .group-name {
    margin-right: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
    color: #999
}

.gform_wrapper .chosen-container .chosen-single .group-name:after,
.gform_wrapper .chosen-container .search-choice .group-name:after {
    content: ":";
    padding-left: 2px;
    vertical-align: top
}

.gform_wrapper .chosen-container-single .chosen-single {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 0 0 0 8px;
    height: 25px;
    border: 1px solid #aaa;
    border-radius: 5px;
    background-color: #fff;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #fff), color-stop(50%, #f6f6f6), color-stop(52%, #eee), to(#f4f4f4));
    background: linear-gradient(#fff 20%, #f6f6f6 50%, #eee 52%, #f4f4f4 100%);
    background-clip: padding-box;
    -webkit-box-shadow: 0 0 3px #fff inset, 0 1px 1px rgba(0, 0, 0, .1);
    box-shadow: 0 0 3px #fff inset, 0 1px 1px rgba(0, 0, 0, .1);
    color: #444;
    text-decoration: none;
    white-space: nowrap;
    line-height: 24px
}

.gform_wrapper .chosen-container-single .chosen-single input[type=text] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    width: 0
}

.gform_wrapper .chosen-container-single .chosen-default {
    color: #999
}

.gform_wrapper .chosen-container-single .chosen-single span {
    display: block;
    overflow: hidden;
    margin-right: 26px;
    text-overflow: ellipsis;
    white-space: nowrap
}

.gform_wrapper .chosen-container-single .chosen-single-with-deselect span {
    margin-right: 38px
}

/* .gform_wrapper .chosen-container-single .chosen-single abbr {
    position: absolute;
    top: 6px;
    right: 26px;
    display: block;
    width: 12px;
    height: 12px;
    background: url(chosen-sprite.png) -42px 1px no-repeat;
    font-size: 1px
} */

.gform_wrapper .chosen-container-single .chosen-single abbr:hover,
.gform_wrapper .chosen-container-single.chosen-disabled .chosen-single abbr:hover {
    background-position: -42px -10px
}

.gform_wrapper .chosen-container-single .chosen-single div {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 18px;
    height: 100%
}

/* .gform_wrapper .chosen-container-single .chosen-single div b {
    display: block;
    width: 100%;
    height: 100%;
    background: url(chosen-sprite.png) 0 2px no-repeat
} */

.gform_wrapper .chosen-container-single .chosen-search {
    position: relative;
    z-index: 1010;
    margin: 0;
    padding: 3px 4px;
    white-space: nowrap
}

/* .gform_wrapper .chosen-container-single .chosen-search input[type=text] {
    margin: 1px 0;
    padding: 4px 20px 4px 5px;
    width: 100%;
    height: auto;
    outline: 0;
    border: 1px solid #aaa;
    background: url(chosen-sprite.png) 100% -20px no-repeat;
    font-size: 1em;
    font-family: sans-serif;
    line-height: normal;
    border-radius: 0
} */

.gform_wrapper .chosen-container-single .chosen-drop {
    margin-top: -1px;
    border-radius: 0 0 4px 4px;
    background-clip: padding-box
}

.gform_wrapper .chosen-container-single.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    opacity: 0;
    pointer-events: none
}

.gform_wrapper .chosen-container .chosen-results {
    color: #444;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0 4px 4px 0;
    padding: 0 0 0 4px;
    max-height: 240px;
    -webkit-overflow-scrolling: touch
}

.gform_wrapper .chosen-container .chosen-results li {
    display: none;
    margin: 0;
    padding: 5px 6px;
    list-style: none;
    line-height: 15px;
    word-wrap: break-word;
    -webkit-touch-callout: none
}

.gform_wrapper .chosen-container .chosen-results li.active-result {
    display: list-item;
    cursor: pointer
}

.gform_wrapper .chosen-container .chosen-results li.disabled-result {
    display: list-item;
    color: #ccc;
    cursor: default
}

.gform_wrapper .chosen-container .chosen-results li.highlighted {
    background-color: #3875d7;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #3875d7), color-stop(90%, #2a62bc));
    background-image: linear-gradient(#3875d7 20%, #2a62bc 90%);
    color: #fff
}

.gform_wrapper .chosen-container .chosen-results li.no-results {
    color: #777;
    display: list-item;
    background: #f4f4f4
}

.gform_wrapper .chosen-container .chosen-results li.group-result {
    display: list-item;
    font-weight: 700;
    cursor: default
}

.gform_wrapper .chosen-container .chosen-results li.group-option {
    padding-left: 15px
}

.gform_wrapper .chosen-container .chosen-results li em {
    font-style: normal;
    text-decoration: underline
}

.gform_wrapper .chosen-container-multi .chosen-choices {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0 5px;
    width: 100%;
    height: auto;
    border: 1px solid #aaa;
    background-color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #eee), color-stop(15%, #fff));
    background-image: linear-gradient(#eee 1%, #fff 15%);
    cursor: text
}

.gform_wrapper .chosen-container-multi .chosen-choices li {
    float: left;
    list-style: none
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-field {
    margin: 0;
    padding: 0;
    white-space: nowrap
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-field input[type=text] {
    margin: 1px 0;
    padding: 0;
    height: 25px;
    outline: 0;
    border: 0 !important;
    background: 0 0 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #999;
    font-size: 100%;
    font-family: sans-serif;
    line-height: normal;
    border-radius: 0;
    width: 25px
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice {
    position: relative;
    margin: 3px 5px 3px 0;
    padding: 3px 20px 3px 5px;
    border: 1px solid #aaa;
    max-width: 100%;
    border-radius: 3px;
    background-color: #eee;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
    background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
    background-size: 100% 19px;
    background-repeat: repeat-x;
    background-clip: padding-box;
    -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, .05);
    color: #333;
    line-height: 13px;
    cursor: default
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice span {
    word-wrap: break-word
}

/* .gform_wrapper .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    position: absolute;
    top: 4px;
    right: 3px;
    display: block;
    width: 12px;
    height: 12px;
    background: url(chosen-sprite.png) -42px 1px no-repeat;
    font-size: 1px
} */

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
    background-position: -42px -10px
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice-disabled {
    padding-right: 5px;
    border: 1px solid #ccc;
    background-color: #e4e4e4;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
    background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
    color: #666
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice-focus {
    background: #d4d4d4
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
    background-position: -42px -10px
}

.gform_wrapper .chosen-container-multi .chosen-results {
    margin: 0;
    padding: 0
}

.gform_wrapper .chosen-container-multi .chosen-drop .result-selected {
    display: list-item;
    color: #ccc;
    cursor: default
}

.gform_wrapper .chosen-container-active .chosen-single {
    border: 1px solid #5897fb;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    box-shadow: 0 0 5px rgba(0, 0, 0, .3)
}

.gform_wrapper .chosen-container-active.chosen-with-drop .chosen-single {
    border: 1px solid #aaa;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #eee), color-stop(80%, #fff));
    background-image: linear-gradient(#eee 20%, #fff 80%);
    -webkit-box-shadow: 0 1px 0 #fff inset;
    box-shadow: 0 1px 0 #fff inset
}

.gform_wrapper .chosen-container-active.chosen-with-drop .chosen-single div {
    border-left: none;
    background: 0 0
}

.gform_wrapper .chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: -18px 2px
}

.gform_wrapper .chosen-container-active .chosen-choices {
    border: 1px solid #5897fb;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    box-shadow: 0 0 5px rgba(0, 0, 0, .3)
}

.gform_wrapper .chosen-container-active .chosen-choices li.search-field input[type=text] {
    color: #222 !important
}

.gform_wrapper .chosen-disabled {
    opacity: .5 !important;
    cursor: default
}

.gform_wrapper .chosen-disabled .chosen-choices .search-choice .search-choice-close,
.gform_wrapper .chosen-disabled .chosen-single {
    cursor: default
}

.gform_wrapper .chosen-rtl {
    text-align: right
}

.gform_wrapper .chosen-rtl .chosen-single {
    overflow: visible;
    padding: 0 8px 0 0
}

.gform_wrapper .chosen-rtl .chosen-single span {
    margin-right: 0;
    margin-left: 26px;
    direction: rtl
}

.gform_wrapper .chosen-rtl .chosen-single-with-deselect span {
    margin-left: 38px
}

.gform_wrapper .chosen-rtl .chosen-single div {
    right: auto;
    left: 3px
}

.gform_wrapper .chosen-rtl .chosen-single abbr {
    right: auto;
    left: 26px
}

.gform_wrapper .chosen-rtl .chosen-choices li {
    float: right
}

.gform_wrapper .chosen-rtl .chosen-choices li.search-field input[type=text] {
    direction: rtl
}

.gform_wrapper .chosen-rtl .chosen-choices li.search-choice {
    margin: 3px 5px 3px 0;
    padding: 3px 5px 3px 19px
}

.gform_wrapper .chosen-rtl .chosen-choices li.search-choice .search-choice-close {
    right: auto;
    left: 4px
}

.gform_wrapper .chosen-rtl.chosen-container-single .chosen-results {
    margin: 0 0 4px 4px;
    padding: 0 4px 0 0
}

.gform_wrapper .chosen-rtl .chosen-results li.group-option {
    padding-right: 15px;
    padding-left: 0
}

.gform_wrapper .chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
    border-right: none
}

/* .gform_wrapper .chosen-rtl .chosen-search input[type=text] {
    padding: 4px 5px 4px 20px;
    background: url(chosen-sprite.png) -30px -20px no-repeat;
    direction: rtl
} */

.gform_wrapper .chosen-rtl.chosen-container-single .chosen-single div b {
    background-position: 6px 2px
}

.gform_wrapper .chosen-rtl.chosen-container-single.chosen-with-drop .chosen-single div b {
    background-position: -12px 2px
}

/* @media only screen and (-webkit-min-device-pixel-ratio:1.5),
only screen and (min-resolution:144dpi),
only screen and (min-resolution:1.5dppx) {

    .gform_wrapper .chosen-container .chosen-results-scroll-down span,
    .gform_wrapper .chosen-container .chosen-results-scroll-up span,
    .gform_wrapper .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
    .gform_wrapper .chosen-container-single .chosen-search input[type=text],
    .gform_wrapper .chosen-container-single .chosen-single abbr,
    .gform_wrapper .chosen-container-single .chosen-single div b,
    .gform_wrapper .chosen-rtl .chosen-search input[type=text] {
        background-image: url(chosen-sprite@2x.png) !important;
        background-size: 52px 37px !important;
        background-repeat: no-repeat !important
    }
} */

body .gform_wrapper .chosen-container a.chosen-single {
    border-radius: inherit
}

body .gform_wrapper ul.chosen-results li {
    line-height: inherit
}

@media only screen and (max-width:641px) {

    .gform_wrapper .chosen-container.chosen-container-single[style],
    .gform_wrapper div.chosen-container.chosen-container-multi[style] {
        width: 100% !important
    }

    .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
        line-height: 2;
        min-height: 2rem
    }

    .gform_wrapper textarea {
        line-height: 1.5
    }

    .gform_wrapper .chosen-container-single .chosen-single {
        height: 40px;
        line-height: 2.8
    }

    .gform_wrapper .chosen-container-single .chosen-single div b {
        position: relative;
        top: 10px
    }

    .gform_wrapper .chosen-container.chosen-container-multi ul.chosen-choices li.search-choice,
    .gform_wrapper .chosen-container.chosen-container-multi ul.chosen-choices li.search-field {
        width: 100%;
        line-height: inherit;
        float: none
    }

    .gform_wrapper .chosen-container.chosen-container-multi ul.chosen-choices li.search-choice span {
        margin-bottom: 0
    }

    .gform_wrapper .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
        top: calc(50% - 6px)
    }

    .gform_wrapper .ginput_container span:not(.ginput_price) {
        margin-bottom: 8px;
        display: block
    }

    .gform_wrapper li.field_sublabel_below .ginput_complex {
        margin-top: 12px !important
    }

    .gform_wrapper ul.gfield_checkbox li label,
    .gform_wrapper ul.gfield_radio li label {
        width: 85%;
        cursor: pointer
    }
}

@media only screen and (max-width:761px),
(min-device-width:768px) and (max-device-width:1023px) {
    .gform_wrapper ul.gform_fields li.gfield.gfield_error + li.gfield.gfield_error {
        margin-top: 32px
    }

    .gform_wrapper table.gfield_list {
        border: 0
    }

    .gform_wrapper table.gfield_list thead {
        position: absolute;
        top: -9999px;
        left: -9999px
    }

    .gform_wrapper table.gfield_list tr {
        margin-bottom: 10px;
        display: block;
        background: #fff;
        position: relative;
        border: 1px solid #CCC;
        border-top: 2px solid #CCC;
        border-bottom: 4px solid #CCC
    }

    .gform_wrapper table.gfield_list td {
        display: block;
        margin-left: 8px;
        margin-right: 8px
    }

    .gform_wrapper table.gfield_list td.gfield_list_icons {
        border-bottom: 0;
        margin: 16px 0 0 !important
    }

    .gform_wrapper ul.gform_fields.form_sublabel_above table.gfield_list td:before,
    .gform_wrapper ul.gform_fields.form_sublabel_below table.gfield_list td:after {
        content: attr(data-label);
        font-size: .875em;
        letter-spacing: .5pt;
        white-space: nowrap;
        display: block;
        clear: both
    }

    .gform_wrapper ul.gform_fields.form_sublabel_above table.gfield_list td:before {
        margin: 8px 0 3px 8px
    }

    .gform_wrapper ul.gform_fields.form_sublabel_below table.gfield_list td:after {
        margin: 3px 0 8px 8px
    }

    .gform_wrapper table.gfield_list td + td,
    .gform_wrapper table.gfield_list th + th {
        padding: 4px 0 0
    }

    .gform_wrapper table.gfield_list tr.gfield_list_row_even,
    .gform_wrapper table.gfield_list tr.gfield_list_row_odd {
        margin: 16px 0
    }

    .gform_wrapper table.gfield_list tr,
    .gform_wrapper table.gfield_list tr.gfield_list_row_odd:first-child {
        padding-top: 12px
    }

    .gform_wrapper table.gfield_list tr td.gfield_list_icons {
        width: 100% !important;
        padding: 0 4px 4px !important;
        background-color: #EEE
    }

    .gform_wrapper table.gfield_list tr td.gfield_list_icons img.add_list_item {
        margin: 12px 0 0 16px !important
    }

    .gform_wrapper table.gfield_list tr td.gfield_list_icons img.delete_list_item {
        margin: 12px 0 0 8px !important
    }

    .gform_wrapper .gform_footer a.gform_save_link,
    .gform_wrapper .gform_page_footer a.gform_save_link {
        margin-left: 0
    }

    .gform_wrapper .gfield_list td.gfield_list_cell input {
        width: calc(100% - 16px);
        margin-left: 8px;
        margin-right: 8px
    }
}

@media only screen and (min-width:641px) {
    .gform_wrapper .gform_body {
        width: 100%
    }

    .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
        max-width: calc(100% - 16px) !important
    }

    .gform_wrapper .gfield_checkbox li,
    .gform_wrapper .gfield_radio li {
        margin: 0 0 8px;
        line-height: 1.3
    }

    .gform_wrapper .gsection {
        margin: 28px 16px 28px 0
    }

    .gform_wrapper ul.gform_fields:not(.top_label) .gfield_label {
        float: left;
        width: 29%;
        padding-right: 16px;
        margin-bottom: 16px
    }

    .gform_wrapper .right_label .gfield_label {
        text-align: right
    }

    .gform_wrapper .left_label .gfield_description,
    .gform_wrapper .right_label .gfield_description {
        width: 70%
    }

    .gform_wrapper form ul.left_label li ul.gfield_checkbox li,
    .gform_wrapper form ul.left_label li ul.gfield_radio li,
    .gform_wrapper form ul.right_label li ul.gfield_checkbox li,
    .gform_wrapper form ul.right_label li ul.gfield_radio li,
    .gform_wrapper ul.left_label li ul.gfield_checkbox li,
    .gform_wrapper ul.left_label li ul.gfield_radio li,
    .gform_wrapper ul.right_label li ul.gfield_checkbox li,
    .gform_wrapper ul.right_label li ul.gfield_radio li {
        margin-bottom: 10px
    }

    .gform_wrapper .left_label .gfield_description,
    .gform_wrapper .left_label .instruction,
    .gform_wrapper .right_label .gfield_description,
    .gform_wrapper .right_label .instruction {
        margin-left: 29%
    }

    .gform_wrapper ul.gform_fields:not(.top_label) .gsection .gsection_description {
        margin-left: 0;
        padding-top: 4px;
        padding-left: 0;
        line-height: 1.5
    }

    .gform_wrapper form ul.gform_fields:not(.top_label) li.gfield_html_formatted {
        margin-left: 29% !important;
        width: 70%
    }

    .gform_wrapper ul.gform_fields:not(.top_label) ul.gfield_checkbox,
    .gform_wrapper ul.gform_fields:not(.top_label) ul.gfield_radio {
        overflow: hidden
    }

    .gform_wrapper .left_label div.ginput_complex,
    .gform_wrapper .right_label div.ginput_complex {
        width: 70%;
        margin-left: 29%
    }

    .gform_wrapper .left_label input.small,
    .gform_wrapper .left_label select.small,
    .gform_wrapper .right_label input.small,
    .gform_wrapper .right_label select.small {
        width: 15%
    }

    .gform_wrapper .left_label input.medium,
    .gform_wrapper .left_label select.medium,
    .gform_wrapper .right_label input.medium,
    .gform_wrapper .right_label select.medium {
        width: calc(35% - 8px)
    }

    .gform_wrapper .left_label input.large,
    .gform_wrapper .left_label select.large,
    .gform_wrapper .left_label textarea.large,
    .gform_wrapper .left_label textarea.medium,
    .gform_wrapper .left_label textarea.small,
    .gform_wrapper .right_label input.large,
    .gform_wrapper .right_label select.large,
    .gform_wrapper .right_label textarea.large,
    .gform_wrapper .right_label textarea.medium,
    .gform_wrapper .right_label textarea.small {
        width: 70%
    }

    .gform_wrapper ul.gform_fields:not(.top_label) .ginput_list {
        width: 70%;
        margin-left: 29% !important
    }

    .gform_wrapper .top_label .gfield_list {
        width: 100%
    }

    .gform_wrapper .left_label .gf_list_one_column,
    .gform_wrapper .right_label .gf_list_one_column {
        width: 45%
    }

    .gform_wrapper .top_label .gf_list_one_column {
        width: 46%
    }

    .gform_wrapper .left_label li.hidden_label input,
    .gform_wrapper .right_label li.hidden_label input {
        margin-left: 3.7%
    }

    .gform_wrapper .left_label li.gfield .gfield_password_strength,
    .gform_wrapper .right_label li.gfield .gfield_password_strength {
        margin-left: 29%
    }

    .gform_wrapper .top_label input.small,
    .gform_wrapper .top_label select.small {
        width: 25%
    }

    .gform_wrapper .top_label input.medium,
    .gform_wrapper .top_label select.medium {
        width: calc(50% - 8px)
    }

    .gform_wrapper.gform_validation_error .top_label input.medium,
    .gform_wrapper.gform_validation_error .top_label select.medium {
        width: 50%
    }

    .gform_wrapper .hidden_label input.large,
    .gform_wrapper .hidden_label select.large,
    .gform_wrapper .top_label input.large,
    .gform_wrapper .top_label select.large {
        width: 100%
    }

    .gform_wrapper .top_label input.large.datepicker,
    .gform_wrapper .top_label input.medium.datepicker,
    .gform_wrapper .top_label input.small.datepicker {
        width: auto
    }

    .gform_wrapper textarea.small {
        height: 80px;
        width: 100%
    }

    .gform_wrapper textarea.medium {
        height: 160px;
        width: 100%
    }

    .gform_wrapper textarea.large {
        height: 320px;
        width: 100%
    }

    .gform_wrapper .gfield_error .ginput_complex .ginput_left,
    .gform_wrapper .gfield_error .ginput_complex .ginput_right,
    .gform_wrapper .ginput_complex .ginput_cardinfo_left {
        width: 50%
    }

    .gform_wrapper .ginput_complex .ginput_left,
    .gform_wrapper .ginput_complex .ginput_right {
        width: 50%;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        margin: 0 -4px 0 0
    }

    .gform_wrapper .ginput_container_password .ginput_right {
        padding-left: 8px
    }

    .gform_wrapper .ginput_complex .ginput_cardinfo_left,
    .gform_wrapper .ginput_complex .ginput_cardinfo_right {
        min-height: 43px;
        position: relative;
        float: left
    }

    .gform_wrapper .ginput_complex select.ginput_card_expiration.ginput_card_expiration_month,
    .gform_wrapper .ginput_complex select.ginput_card_expiration.ginput_card_expiration_year {
        width: 47% !important;
        display: -moz-inline-stack;
        display: inline-block
    }

    .gform_wrapper .ginput_complex .ginput_cardinfo_left select.ginput_card_expiration.ginput_card_expiration_month {
        margin-right: 4px
    }

    .gform_wrapper .gf_step {
        width: auto;
        display: -moz-inline-stack;
        display: inline-block;
        margin: 16px 32px 16px 0
    }

    .gform_wrapper .gform_footer input.button,
    .gform_wrapper .gform_footer input[type=image],
    .gform_wrapper .gform_footer input[type=submit] {
        display: -moz-inline-stack;
        display: inline-block
    }

    .gform_wrapper .gform_footer input[type=image] {
        vertical-align: middle
    }

    .gform_wrapper .gform_footer a.gform_save_link,
    .gform_wrapper .gform_page_footer a.gform_save_link {
        font-size: 1em;
        margin-left: 16px;
        display: -moz-inline-stack;
        display: inline-block
    }

    .gform_wrapper .gform_footer input.button,
    .gform_wrapper .gform_footer input[type=submit],
    .gform_wrapper .gform_page_footer input.button,
    .gform_wrapper .gform_page_footer input[type=submit] {
        font-size: 1em;
        width: auto;
        margin: 0 16px 0 0
    }

    .gform_wrapper ul.gform_fields li.gfield {
        padding-right: 16px
    }

    .gform_wrapper .ginput_complex .ginput_left {
        padding-right: 8px
    }

    .gform_wrapper .left_label .ginput_complex.ginput_container_address:after {
        content: "";
        display: block
    }

    .gform_wrapper .ginput_complex.ginput_container_address .ginput_left {
        margin-right: 8px
    }

    .gform_wrapper .ginput_complex.ginput_container_address .ginput_right {
        margin-right: -8px;
        padding-right: 8px
    }

    .gform_wrapper .ginput_complex.ginput_container_address span.ginput_full + span.ginput_right {
        padding-right: 8px !important;
        margin-right: 8px
    }

    .gform_wrapper .ginput_complex.ginput_container_address span.ginput_right + span.ginput_left {
        padding-right: 8px !important;
        margin-right: -8px
    }

    .gform_wrapper .ginput_complex.ginput_container_address span.ginput_right + span.ginput_left.address_zip {
        margin-right: 8px
    }

    .gform_wrapper .gfield_error .ginput_container {
        padding-right: 0
    }

    .gform_wrapper ul.top_label li.gfield.gfield_error.gfield_contains_required .gfield_password_strength {
        margin: 18px 0
    }

    .gform_wrapper .gform_footer:not(.top_label) {
        padding: 16px 0 10px;
        margin-left: 29%;
        width: 70%
    }

    .top_label div.ginput_complex.ginput_container.gf_name_has_1,
    .top_label div.ginput_complex.ginput_container.gf_name_has_2,
    .top_label div.ginput_complex.ginput_container.gf_name_has_3 {
        width: calc(100% + 15px)
    }

    .top_label div.ginput_complex.ginput_container.gf_name_has_4 {
        width: calc(100% + 13px)
    }

    .top_label div.ginput_complex.ginput_container.gf_name_has_5 {
        width: calc(100% + 14px)
    }

    ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_1,
    ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_2,
    ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_3 {
        width: calc(70% + 15px);
        margin-left: 29%
    }

    ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_1,
    ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_2,
    ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_3 {
        width: calc(70% + 24px)
    }

    ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_4 {
        width: calc(70% + 13px);
        margin-left: 29%
    }

    ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_5 {
        width: calc(70% + 14px);
        margin-left: 29%
    }

    div.ginput_container_name span {
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        padding-right: 16px;
        margin-right: -4px
    }

    div.ginput_complex.ginput_container.gf_name_has_1 span {
        width: 100%
    }

    div.ginput_complex.ginput_container.gf_name_has_2 span {
        width: 50%
    }

    div.ginput_complex.ginput_container.gf_name_has_3 span {
        width: 33.3%
    }

    div.ginput_complex.ginput_container.gf_name_has_4 span {
        width: 25%
    }

    div.ginput_complex.ginput_container.gf_name_has_5 span {
        width: 19.95%
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_1 span {
        width: 99.75% !important
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_2 span {
        width: 49.75% !important
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_3 span {
        width: 33% !important
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_4 span {
        width: 24.75% !important
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_5 span {
        width: 19.75% !important
    }

    html div.ginput_complex.ginput_container.gf_name_has_2 span:first-child,
    html div.ginput_complex.ginput_container.gf_name_has_3 span:first-child,
    html div.ginput_complex.ginput_container.gf_name_has_4 span:first-child,
    html div.ginput_complex.ginput_container.gf_name_has_5 span:first-child {
        margin-left: 0 !important
    }

    .gform_wrapper .top_label .ginput_complex.ginput_container.ginput_container_email {
        width: calc(100% + 15px)
    }

    .gform_wrapper ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.ginput_container_email {
        width: calc(70% + 16px);
        margin-left: 29%
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.ginput_container_email {
        width: calc(70% + 17px)
    }

    li.gfield.gfield_error .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error .ginput_complex.ginput_container.ginput_container_email {
        width: calc(70% + 8px) !important
    }

    .gform_wrapper .ginput_complex.ginput_container.ginput_container_email .ginput_left,
    .gform_wrapper .ginput_complex.ginput_container.ginput_container_email .ginput_right {
        padding-right: 16px
    }

    .gform_wrapper ul.left_label li.gfield .gfield_password_strength,
    .gform_wrapper ul.right_label li.gfield .gfield_password_strength {
        width: 70%
    }

    .gform_wrapper .ginput_complex span.ginput_left + input.gform_hidden + span.ginput_left {
        padding-right: 0
    }

    .gform_wrapper .ginput_complex span.ginput_left.address_country {
        margin-right: 0
    }

    html:not([dir=rtl]) .gform_wrapper ul.gform_fields:not(.top_label) .clear-multi,
    html:not([dir=rtl]) .gform_wrapper ul.gform_fields:not(.top_label) .gfield_description,
    html:not([dir=rtl]) .gform_wrapper ul.gform_fields:not(.top_label) .ginput_container:not(.ginput_container_time):not(.ginput_container_date) {
        width: 70%;
        margin-left: 29%
    }

    .gform_wrapper .gf_progressbar {
        width: calc(100% - 16px)
    }

    .gform_wrapper ul.gform_fields:not(.top_label) .gform_fileupload_multifile .gform_drop_area {
        margin-left: 29%;
        width: 70%
    }

    body .gform_wrapper span.ginput_left.address_city + input.gform_hidden + span.ginput_left.address_country {
        width: calc(50% - 8px);
        margin-right: 0
    }

    .gform_wrapper ul.gform_fields:not(.top_label) .ginput_container.ginput_recaptcha {
        margin-left: 29% !important
    }

    .gform_wrapper .gform_page_footer .button.gform_previous_button {
        margin-bottom: 0
    }

    .gform_wrapper .gform_page_footer .button.gform_button,
    .gform_wrapper .gform_page_footer .button.gform_next_button {
        margin-right: 10px
    }

    body .gform_wrapper select.medium.gfield_select + div.chosen-container-multi[style],
    body .gform_wrapper select.medium.gfield_select + div.chosen-container-single[style] {
        width: calc(50% - 8px) !important
    }

    body .gform_wrapper select.large.gfield_select + div.chosen-container-multi[style],
    body .gform_wrapper select.large.gfield_select + div.chosen-container-single[style] {
        width: 100% !important
    }

    body .gform_wrapper select.small.gfield_select + div.chosen-container-multi[style],
    body .gform_wrapper select.small.gfield_select + div.chosen-container-single[style] {
        width: 25%
    }
}

.gfield_repeater_cell > .gfield_repeater_wrapper {
    background-color: rgba(1, 1, 1, .02);
    padding: 10px 20px;
    border-radius: 8px;
    border-bottom: 1px solid rgba(238, 238, 238, 1)
}

.gfield_repeater_wrapper input {
    border: 1px solid rgba(197, 198, 197, 1);
    border-radius: 4px
}

.gfield_repeater_wrapper .gfield_repeater_wrapper {
    margin-top: 10px
}

.gfield_repeater_buttons .add_repeater_item_text,
.gfield_repeater_buttons .remove_repeater_item_text {
    min-width: 100px;
    height: 30px;
    background: rgba(242, 242, 242, .5);
    transition: all .3s cubic-bezier(.67, .17, .4, .83);
    font-size: 12px;
    color: rgba(117, 117, 117, 1);
    border-radius: 20px;
    margin-right: 10px;
    margin-bottom: 5px;
    border: 1px solid rgba(117, 117, 117, .4);
    font-weight: 400
}

.gfield_repeater_buttons .add_repeater_item_plus,
.gfield_repeater_buttons .remove_repeater_item_minus {
    width: 22px;
    height: 22px;
    background: rgba(242, 242, 242, .5);
    transition: all .3s cubic-bezier(.67, .17, .4, .83);
    font-size: 16px;
    color: rgba(117, 117, 117, 1);
    border-radius: 50%;
    margin: 10px 5px 0;
    border: 1px solid rgba(117, 117, 117, .4);
    font-weight: 700;
    padding: 0 0 5px
}

.gfield_repeater_buttons button.gfield_icon_disabled {
    cursor: default;
    filter: alpha(opacity=30);
    -moz-opacity: .3;
    -khtml-opacity: .3;
    opacity: .3
}

.gfield_repeater_buttons button.gfield_icon_disabled:hover {
    background: rgba(242, 242, 242, .5);
    color: rgba(117, 117, 117, 1);
    border: 1px solid rgba(117, 117, 117, .4)
}

.gfield_repeater_buttons button:hover {
    background: rgba(250, 250, 250, 1);
    color: #374750;
    border: 1px solid rgba(117, 117, 117, 1)
}

.gfield_repeater_cell > .gfield_repeater_wrapper {
    border-left: 8px solid rgba(241, 241, 241, 1);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06), 0 2px 1px -1px rgba(0, 0, 0, .06), 0 1px 5px 0 rgba(0, 0, 0, .06)
}

.gfield_repeater_buttons .add_repeater_item_text:focus,
.gfield_repeater_buttons .remove_repeater_item_text:focus {
    outline: 0
}

.gfield_repeater_item .gfield_repeater_cell .gfield_required {
    color: #c32121
}

.gform_wrapper .gfield_repeater_cell label {
    color: #9b9a9a;
    font-weight: 400;
    font-size: 14px;
    padding-top: 8px
}

.gfield_repeater_value .gfield_repeater_value .gfield_repeater_cell {
    padding-bottom: 20px;
    padding-top: 0
}

div .gfield_repeater_cell {
    margin-top: 5px
}

.gfield_repeater .gfield_repeater_items .gfield_repeater_item:not(:last-child) {
    border-bottom: 2px solid #e0e0e6;
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-right: 10px
}

.gfield_repeater_value > .gfield_repeater {
    border-left: 8px solid rgba(54, 86, 102, .1);
    background-color: rgba(1, 1, 1, .02);
    padding: 10px 20px;
    border-radius: 8px;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
    margin: 10px
}

.gfield_repeater_cell .gfield_repeater_value:not(:first-child) {
    color: rgba(117, 117, 117, .7);
    border: 1px solid rgba(197, 198, 197, 1);
    border-radius: 4px;
    margin-right: 10px;
    padding-left: 10px;
    background-color: rgba(240, 240, 240, 1)
}

.gfield_repeater .gfield_repeater_items,
.gfield_repeater_items .gfield_repeater_cell:not(:first-child) {
    padding-top: 5px
}

.gfield_repeater .gfield_label {
    color: rgba(35, 40, 45, 1);
    font-size: 16px;
    font-weight: 600
}

.gfield_repeater_value .gfield_label,
.gfield_repeater_value .gfield_repeater_value .gfield_repeater_item:first-child {
    padding-bottom: 0
}

#gform_submit_button_1 {
    position: relative;
    background-image: url("../images/subscribe-btn-image2.png");
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 25px 30px;
    font-weight: 600;
}

#gform_submit_button_1:hover {
    position: relative;
    background-image: url("../images/subscribe-hover-image2.png");
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 25px 30px;
}

@media only screen and (min-width: 641px){
    .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label.form_sublabel_above li.gfield, .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label.form_sublabel_below li.gfield {
        vertical-align: middle!important;
    }
    .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield {
        display: table-cell;
        padding-right: 1em;
        height: auto;
    }

    .gform_wrapper form.gf_simple_horizontal div.gform_body, .gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label {
        display: table-cell;
        vertical-align: middle;
        margin: 0;
        padding: 0;
        position: relative;
    }

    body:not(rtl) .gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label {
        text-align: left;
    }
}

.fl-node-5bdc292c5794a .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5bdc292c5794a .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5bdc292c5794a .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5bdc292c5794a > .fl-row-content-wrap {
    background-color: #ffffff;
}

.fl-node-5bdc292c5794a > .fl-row-content-wrap {
    padding-top: 0px;
    padding-bottom: 0px;
}

.fl-node-5bdc295cdc3a7 {
    width: 50%;
}

#subscribe-wrapper .fl-node-5bdc295cdc427 {
    width: 50%;
}

.pp-photo-container .pp-photo-align-left {
    text-align: left;
}

.pp-photo-container .pp-photo-align-center {
    text-align: center;
}

.pp-photo-container .pp-photo-align-right {
    text-align: right;
}

.pp-photo {
    line-height: 0;
    position: relative;
}

.pp-photo-align-left {
    text-align: left;
}

.pp-photo-align-center {
    text-align: center;
}

.pp-photo-align-right {
    text-align: right;
}

.pp-photo-content {
    display: inline-block;
    line-height: 0;
    position: relative;
    max-width: 100%;
}

.pp-photo-content img {
    display: inline;
    max-width: 100%;
}

.pp-photo-crop-circle img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.pp-photo-caption {
    font-size: 13px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    width: 100%;
}

.pp-photo-caption-below {
    padding-bottom: 20px;
    padding-top: 10px;
}

.pp-photo-caption-overlay {
    background: rgba(0, 0, 0, 0.7);
    bottom: 0;
    color: #fff;
    left: 0;
    padding: 10px 15px;
    position: absolute;
    right: 0;
    -webkit-transition: opacity 0.3s ease-in;
    -moz-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
}

.pp-overlay-wrap .pp-overlay-bg {
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity .3s linear;
    -moz-transition: opacity .3s linear;
    -o-transition: opacity .3s linear;
    -ms-transition: opacity .3s linear;
    transition: opacity .3s linear;
}

.pp-photo-caption-hover {
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: opacity .3s linear;
    -moz-transition: opacity .3s linear;
    -o-transition: opacity .3s linear;
    -ms-transition: opacity .3s linear;
    transition: opacity .3s linear;
}

.pp-photo-container .pp-photo-content:hover .pp-overlay-bg,
.pp-photo-container .pp-photo-content:hover .pp-photo-caption-hover {
    opacity: 1;
    -webkit-transition: opacity .3s linear;
    -moz-transition: opacity .3s linear;
    -o-transition: opacity .3s linear;
    -ms-transition: opacity .3s linear;
    transition: opacity .3s linear;
}

.pp-photo-container a:focus {
    outline: none;
}

.pp-photo-container .pp-photo-content .pp-photo-content-inner {
    overflow: hidden;
    position: relative;
}

.fl-node-5bdc29b03d6e0 .pp-photo-container .pp-photo-content {
    background: transparent;
    border-color: #cccccc;
    border-style: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -o-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
    border-width: 1px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.fl-node-5bdc29b03d6e0 .pp-photo-container .pp-photo-content .pp-photo-content-inner {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -o-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
}

.fl-node-5bdc29b03d6e0 .pp-photo-container .pp-photo-content .pp-photo-content-inner img {
    width: 190px;
}

.fl-node-5bdc29b03d6e0 .pp-photo-container .pp-photo-content .pp-photo-content-inner a {
    display: block;
    text-decoration: none !important;
}

.fl-node-5bdc29b03d6e0 .pp-photo-container .pp-photo-content .pp-photo-content-inner img {
    border-color: #000000;
    border-style: none;
    border-width: 1px;
}

.fl-node-5bdc29b03d6e0 .pp-photo-caption {
    background: rgba(221, 221, 221, 0.5);
    color: #000000;
    font-size: 18px;
    line-height: 1.6;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.fl-node-5bdc29b03d6e0 .pp-overlay-wrap .pp-overlay-bg {
    background: rgba(221, 221, 221, 0.5);
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -o-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
}

@media only screen and (max-width: 800px) {
    .pp-photo-container .pp-photo-align-responsive-left {
        text-align: left !important;
    }

    .pp-photo-container .pp-photo-align-responsive-center {
        text-align: center !important;
    }

    .pp-photo-container .pp-photo-align-responsive-right {
        text-align: right !important;
    }
}

.fl-node-5bdc29b03d6e0 > .fl-module-content {
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (max-width: 800px) {
    .fl-node-5bdc29b03d6e0.fl-module > .fl-module-content {
        margin-right: 10px;
        margin-left: 10px;
    }
}

a.pp-button,
a.pp-button:visited {
    display: inline-block;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    text-shadow: none;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -o-transition: all .3s linear;
    -ms-transition: all .3s linear;
    transition: all .3s linear;
}

.pp-button:hover {
    text-decoration: none;
}

.pp-button:active {
    position: relative;
    top: 1px;
}

.pp-button-width-full .pp-button {
    display: block;
    text-align: center;
}

.pp-button-width-custom .pp-button {
    display: inline-block;
    text-align: center;
    max-width: 100%;
}

.pp-button-left {
    text-align: left;
}

.pp-button-center {
    text-align: center;
}

.pp-button-right {
    text-align: right;
}

.pp-button i {
    font-size: 1.3em;
    height: auto;
    margin-right: 8px;
    vertical-align: middle;
    width: auto;
}

.pp-button i.pp-button-icon-after {
    margin-left: 8px;
    margin-right: 0;
}

.pp-button-has-icon .pp-button-text {
    vertical-align: middle;
}

.pp-button-wrap a.pp-button:before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition-property: transform;
    -moz-transition-property: transform;
    -o-transition-property: transform;
    -ms-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    -ms-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.pp-button .dashicons,
.pp-button .dashicons-before:before {
    font-size: inherit;
    height: auto;
    width: auto;
}

@media (max-width: 800px) {

    .fl-module-button .pp-button-left,
    .fl-module-button .pp-button-right {
        text-align: center;
    }
}

.fl-node-5bdc29652086c .pp-button-wrap a.pp-button,
.fl-node-5bdc29652086c .pp-button-wrap a.pp-button:visited {
    text-decoration: none;
    line-height: 1.6;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 40px;
    padding-right: 40px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border: 2px #333333;
    border-style: none;
    background: #104fa0;
}

.fl-node-5bdc29652086c a.pp-button .pp-button-text {
    font-size: 18px;
    letter-spacing: 0px;
}

.fl-node-5bdc29652086c .pp-button .pp-button-icon {
    font-size: 16px;
}

.fl-node-5bdc29652086c a.pp-button {
    color: #ffffff;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    -ms-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
}

.fl-node-5bdc29652086c a.pp-button span {
    color: #ffffff;
}

.fl-node-5bdc29652086c .pp-button-wrap a.pp-button:hover,
.fl-node-5bdc29652086c .pp-button-wrap a.pp-button:focus {
    text-decoration: none;
    background: #28ace2;
    border-color: #dddddd;
}

.fl-node-5bdc29652086c a.pp-button:hover,
.fl-node-5bdc29652086c a.pp-button:focus,
.fl-node-5bdc29652086c a.pp-button:hover *,
.fl-node-5bdc29652086c a.pp-button:focus * {
    color: #ffffff;
}

.fl-node-5bdc29652086c .pp-button-wrap a.pp-button,
.fl-node-5bdc29652086c .pp-button-wrap a.pp-button:visited {
    transition-duration: 500ms;
}

@media only screen and (max-width: 800px) {
    .fl-node-5bdc29652086c .pp-button-wrap.pp-button-left {
        text-align: center;
    }
}

@media only screen and (max-width: 800px) {
    .fl-node-5bdc29652086c .pp-button-wrap {
        text-align: center !important;
    }
}

@media only screen and (max-width: 480px) {}

.fl-node-5bdc29652086c > .fl-module-content {
    margin-top: 10px;
    margin-bottom: 0px;
}

@media (max-width: 800px) {
    .fl-node-5bdc29652086c.fl-module > .fl-module-content {
        margin-right: 10px;
        margin-left: 10px;
    }
}

.fl-node-5bdc2b198ad94 > .fl-module-content {
    margin-top: 10px;
    margin-bottom: 0px;
}

@media (max-width: 800px) {
    .fl-node-5bdc2b198ad94.fl-module > .fl-module-content {
        margin-top: 0px;
    }
}

.fl-builder-content[data-type="header"].fl-theme-builder-header-sticky {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 100;
}

.admin-bar .fl-builder-content[data-type="header"].fl-theme-builder-header-sticky {
    top: 32px;
}

.fl-builder-content[data-shrink="1"] .fl-row-content-wrap,
.fl-builder-content[data-shrink="1"] .fl-col-content,
.fl-builder-content[data-shrink="1"] .fl-module-content,
.fl-builder-content[data-shrink="1"] img {
    -webkit-transition: all 0.4s ease-in-out, background-position 1ms;
    -moz-transition: all 0.4s ease-in-out, background-position 1ms;
    transition: all 0.4s ease-in-out, background-position 1ms;
}

.fl-builder-content[data-shrink="1"] img {
    width: auto;
}

.fl-builder-content[data-type="header"].fl-theme-builder-header-shrink .fl-row-content-wrap {
    margin-bottom: 0;
    margin-top: 0;
}

.fl-theme-builder-header-shrink-row-bottom.fl-row-content-wrap {
    padding-bottom: 5px;
}

.fl-theme-builder-header-shrink-row-top.fl-row-content-wrap {
    padding-top: 5px;
}

.fl-builder-content[data-type="header"].fl-theme-builder-header-shrink .fl-col-content {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
}

.fl-theme-builder-header-shrink-module-bottom.fl-module-content {
    margin-bottom: 5px;
}

.fl-theme-builder-header-shrink-module-top.fl-module-content {
    margin-top: 5px;
}

.fl-builder-content[data-type="header"].fl-theme-builder-header-shrink img {
    max-height: 50px;
}

.fl-builder-content[data-overlay="1"]:not(.fl-theme-builder-header-sticky) {
    position: absolute;
    width: 100%;
    z-index: 100;
}

.fl-builder-edit body:not(.single-fl-theme-layout) .fl-builder-content[data-overlay="1"] {
    display: none;
}

body:not(.single-fl-theme-layout) .fl-builder-content[data-overlay="1"]:not(.fl-theme-builder-header-scrolled):not([data-overlay-bg="default"]) .fl-row-content-wrap,
body:not(.single-fl-theme-layout) .fl-builder-content[data-overlay="1"]:not(.fl-theme-builder-header-scrolled):not([data-overlay-bg="default"]) .fl-col-content {
    background: transparent;
}

.fl-node-5bdc292c5794a .uabb-top-row-separator.uabb-has-svg svg {
    width: calc(100% + 1.5px);
}

.fl-node-5bdc292c5794a .uabb-bottom-row-separator.uabb-has-svg svg {
    width: calc(100% + 1.5px);
}

.fl-node-5bdc292c5794a #uabb-top-slit2,
.fl-node-5bdc292c5794a #uabb-top-slit3 {
    fill: #afafaf;
}

.fl-node-5bdc292c5794a #uabb-bottom-slit2,
.fl-node-5bdc292c5794a #uabb-bottom-slit3 {
    fill: #afafaf;
}

.fl-node-5bdc292c5794a #uabb-top-pine-tree-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bdc292c5794a #uabb-bottom-pine-tree-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bdc292c5794a #uabb-top-pine-tree-bend-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bdc292c5794a #uabb-bottom-pine-tree-bend-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bdc292c5794a #uabb-top-slime-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bdc292c5794a #uabb-bottom-slime-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bdc292c5794a #uabb-top-wave-slide-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bdc292c5794a #uabb-bottom-wave-slide-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bdc292c5794a .uabb-top-row-separator {
    z-index: 9;
}

.fl-node-5bdc292c5794a .uabb-bottom-row-separator {
    z-index: 9;
}

@media(max-width: 1000px) {
    .fl-node-5bdc292c5794a .uabb-top-row-separator.uabb-has-svg svg {
        width: 100%;
    }

    .fl-node-5bdc292c5794a .uabb-bottom-row-separator.uabb-has-svg svg {
        width: 100%;
    }
}

@media(max-width: 800px) {
    .fl-node-5bdc292c5794a .uabb-top-row-separator.uabb-has-svg svg {
        width: 100%;
    }

    .fl-node-5bdc292c5794a .uabb-bottom-row-separator.uabb-has-svg svg {
        width: 100%;
    }
}

.fl-col-group-equal-height.fl-col-group-align-bottom .fl-col-content {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
}

.uabb-module-content h1,
.uabb-module-content h2,
.uabb-module-content h3,
.uabb-module-content h4,
.uabb-module-content h5,
.uabb-module-content h6 {
    margin: 0;
    clear: both;
}

.fl-module-content a,
.fl-module-content a:hover,
.fl-module-content a:focus {
    text-decoration: none;
}

.uabb-row-separator {
    position: absolute;
    width: 100%;
    left: 0;
}

.uabb-top-row-separator {
    top: 0;
    bottom: auto
}

.uabb-bottom-row-separator {
    top: auto;
    bottom: 0;
}

.fl-builder-content-editing .fl-visible-medium.uabb-row,
.fl-builder-content-editing .fl-visible-medium-mobile.uabb-row,
.fl-builder-content-editing .fl-visible-mobile.uabb-row {
    display: none !important;
}

@media (max-width: 992px) {

    .fl-builder-content-editing .fl-visible-desktop.uabb-row,
    .fl-builder-content-editing .fl-visible-mobile.uabb-row {
        display: none !important;
    }

    .fl-builder-content-editing .fl-visible-desktop-medium.uabb-row,
    .fl-builder-content-editing .fl-visible-medium.uabb-row,
    .fl-builder-content-editing .fl-visible-medium-mobile.uabb-row {
        display: block !important;
    }
}

@media (max-width: 768px) {

    .fl-builder-content-editing .fl-visible-desktop.uabb-row,
    .fl-builder-content-editing .fl-visible-desktop-medium.uabb-row,
    .fl-builder-content-editing .fl-visible-medium.uabb-row {
        display: none !important;
    }

    .fl-builder-content-editing .fl-visible-medium-mobile.uabb-row,
    .fl-builder-content-editing .fl-visible-mobile.uabb-row {
        display: block !important;
    }
}

.fl-responsive-preview-content .fl-builder-content-editing {
    overflow-x: hidden;
    overflow-y: visible;
}

.uabb-row-separator svg {
    width: 100%;
}

.uabb-top-row-separator.uabb-has-svg svg {
    position: absolute;
    padding: 0;
    margin: 0;
    left: 50%;
    top: -1px;
    bottom: auto;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.uabb-bottom-row-separator.uabb-has-svg svg {
    position: absolute;
    padding: 0;
    margin: 0;
    left: 50%;
    bottom: -1px;
    top: auto;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.uabb-bottom-row-separator.uabb-has-svg .uasvg-wave-separator {
    bottom: 0;
}

.uabb-top-row-separator.uabb-has-svg .uasvg-wave-separator {
    top: 0;
}

.uabb-bottom-row-separator.uabb-svg-triangle svg,
.uabb-bottom-row-separator.uabb-xlarge-triangle svg,
.uabb-top-row-separator.uabb-xlarge-triangle-left svg,
.uabb-bottom-row-separator.uabb-svg-circle svg,
.uabb-top-row-separator.uabb-slime-separator svg,
.uabb-top-row-separator.uabb-grass-separator svg,
.uabb-top-row-separator.uabb-grass-bend-separator svg,
.uabb-bottom-row-separator.uabb-mul-triangles-separator svg,
.uabb-top-row-separator.uabb-wave-slide-separator svg,
.uabb-top-row-separator.uabb-pine-tree-separator svg,
.uabb-top-row-separator.uabb-pine-tree-bend-separator svg,
.uabb-bottom-row-separator.uabb-stamp-separator svg,
.uabb-bottom-row-separator.uabb-xlarge-circle svg,
.uabb-top-row-separator.uabb-wave-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleY(-1);
    -moz-transform: translateX(-50%) scaleY(-1);
    -ms-transform: translateX(-50%) scaleY(-1);
    -o-transform: translateX(-50%) scaleY(-1);
    transform: translateX(-50%) scaleY(-1);
}

.uabb-bottom-row-separator.uabb-big-triangle svg {
    left: 50%;
    -webkit-transform: scale(1) scaleY(-1) translateX(-50%);
    -moz-transform: scale(1) scaleY(-1) translateX(-50%);
    -ms-transform: scale(1) scaleY(-1) translateX(-50%);
    -o-transform: scale(1) scaleY(-1) translateX(-50%);
    transform: scale(1) scaleY(-1) translateX(-50%);
}

.uabb-top-row-separator.uabb-big-triangle svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scale(1);
    -moz-transform: translateX(-50%) scale(1);
    -ms-transform: translateX(-50%) scale(1);
    -o-transform: translateX(-50%) scale(1);
    transform: translateX(-50%) scale(1);
}

.uabb-top-row-separator.uabb-xlarge-triangle-right svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scale(-1);
    -moz-transform: translateX(-50%) scale(-1);
    -ms-transform: translateX(-50%) scale(-1);
    -o-transform: translateX(-50%) scale(-1);
    transform: translateX(-50%) scale(-1);
}

.uabb-bottom-row-separator.uabb-xlarge-triangle-right svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleX(-1);
    -moz-transform: translateX(-50%) scaleX(-1);
    -ms-transform: translateX(-50%) scaleX(-1);
    -o-transform: translateX(-50%) scaleX(-1);
    transform: translateX(-50%) scaleX(-1);
}

.uabb-top-row-separator.uabb-curve-up-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleY(-1);
    -moz-transform: translateX(-50%) scaleY(-1);
    -ms-transform: translateX(-50%) scaleY(-1);
    -o-transform: translateX(-50%) scaleY(-1);
    transform: translateX(-50%) scaleY(-1);
}

.uabb-top-row-separator.uabb-curve-down-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scale(-1);
    -moz-transform: translateX(-50%) scale(-1);
    -ms-transform: translateX(-50%) scale(-1);
    -o-transform: translateX(-50%) scale(-1);
    transform: translateX(-50%) scale(-1);
}

.uabb-bottom-row-separator.uabb-curve-down-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleX(-1);
    -moz-transform: translateX(-50%) scaleX(-1);
    -ms-transform: translateX(-50%) scaleX(-1);
    -o-transform: translateX(-50%) scaleX(-1);
    transform: translateX(-50%) scaleX(-1);
}

.uabb-top-row-separator.uabb-tilt-left-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scale(-1);
    -moz-transform: translateX(-50%) scale(-1);
    -ms-transform: translateX(-50%) scale(-1);
    -o-transform: translateX(-50%) scale(-1);
    transform: translateX(-50%) scale(-1);
}

.uabb-top-row-separator.uabb-tilt-right-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleY(-1);
    -moz-transform: translateX(-50%) scaleY(-1);
    -ms-transform: translateX(-50%) scaleY(-1);
    -o-transform: translateX(-50%) scaleY(-1);
    transform: translateX(-50%) scaleY(-1);
}

.uabb-bottom-row-separator.uabb-tilt-left-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.uabb-bottom-row-separator.uabb-tilt-right-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleX(-1);
    -moz-transform: translateX(-50%) scaleX(-1);
    -ms-transform: translateX(-50%) scaleX(-1);
    -o-transform: translateX(-50%) scaleX(-1);
    transform: translateX(-50%) scaleX(-1);
}

.uabb-top-row-separator.uabb-tilt-left-separator,
.uabb-top-row-separator.uabb-tilt-right-separator {
    top: 0;
}

.uabb-bottom-row-separator.uabb-tilt-left-separator,
.uabb-bottom-row-separator.uabb-tilt-right-separator {
    bottom: 0;
}

.uabb-top-row-separator.uabb-arrow-outward-separator svg,
.uabb-top-row-separator.uabb-arrow-inward-separator svg,
.uabb-top-row-separator.uabb-cloud-separator svg,
.uabb-top-row-separator.uabb-multi-triangle svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleY(-1);
    -moz-transform: translateX(-50%) scaleY(-1);
    -ms-transform: translateX(-50%) scaleY(-1);
    -o-transform: translateX(-50%) scaleY(-1);
    transform: translateX(-50%) scaleY(-1);
}

.uabb-bottom-row-separator.uabb-multi-triangle svg {
    bottom: -2px;
}

.uabb-row-separator.uabb-round-split:after,
.uabb-row-separator.uabb-round-split:before {
    left: 0;
    width: 50%;
    background: inherit inherit/inherit inherit inherit inherit;
    content: '';
    position: absolute
}

.uabb-button-wrap a,
.uabb-button-wrap a:visited {
    display: inline-block;
    font-size: 16px;
    line-height: 18px;
    text-decoration: none;
    text-shadow: none;
}

.uabb-button-wrap a *,
.uabb-button-wrap a:visited * {}

.fl-builder-content .uabb-button:hover {
    text-decoration: none;
}

.fl-builder-content .uabb-button-width-full .uabb-button {
    display: block;
    text-align: center;
}

.uabb-button-width-custom .uabb-button {
    display: inline-block;
    text-align: center;
    max-width: 100%;
}

.fl-builder-content .uabb-button-left {
    text-align: left;
}

.fl-builder-content .uabb-button-center {
    text-align: center;
}

.fl-builder-content .uabb-button-right {
    text-align: right;
}

.fl-builder-content .uabb-button i,
.fl-builder-content .uabb-button i:before {
    font-size: 1em;
    height: 1em;
    line-height: 1em;
    width: 1em;
}

.uabb-button .uabb-button-icon-after {
    margin-left: 8px;
    margin-right: 0;
}

.uabb-button .uabb-button-icon-before {
    margin-left: 0;
    margin-right: 8px;
}

.uabb-button .uabb-button-icon-no-text {
    margin: 0;
}

.uabb-button-has-icon .uabb-button-text {
    vertical-align: middle;
}

.uabb-icon-wrap {
    display: inline-block;
}

.uabb-icon a {
    text-decoration: none;
}

.uabb-icon i {
    display: block;
}

.uabb-icon i:before {
    border: none !important;
    background: none !important;
}

.uabb-icon-text {
    display: table-cell;
    text-align: left;
    padding-left: 15px;
    vertical-align: middle;
}

.uabb-icon-text *:last-child {
    margin: 0 !important;
    padding: 0 !important;
}

.uabb-icon-text a {
    text-decoration: none;
}

.uabb-photo {
    line-height: 0;
    position: relative;
    z-index: 2;
}

.uabb-photo-align-left {
    text-align: left;
}

.uabb-photo-align-center {
    text-align: center;
}

.uabb-photo-align-right {
    text-align: right;
}

.uabb-photo-content {
    border-radius: 0;
    display: inline-block;
    line-height: 0;
    position: relative;
    max-width: 100%;
    overflow: hidden;
}

.uabb-photo-content img {
    border-radius: inherit;
    display: inline;
    height: auto;
    max-width: 100%;
    width: auto;
}

.fl-builder-content .uabb-photo-crop-circle img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.fl-builder-content .uabb-photo-crop-square img {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.uabb-photo-caption {
    font-size: 13px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.uabb-photo-caption-below {
    padding-bottom: 20px;
    padding-top: 10px;
}

.uabb-photo-caption-hover {
    background: rgba(0, 0, 0, 0.7);
    bottom: 0;
    color: #fff;
    left: 0;
    opacity: 0;
    visibility: hidden;
    filter: alpha(opacity=0);
    padding: 10px 15px;
    position: absolute;
    right: 0;
    -webkit-transition: visibility 200ms linear;
    -moz-transition: visibility 200ms linear;
    transition: visibility 200ms linear;
}

.uabb-photo-content:hover .uabb-photo-caption-hover {
    opacity: 100;
    visibility: visible;
}

.uabb-active-btn {
    background: #1e8cbe;
    border-color: #0074a2;
    -webkit-box-shadow: inset 0 1px 0 rgba(120, 200, 230, .6);
    box-shadow: inset 0 1px 0 rgba(120, 200, 230, .6);
    color: white;
}

.fl-builder-bar .fl-builder-add-content-button {
    display: block !important;
    opacity: 1 !important;
}

.uabb-imgicon-wrap .uabb-icon {
    display: block;
}

.uabb-imgicon-wrap .uabb-icon i {
    float: none;
}

.uabb-imgicon-wrap .uabb-image {
    line-height: 0;
    position: relative;
}

.uabb-imgicon-wrap .uabb-image-align-left {
    text-align: left;
}

.uabb-imgicon-wrap .uabb-image-align-center {
    text-align: center;
}

.uabb-imgicon-wrap .uabb-image-align-right {
    text-align: right;
}

.uabb-imgicon-wrap .uabb-image-content {
    display: inline-block;
    border-radius: 0;
    line-height: 0;
    position: relative;
    max-width: 100%;
}

.uabb-imgicon-wrap .uabb-image-content img {
    display: inline;
    height: auto !important;
    max-width: 100%;
    width: auto;
    border-radius: inherit;
    box-shadow: none;
    box-sizing: content-box;
}

.fl-builder-content .uabb-imgicon-wrap .uabb-image-crop-circle img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.fl-builder-content .uabb-imgicon-wrap .uabb-image-crop-square img {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.uabb-creative-button-wrap a,
.uabb-creative-button-wrap a:visited {
    background: #fafafa;
    border: 1px solid #ccc;
    color: #333;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    position: relative;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
}

.uabb-creative-button-wrap a:focus {
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
}

.uabb-creative-button-wrap a .uabb-creative-button-text,
.uabb-creative-button-wrap a .uabb-creative-button-icon,
.uabb-creative-button-wrap a:visited .uabb-creative-button-text,
.uabb-creative-button-wrap a:visited .uabb-creative-button-icon {
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
}

.uabb-creative-button-wrap a:hover {
    text-decoration: none;
}

.uabb-creative-button-wrap .uabb-creative-button-width-full .uabb-creative-button {
    display: block;
    text-align: center;
}

.uabb-creative-button-wrap .uabb-creative-button-width-custom .uabb-creative-button {
    display: inline-block;
    text-align: center;
    max-width: 100%;
}

.uabb-creative-button-wrap .uabb-creative-button-left {
    text-align: left;
}

.uabb-creative-button-wrap .uabb-creative-button-center {
    text-align: center;
}

.uabb-creative-button-wrap .uabb-creative-button-right {
    text-align: right;
}

.uabb-creative-button-wrap .uabb-creative-button i {
    font-size: 1.3em;
    height: auto;
    vertical-align: middle;
    width: auto;
}

.uabb-creative-button-wrap .uabb-creative-button .uabb-creative-button-icon-after {
    margin-left: 8px;
    margin-right: 0;
}

.uabb-creative-button-wrap .uabb-creative-button .uabb-creative-button-icon-before {
    margin-right: 8px;
    margin-left: 0;
}

.uabb-creative-button-wrap.uabb-creative-button-icon-no-text .uabb-creative-button i {
    margin: 0;
}

.uabb-creative-button-wrap .uabb-creative-button-has-icon .uabb-creative-button-text {
    vertical-align: middle;
}

.uabb-creative-button.uabb-creative-transparent-btn {
    background: transparent;
}

.uabb-creative-button.uabb-creative-transparent-btn:after {
    content: '';
    position: absolute;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
}

.uabb-transparent-fill-top-btn:after,
.uabb-transparent-fill-bottom-btn:after {
    width: 100%;
    height: 0;
    left: 0;
}

.uabb-transparent-fill-top-btn:after {
    top: 0;
}

.uabb-transparent-fill-bottom-btn:after {
    bottom: 0;
}

.uabb-transparent-fill-left-btn:after,
.uabb-transparent-fill-right-btn:after {
    width: 0;
    height: 100%;
    top: 0;
}

.uabb-transparent-fill-left-btn:after {
    left: 0;
}

.uabb-transparent-fill-right-btn:after {
    right: 0;
}

.uabb-transparent-fill-center-btn:after {
    width: 0;
    height: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.uabb-transparent-fill-diagonal-btn:after,
.uabb-transparent-fill-horizontal-btn:after {
    width: 100%;
    height: 0;
    top: 50%;
    left: 50%;
}

.uabb-transparent-fill-diagonal-btn {
    overflow: hidden;
}

.uabb-transparent-fill-diagonal-btn:after {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.uabb-transparent-fill-horizontal-btn:after {
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.uabb-creative-button-wrap .uabb-creative-threed-btn.uabb-threed_down-btn,
.uabb-creative-button-wrap .uabb-creative-threed-btn.uabb-threed_up-btn,
.uabb-creative-button-wrap .uabb-creative-threed-btn.uabb-threed_left-btn,
.uabb-creative-button-wrap .uabb-creative-threed-btn.uabb-threed_right-btn {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.perspective {
    -webkit-perspective: 800px;
    -moz-perspective: 800px;
    perspective: 800px;
    margin: 0;
}

.uabb-creative-button.uabb-creative-threed-btn:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    transition: all 200ms linear;
}

.uabb-creative-button.uabb-creative-threed-btn {
    outline: 1px solid transparent;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.uabb-creative-threed-btn.uabb-animate_top-btn:after {
    height: 40%;
    left: 0;
    top: -40%;
    width: 100%;
    -webkit-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    transform: rotateX(90deg);
}

.uabb-creative-threed-btn.uabb-animate_bottom-btn:after {
    width: 100%;
    height: 40%;
    left: 0;
    top: 100%;
    -webkit-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    -ms-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
}

.uabb-creative-threed-btn.uabb-animate_left-btn:after {
    width: 20%;
    height: 100%;
    left: -20%;
    top: 0;
    -webkit-transform-origin: 100% 0%;
    -moz-transform-origin: 100% 0%;
    -ms-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: rotateY(-60deg);
    -moz-transform: rotateY(-60deg);
    -ms-transform: rotateY(-60deg);
    transform: rotateY(-60deg);
}

.uabb-creative-threed-btn.uabb-animate_right-btn:after {
    width: 20%;
    height: 100%;
    left: 104%;
    top: 0;
    -webkit-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: rotateY(120deg);
    -moz-transform: rotateY(120deg);
    -ms-transform: rotateY(120deg);
    transform: rotateY(120deg);
}

.uabb-animate_top-btn:hover {
    -webkit-transform: rotateX(-15deg);
    -moz-transform: rotateX(-15deg);
    -ms-transform: rotateX(-15deg);
    -o-transform: rotateX(-15deg);
    transform: rotateX(-15deg);
}

.uabb-animate_bottom-btn:hover {
    -webkit-transform: rotateX(15deg);
    -moz-transform: rotateX(15deg);
    -ms-transform: rotateX(15deg);
    -o-transform: rotateX(15deg);
    transform: rotateX(15deg);
}

.uabb-animate_left-btn:hover {
    -webkit-transform: rotateY(6deg);
    -moz-transform: rotateY(6deg);
    -ms-transform: rotateY(6deg);
    -o-transform: rotateY(6deg);
    transform: rotateY(6deg);
}

.uabb-animate_right-btn:hover {
    -webkit-transform: rotateY(-6deg);
    -moz-transform: rotateY(-6deg);
    -ms-transform: rotateY(-6deg);
    -o-transform: rotateY(-6deg);
    transform: rotateY(-6deg);
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn,
.uabb-creative-flat-btn.uabb-animate_to_left-btn,
.uabb-creative-flat-btn.uabb-animate_from_top-btn,
.uabb-creative-flat-btn.uabb-animate_from_bottom-btn {
    overflow: hidden;
    position: relative;
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn i,
.uabb-creative-flat-btn.uabb-animate_to_left-btn i,
.uabb-creative-flat-btn.uabb-animate_from_top-btn i,
.uabb-creative-flat-btn.uabb-animate_from_bottom-btn i {
    bottom: 0;
    height: 100%;
    margin: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    width: 100%;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    transition: all 200ms linear;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn .uabb-button-text,
.uabb-creative-flat-btn.uabb-animate_to_left-btn .uabb-button-text,
.uabb-creative-flat-btn.uabb-animate_from_top-btn .uabb-button-text,
.uabb-creative-flat-btn.uabb-animate_from_bottom-btn .uabb-button-text {
    display: inline-block;
    width: 100%;
    height: 100%;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn i:before,
.uabb-creative-flat-btn.uabb-animate_to_left-btn i:before,
.uabb-creative-flat-btn.uabb-animate_from_top-btn i:before,
.uabb-creative-flat-btn.uabb-animate_from_bottom-btn i:before {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn:hover i,
.uabb-creative-flat-btn.uabb-animate_to_left-btn:hover i {
    left: 0;
}

.uabb-creative-flat-btn.uabb-animate_from_top-btn:hover i,
.uabb-creative-flat-btn.uabb-animate_from_bottom-btn:hover i {
    top: 0;
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn i {
    top: 0;
    left: -100%;
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn:hover .uabb-button-text {
    -webkit-transform: translateX(200%);
    -moz-transform: translateX(200%);
    -ms-transform: translateX(200%);
    -o-transform: translateX(200%);
    transform: translateX(200%);
}

.uabb-creative-flat-btn.uabb-animate_to_left-btn i {
    top: 0;
    left: 100%;
}

.uabb-creative-flat-btn.uabb-animate_to_left-btn:hover .uabb-button-text {
    -webkit-transform: translateX(-200%);
    -moz-transform: translateX(-200%);
    -ms-transform: translateX(-200%);
    -o-transform: translateX(-200%);
    transform: translateX(-200%);
}

.uabb-creative-flat-btn.uabb-animate_from_top-btn i {
    top: -100%;
    left: 0;
}

.uabb-creative-flat-btn.uabb-animate_from_top-btn:hover .uabb-button-text {
    -webkit-transform: translateY(400px);
    -moz-transform: translateY(400px);
    -ms-transform: translateY(400px);
    -o-transform: translateY(400px);
    transform: translateY(400px);
}

.uabb-creative-flat-btn.uabb-animate_from_bottom-btn i {
    top: 100%;
    left: 0;
}

.uabb-creative-flat-btn.uabb-animate_from_bottom-btn:hover .uabb-button-text {
    -webkit-transform: translateY(-400px);
    -moz-transform: translateY(-400px);
    -ms-transform: translateY(-400px);
    -o-transform: translateY(-400px);
    transform: translateY(-400px);
}

.uabb-tab-acc-content .wp-video,
.uabb-tab-acc-content video.wp-video-shortcode,
.uabb-tab-acc-content .mejs-container:not(.mejs-audio),
.uabb-tab-acc-content .mejs-overlay.load,
.uabb-adv-accordion-content .wp-video,
.uabb-adv-accordion-content video.wp-video-shortcode,
.uabb-adv-accordion-content .mejs-container:not(.mejs-audio),
.uabb-adv-accordion-content .mejs-overlay.load {
    width: 100% !important;
    height: 100% !important;
}

.uabb-tab-acc-content .mejs-container:not(.mejs-audio),
.uabb-adv-accordion-content .mejs-container:not(.mejs-audio) {
    padding-top: 56.25%;
}

.uabb-tab-acc-content .wp-video,
.uabb-tab-acc-content video.wp-video-shortcode,
.uabb-adv-accordion-content .wp-video,
.uabb-adv-accordion-content video.wp-video-shortcode {
    max-width: 100% !important;
}

.uabb-tab-acc-content video.wp-video-shortcode,
.uabb-adv-accordion-content video.wp-video-shortcode {
    position: relative;
}

.uabb-tab-acc-content .mejs-container:not(.mejs-audio) .mejs-mediaelement,
.uabb-adv-accordion-content .mejs-container:not(.mejs-audio) .mejs-mediaelement {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.uabb-tab-acc-content .mejs-overlay-play,
.uabb-adv-accordion-content .mejs-overlay-play {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto !important;
    height: auto !important;
}

.uabb-creative-button-wrap a,
.uabb-creative-button-wrap a:visited {}

.uabb-dual-button .uabb-btn,
.uabb-dual-button .uabb-btn:visited {}

.uabb-js-breakpoint {
    content: "default";
    display: none;
}

@media screen and (max-width: 1000px) {
    .uabb-js-breakpoint {
        content: "1000";
    }
}

@media screen and (max-width: 800px) {
    .uabb-js-breakpoint {
        content: "800";
    }
}

.fl-builder-row-settings #fl-field-separator_position {
    display: none !important;
}

.fl-node-5bdc295cdc3a7 > .fl-col-content {}

.fl-node-5bdc295cdc427 > .fl-col-content {}

.fl-node-5bdc295cdc3a7 > .fl-col-content {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -webkit-transition: -webkit-box-shadow 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    -moz-transition: -moz-box-shadow 500ms ease-in-out, -moz-transform 500ms ease-in-out;
    transition: box-shadow 500ms ease-in-out, transform 500ms ease-in-out;
    will-change: box-shadow;
}

.fl-node-5bdc295cdc427 > .fl-col-content {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -webkit-transition: -webkit-box-shadow 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    -moz-transition: -moz-box-shadow 500ms ease-in-out, -moz-transform 500ms ease-in-out;
    transition: box-shadow 500ms ease-in-out, transform 500ms ease-in-out;
    will-change: box-shadow;
}

.fl-node-5bcead71a5963 {
    color: #ffffff;
}

.fl-builder-content .fl-node-5bcead71a5963 *:not(input):not(textarea):not(select):not(a):not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(.fl-menu-mobile-toggle) {
    color: inherit;
}

.fl-builder-content .fl-node-5bcead71a5963 a {
    color: #ffffff;
}

.fl-builder-content .fl-node-5bcead71a5963 a:hover {
    color: #ffffff;
}

.fl-builder-content .fl-node-5bcead71a5963 h1,
.fl-builder-content .fl-node-5bcead71a5963 h2,
.fl-builder-content .fl-node-5bcead71a5963 h3,
.fl-builder-content .fl-node-5bcead71a5963 h4,
.fl-builder-content .fl-node-5bcead71a5963 h5,
.fl-builder-content .fl-node-5bcead71a5963 h6,
.fl-builder-content .fl-node-5bcead71a5963 h1 a,
.fl-builder-content .fl-node-5bcead71a5963 h2 a,
.fl-builder-content .fl-node-5bcead71a5963 h3 a,
.fl-builder-content .fl-node-5bcead71a5963 h4 a,
.fl-builder-content .fl-node-5bcead71a5963 h5 a,
.fl-builder-content .fl-node-5bcead71a5963 h6 a {
    color: #ffffff;
}

.fl-node-5bcead71a5963 .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5bcead71a5963 .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5bcead71a5963 .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5bcead71a5963 > .fl-row-content-wrap {
    background-color: #104fa0;
    border-top-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
}

.fl-node-5bcead71a5963 > .fl-row-content-wrap {
    padding-top: 20px;
    padding-bottom: 20px;
}

.fl-node-5bcead71a587d {
    color: #ffffff;
}

.fl-builder-content .fl-node-5bcead71a587d *:not(input):not(textarea):not(select):not(a):not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(.fl-menu-mobile-toggle) {
    color: inherit;
}

.fl-builder-content .fl-node-5bcead71a587d a {
    color: #ffffff;
}

.fl-builder-content .fl-node-5bcead71a587d a:hover {
    color: #ffffff;
}

.fl-builder-content .fl-node-5bcead71a587d h1,
.fl-builder-content .fl-node-5bcead71a587d h2,
.fl-builder-content .fl-node-5bcead71a587d h3,
.fl-builder-content .fl-node-5bcead71a587d h4,
.fl-builder-content .fl-node-5bcead71a587d h5,
.fl-builder-content .fl-node-5bcead71a587d h6,
.fl-builder-content .fl-node-5bcead71a587d h1 a,
.fl-builder-content .fl-node-5bcead71a587d h2 a,
.fl-builder-content .fl-node-5bcead71a587d h3 a,
.fl-builder-content .fl-node-5bcead71a587d h4 a,
.fl-builder-content .fl-node-5bcead71a587d h5 a,
.fl-builder-content .fl-node-5bcead71a587d h6 a {
    color: #ffffff;
}

.fl-node-5bcead71a587d .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5bcead71a587d .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5bcead71a587d .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5bcead71a587d > .fl-row-content-wrap {
    background-color: #ffffff;
    border-top-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
}

.fl-node-5bcead71a587d > .fl-row-content-wrap {
    padding-top: 20px;
    padding-bottom: 10px;
}

.fl-node-5bcead71a5a1a {
    width: 33.33%;
}

.fl-node-5bcead71a5a1a > .fl-col-content {
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
}

.fl-node-5bcead71a5a0a {
    width: 33.33%;
}

.fl-node-5bcead71a5a0a > .fl-col-content {
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
}

.fl-node-5bcead71a59d2 {
    width: 33.33%;
}

.fl-node-5bcead71a59d2 > .fl-col-content {
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
}

.fl-node-5bfd4c4636593 {
    width: 44.16%;
}

.fl-node-5bfd4c46365d3 {
    width: 28.85%;
}

.fl-node-5bfd4c4636610 {
    width: 26.99%;
}

.fl-node-5bcead71a58f4 {
    width: 100%;
}

.fl-module-heading .fl-heading {
    padding: 0 !important;
    margin: 0 !important;
}

.fl-node-5bcead71a5caa.fl-module-heading .fl-heading {
    text-align: center;
}

.fl-node-5bcead71a5caa > .fl-module-content {
    margin-bottom: 0px;
}

/* .fl-node-5bceb3beb3ac1 .fl-rich-text,
.fl-node-5bceb3beb3ac1 .fl-rich-text * {
    color: # !important;
} */

.fl-node-5bceb3beb3ac1 > .fl-module-content {
    margin-right: 65px;
    margin-bottom: 10px;
    margin-left: 65px;
}

@media (max-width: 1000px) {
    .fl-node-5bceb3beb3ac1.fl-module > .fl-module-content {
        margin-right: 30px;
        margin-left: 30px;
    }
}

@media (max-width: 800px) {
    .fl-node-5bceb3beb3ac1 > .fl-module-content {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.fl-node-5bcead71a5bc6.fl-module-heading .fl-heading {
    text-align: center;
}

.fl-node-5bcead71a5bc6 > .fl-module-content {
    margin-bottom: 0px;
}

.fl-node-5bcead71a5a53 > .fl-module-content {
    margin-right: 65px;
    margin-bottom: 10px;
    margin-left: 65px;
}

@media (max-width: 1000px) {
    .fl-node-5bcead71a5a53.fl-module > .fl-module-content {
        margin-right: 30px;
        margin-left: 30px;
    }
}

@media (max-width: 800px) {
    .fl-node-5bcead71a5a53 > .fl-module-content {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.fl-node-5bcead71a5ce5.fl-module-heading .fl-heading {
    text-align: center;
}

/* .fl-node-5bceb3eaa966d .fl-rich-text,
.fl-node-5bceb3eaa966d .fl-rich-text * {
    color: # !important;
} */

.fl-node-5bceb3eaa966d > .fl-module-content {
    margin-top: 0px;
    margin-right: 65px;
    margin-bottom: 10px;
    margin-left: 65px;
}

@media (max-width: 1000px) {
    .fl-node-5bceb3eaa966d.fl-module > .fl-module-content {
        margin-right: 30px;
        margin-left: 30px;
    }
}

@media (max-width: 800px) {
    .fl-node-5bceb3eaa966d > .fl-module-content {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.fl-node-5bfd4c6bd30c4.fl-module-heading .fl-heading {
    text-align: right;
}

@media(max-width: 800px) {
    .fl-node-5bfd4c6bd30c4.fl-module-heading .fl-heading {
        text-align: center;
    }
}

.fl-node-5bfd4c6bd30c4 > .fl-module-content {
    margin-top: 12px;
    margin-bottom: 0px;
}

.pp-social-icons .pp-social-icon {
    display: inline-block;
    line-height: 0;
    margin-bottom: 10px;
    margin-top: 10px;
}

.pp-social-icons .fa {
    font-family: FontAwesome;
}

.pp-social-icons-left {
    text-align: left;
}

.pp-social-icons-center {
    text-align: center;
}

.pp-social-icons-right {
    text-align: right;
}

.fl-node-5bceb50bdefec .fl-module-content .pp-social-icon {}

.fl-node-5bceb50bdefec .fl-module-content .pp-social-icon a,
.fl-node-5bceb50bdefec .fl-module-content .pp-social-icon a:hover {
    text-decoration: none;
}

.fl-node-5bceb50bdefec .fl-module-content .pp-social-icon a {
    display: inline-block;
    height: 40px;
    width: 40px;
}

.fl-node-5bceb50bdefec .fl-module-content .pp-social-icon i {
    float: left;
    color: #ffffff;
    /* background: #; */
    border-radius: 100px;
    font-size: 30px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    /* border: 0px solid #; */
}


.fl-node-5bceb50bdefec .pp-social-icons-left .pp-social-icon {
    margin-right: 0px;
}

.fl-node-5bceb50bdefec .pp-social-icons-center .pp-social-icon {
    margin-left: 0px;
    margin-right: 0px;
}

.fl-node-5bceb50bdefec .pp-social-icons-right .pp-social-icon {
    margin-left: 0px;
}

@media only screen and (max-width: 800px) {
    .fl-node-5bceb50bdefec .pp-responsive-left {
        text-align: left;
    }

    .fl-node-5bceb50bdefec .pp-responsive-center {
        text-align: center;
    }

    .fl-node-5bceb50bdefec .pp-responsive-right {
        text-align: right;
    }

    .fl-node-5bceb50bdefec .pp-responsive-left .pp-social-icon {
        margin-right: 0px;
    }

    .fl-node-5bceb50bdefec .pp-responsive-center .pp-social-icon {
        margin-left: 0px;
        margin-right: 0px;
    }

    .fl-node-5bceb50bdefec .pp-responsive-center .pp-social-icon {
        margin-left: 0px;
    }
}

.fl-node-5bceb50bdefec > .fl-module-content {
    margin-top: 0px;
    margin-bottom: 0px;
}

.fl-node-5bceb5a68e58d.fl-module-heading .fl-heading {
    text-align: left;
}

@media(max-width: 800px) {
    .fl-node-5bceb5a68e58d.fl-module-heading .fl-heading {
        text-align: center;
    }
}

.fl-node-5bceb5a68e58d > .fl-module-content {
    margin-top: 12px;
    margin-bottom: 0px;
}

/* .fl-node-5bcead71a592b .fl-rich-text,
.fl-node-5bcead71a592b .fl-rich-text * {
    color: # !important;
} */

.fl-node-5bcead71a592b > .fl-module-content {
    margin-top: 0px;
    margin-bottom: 0px;
}

img.mfp-img {
    padding-bottom: 40px !important;
}

@media (max-width: 800px) {
    .fl-photo-content {
        width: 100%;
    }
}

.fl-node-5c8956419ac50 .fl-photo {
    text-align: center;
}

.fl-node-5bcead71a5963 .uabb-top-row-separator.uabb-has-svg svg {
    width: calc(100% + 1.5px);
}

.fl-node-5bcead71a5963 .uabb-bottom-row-separator.uabb-has-svg svg {
    width: calc(100% + 1.5px);
}

.fl-node-5bcead71a5963 #uabb-top-slit2,
.fl-node-5bcead71a5963 #uabb-top-slit3 {
    fill: #afafaf;
}

.fl-node-5bcead71a5963 #uabb-bottom-slit2,
.fl-node-5bcead71a5963 #uabb-bottom-slit3 {
    fill: #afafaf;
}

.fl-node-5bcead71a5963 #uabb-top-pine-tree-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a5963 #uabb-bottom-pine-tree-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a5963 #uabb-top-pine-tree-bend-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a5963 #uabb-bottom-pine-tree-bend-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a5963 #uabb-top-slime-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a5963 #uabb-bottom-slime-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a5963 #uabb-top-wave-slide-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a5963 #uabb-bottom-wave-slide-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a5963 .uabb-top-row-separator {
    z-index: 9;
}

.fl-node-5bcead71a5963 .uabb-bottom-row-separator {
    z-index: 9;
}

@media(max-width: 1000px) {
    .fl-node-5bcead71a5963 .uabb-top-row-separator.uabb-has-svg svg {
        width: 100%;
    }

    .fl-node-5bcead71a5963 .uabb-bottom-row-separator.uabb-has-svg svg {
        width: 100%;
    }
}

@media(max-width: 800px) {
    .fl-node-5bcead71a5963 .uabb-top-row-separator.uabb-has-svg svg {
        width: 100%;
    }

    .fl-node-5bcead71a5963 .uabb-bottom-row-separator.uabb-has-svg svg {
        width: 100%;
    }
}

.fl-node-5bcead71a587d .uabb-top-row-separator.uabb-has-svg svg {
    width: calc(100% + 1.5px);
}

.fl-node-5bcead71a587d .uabb-bottom-row-separator.uabb-has-svg svg {
    width: calc(100% + 1.5px);
}

.fl-node-5bcead71a587d #uabb-top-slit2,
.fl-node-5bcead71a587d #uabb-top-slit3 {
    fill: #afafaf;
}

.fl-node-5bcead71a587d #uabb-bottom-slit2,
.fl-node-5bcead71a587d #uabb-bottom-slit3 {
    fill: #afafaf;
}

.fl-node-5bcead71a587d #uabb-top-pine-tree-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a587d #uabb-bottom-pine-tree-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a587d #uabb-top-pine-tree-bend-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a587d #uabb-bottom-pine-tree-bend-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a587d #uabb-top-slime-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a587d #uabb-bottom-slime-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a587d #uabb-top-wave-slide-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a587d #uabb-bottom-wave-slide-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5bcead71a587d .uabb-top-row-separator {
    z-index: 9;
}

.fl-node-5bcead71a587d .uabb-bottom-row-separator {
    z-index: 9;
}

@media(max-width: 1000px) {
    .fl-node-5bcead71a587d .uabb-top-row-separator.uabb-has-svg svg {
        width: 100%;
    }

    .fl-node-5bcead71a587d .uabb-bottom-row-separator.uabb-has-svg svg {
        width: 100%;
    }
}

@media(max-width: 800px) {
    .fl-node-5bcead71a587d .uabb-top-row-separator.uabb-has-svg svg {
        width: 100%;
    }

    .fl-node-5bcead71a587d .uabb-bottom-row-separator.uabb-has-svg svg {
        width: 100%;
    }
}

.fl-col-group-equal-height.fl-col-group-align-bottom .fl-col-content {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
}

.uabb-module-content h1,
.uabb-module-content h2,
.uabb-module-content h3,
.uabb-module-content h4,
.uabb-module-content h5,
.uabb-module-content h6 {
    margin: 0;
    clear: both;
}

.fl-module-content a,
.fl-module-content a:hover,
.fl-module-content a:focus {
    text-decoration: none;
}

.uabb-row-separator {
    position: absolute;
    width: 100%;
    left: 0;
}

.uabb-top-row-separator {
    top: 0;
    bottom: auto
}

.uabb-bottom-row-separator {
    top: auto;
    bottom: 0;
}

.fl-builder-content-editing .fl-visible-medium.uabb-row,
.fl-builder-content-editing .fl-visible-medium-mobile.uabb-row,
.fl-builder-content-editing .fl-visible-mobile.uabb-row {
    display: none !important;
}

@media (max-width: 992px) {

    .fl-builder-content-editing .fl-visible-desktop.uabb-row,
    .fl-builder-content-editing .fl-visible-mobile.uabb-row {
        display: none !important;
    }

    .fl-builder-content-editing .fl-visible-desktop-medium.uabb-row,
    .fl-builder-content-editing .fl-visible-medium.uabb-row,
    .fl-builder-content-editing .fl-visible-medium-mobile.uabb-row {
        display: block !important;
    }
}

@media (max-width: 768px) {

    .fl-builder-content-editing .fl-visible-desktop.uabb-row,
    .fl-builder-content-editing .fl-visible-desktop-medium.uabb-row,
    .fl-builder-content-editing .fl-visible-medium.uabb-row {
        display: none !important;
    }

    .fl-builder-content-editing .fl-visible-medium-mobile.uabb-row,
    .fl-builder-content-editing .fl-visible-mobile.uabb-row {
        display: block !important;
    }
}

.fl-responsive-preview-content .fl-builder-content-editing {
    overflow-x: hidden;
    overflow-y: visible;
}

.uabb-row-separator svg {
    width: 100%;
}

.uabb-top-row-separator.uabb-has-svg svg {
    position: absolute;
    padding: 0;
    margin: 0;
    left: 50%;
    top: -1px;
    bottom: auto;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.uabb-bottom-row-separator.uabb-has-svg svg {
    position: absolute;
    padding: 0;
    margin: 0;
    left: 50%;
    bottom: -1px;
    top: auto;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.uabb-bottom-row-separator.uabb-has-svg .uasvg-wave-separator {
    bottom: 0;
}

.uabb-top-row-separator.uabb-has-svg .uasvg-wave-separator {
    top: 0;
}

.uabb-bottom-row-separator.uabb-svg-triangle svg,
.uabb-bottom-row-separator.uabb-xlarge-triangle svg,
.uabb-top-row-separator.uabb-xlarge-triangle-left svg,
.uabb-bottom-row-separator.uabb-svg-circle svg,
.uabb-top-row-separator.uabb-slime-separator svg,
.uabb-top-row-separator.uabb-grass-separator svg,
.uabb-top-row-separator.uabb-grass-bend-separator svg,
.uabb-bottom-row-separator.uabb-mul-triangles-separator svg,
.uabb-top-row-separator.uabb-wave-slide-separator svg,
.uabb-top-row-separator.uabb-pine-tree-separator svg,
.uabb-top-row-separator.uabb-pine-tree-bend-separator svg,
.uabb-bottom-row-separator.uabb-stamp-separator svg,
.uabb-bottom-row-separator.uabb-xlarge-circle svg,
.uabb-top-row-separator.uabb-wave-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleY(-1);
    -moz-transform: translateX(-50%) scaleY(-1);
    -ms-transform: translateX(-50%) scaleY(-1);
    -o-transform: translateX(-50%) scaleY(-1);
    transform: translateX(-50%) scaleY(-1);
}

.uabb-bottom-row-separator.uabb-big-triangle svg {
    left: 50%;
    -webkit-transform: scale(1) scaleY(-1) translateX(-50%);
    -moz-transform: scale(1) scaleY(-1) translateX(-50%);
    -ms-transform: scale(1) scaleY(-1) translateX(-50%);
    -o-transform: scale(1) scaleY(-1) translateX(-50%);
    transform: scale(1) scaleY(-1) translateX(-50%);
}

.uabb-top-row-separator.uabb-big-triangle svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scale(1);
    -moz-transform: translateX(-50%) scale(1);
    -ms-transform: translateX(-50%) scale(1);
    -o-transform: translateX(-50%) scale(1);
    transform: translateX(-50%) scale(1);
}

.uabb-top-row-separator.uabb-xlarge-triangle-right svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scale(-1);
    -moz-transform: translateX(-50%) scale(-1);
    -ms-transform: translateX(-50%) scale(-1);
    -o-transform: translateX(-50%) scale(-1);
    transform: translateX(-50%) scale(-1);
}

.uabb-bottom-row-separator.uabb-xlarge-triangle-right svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleX(-1);
    -moz-transform: translateX(-50%) scaleX(-1);
    -ms-transform: translateX(-50%) scaleX(-1);
    -o-transform: translateX(-50%) scaleX(-1);
    transform: translateX(-50%) scaleX(-1);
}

.uabb-top-row-separator.uabb-curve-up-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleY(-1);
    -moz-transform: translateX(-50%) scaleY(-1);
    -ms-transform: translateX(-50%) scaleY(-1);
    -o-transform: translateX(-50%) scaleY(-1);
    transform: translateX(-50%) scaleY(-1);
}

.uabb-top-row-separator.uabb-curve-down-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scale(-1);
    -moz-transform: translateX(-50%) scale(-1);
    -ms-transform: translateX(-50%) scale(-1);
    -o-transform: translateX(-50%) scale(-1);
    transform: translateX(-50%) scale(-1);
}

.uabb-bottom-row-separator.uabb-curve-down-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleX(-1);
    -moz-transform: translateX(-50%) scaleX(-1);
    -ms-transform: translateX(-50%) scaleX(-1);
    -o-transform: translateX(-50%) scaleX(-1);
    transform: translateX(-50%) scaleX(-1);
}

.uabb-top-row-separator.uabb-tilt-left-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scale(-1);
    -moz-transform: translateX(-50%) scale(-1);
    -ms-transform: translateX(-50%) scale(-1);
    -o-transform: translateX(-50%) scale(-1);
    transform: translateX(-50%) scale(-1);
}

.uabb-top-row-separator.uabb-tilt-right-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleY(-1);
    -moz-transform: translateX(-50%) scaleY(-1);
    -ms-transform: translateX(-50%) scaleY(-1);
    -o-transform: translateX(-50%) scaleY(-1);
    transform: translateX(-50%) scaleY(-1);
}

.uabb-bottom-row-separator.uabb-tilt-left-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.uabb-bottom-row-separator.uabb-tilt-right-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleX(-1);
    -moz-transform: translateX(-50%) scaleX(-1);
    -ms-transform: translateX(-50%) scaleX(-1);
    -o-transform: translateX(-50%) scaleX(-1);
    transform: translateX(-50%) scaleX(-1);
}

.uabb-top-row-separator.uabb-tilt-left-separator,
.uabb-top-row-separator.uabb-tilt-right-separator {
    top: 0;
}

.uabb-bottom-row-separator.uabb-tilt-left-separator,
.uabb-bottom-row-separator.uabb-tilt-right-separator {
    bottom: 0;
}

.uabb-top-row-separator.uabb-arrow-outward-separator svg,
.uabb-top-row-separator.uabb-arrow-inward-separator svg,
.uabb-top-row-separator.uabb-cloud-separator svg,
.uabb-top-row-separator.uabb-multi-triangle svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleY(-1);
    -moz-transform: translateX(-50%) scaleY(-1);
    -ms-transform: translateX(-50%) scaleY(-1);
    -o-transform: translateX(-50%) scaleY(-1);
    transform: translateX(-50%) scaleY(-1);
}

.uabb-bottom-row-separator.uabb-multi-triangle svg {
    bottom: -2px;
}

.uabb-row-separator.uabb-round-split:after,
.uabb-row-separator.uabb-round-split:before {
    left: 0;
    width: 50%;
    background: inherit inherit/inherit inherit inherit inherit;
    content: '';
    position: absolute
}

.uabb-button-wrap a,
.uabb-button-wrap a:visited {
    display: inline-block;
    font-size: 16px;
    line-height: 18px;
    text-decoration: none;
    text-shadow: none;
}

.uabb-button-wrap a *,
.uabb-button-wrap a:visited * {}

.fl-builder-content .uabb-button:hover {
    text-decoration: none;
}

.fl-builder-content .uabb-button-width-full .uabb-button {
    display: block;
    text-align: center;
}

.uabb-button-width-custom .uabb-button {
    display: inline-block;
    text-align: center;
    max-width: 100%;
}

.fl-builder-content .uabb-button-left {
    text-align: left;
}

.fl-builder-content .uabb-button-center {
    text-align: center;
}

.fl-builder-content .uabb-button-right {
    text-align: right;
}

.fl-builder-content .uabb-button i,
.fl-builder-content .uabb-button i:before {
    font-size: 1em;
    height: 1em;
    line-height: 1em;
    width: 1em;
}

.uabb-button .uabb-button-icon-after {
    margin-left: 8px;
    margin-right: 0;
}

.uabb-button .uabb-button-icon-before {
    margin-left: 0;
    margin-right: 8px;
}

.uabb-button .uabb-button-icon-no-text {
    margin: 0;
}

.uabb-button-has-icon .uabb-button-text {
    vertical-align: middle;
}

.uabb-icon-wrap {
    display: inline-block;
}

.uabb-icon a {
    text-decoration: none;
}

.uabb-icon i {
    display: block;
}

.uabb-icon i:before {
    border: none !important;
    background: none !important;
}

.uabb-icon-text {
    display: table-cell;
    text-align: left;
    padding-left: 15px;
    vertical-align: middle;
}

.uabb-icon-text *:last-child {
    margin: 0 !important;
    padding: 0 !important;
}

.uabb-icon-text a {
    text-decoration: none;
}

.uabb-photo {
    line-height: 0;
    position: relative;
    z-index: 2;
}

.uabb-photo-align-left {
    text-align: left;
}

.uabb-photo-align-center {
    text-align: center;
}

.uabb-photo-align-right {
    text-align: right;
}

.uabb-photo-content {
    border-radius: 0;
    display: inline-block;
    line-height: 0;
    position: relative;
    max-width: 100%;
    overflow: hidden;
}

.uabb-photo-content img {
    border-radius: inherit;
    display: inline;
    height: auto;
    max-width: 100%;
    width: auto;
}

.fl-builder-content .uabb-photo-crop-circle img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.fl-builder-content .uabb-photo-crop-square img {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.uabb-photo-caption {
    font-size: 13px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.uabb-photo-caption-below {
    padding-bottom: 20px;
    padding-top: 10px;
}

.uabb-photo-caption-hover {
    background: rgba(0, 0, 0, 0.7);
    bottom: 0;
    color: #fff;
    left: 0;
    opacity: 0;
    visibility: hidden;
    filter: alpha(opacity=0);
    padding: 10px 15px;
    position: absolute;
    right: 0;
    -webkit-transition: visibility 200ms linear;
    -moz-transition: visibility 200ms linear;
    transition: visibility 200ms linear;
}

.uabb-photo-content:hover .uabb-photo-caption-hover {
    opacity: 100;
    visibility: visible;
}

.uabb-active-btn {
    background: #1e8cbe;
    border-color: #0074a2;
    -webkit-box-shadow: inset 0 1px 0 rgba(120, 200, 230, .6);
    box-shadow: inset 0 1px 0 rgba(120, 200, 230, .6);
    color: white;
}

.fl-builder-bar .fl-builder-add-content-button {
    display: block !important;
    opacity: 1 !important;
}

.uabb-imgicon-wrap .uabb-icon {
    display: block;
}

.uabb-imgicon-wrap .uabb-icon i {
    float: none;
}

.uabb-imgicon-wrap .uabb-image {
    line-height: 0;
    position: relative;
}

.uabb-imgicon-wrap .uabb-image-align-left {
    text-align: left;
}

.uabb-imgicon-wrap .uabb-image-align-center {
    text-align: center;
}

.uabb-imgicon-wrap .uabb-image-align-right {
    text-align: right;
}

.uabb-imgicon-wrap .uabb-image-content {
    display: inline-block;
    border-radius: 0;
    line-height: 0;
    position: relative;
    max-width: 100%;
}

.uabb-imgicon-wrap .uabb-image-content img {
    display: inline;
    height: auto !important;
    max-width: 100%;
    width: auto;
    border-radius: inherit;
    box-shadow: none;
    box-sizing: content-box;
}

.fl-builder-content .uabb-imgicon-wrap .uabb-image-crop-circle img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.fl-builder-content .uabb-imgicon-wrap .uabb-image-crop-square img {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.uabb-creative-button-wrap a,
.uabb-creative-button-wrap a:visited {
    background: #fafafa;
    border: 1px solid #ccc;
    color: #333;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    position: relative;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
}

.uabb-creative-button-wrap a:focus {
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
}

.uabb-creative-button-wrap a .uabb-creative-button-text,
.uabb-creative-button-wrap a .uabb-creative-button-icon,
.uabb-creative-button-wrap a:visited .uabb-creative-button-text,
.uabb-creative-button-wrap a:visited .uabb-creative-button-icon {
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
}

.uabb-creative-button-wrap a:hover {
    text-decoration: none;
}

.uabb-creative-button-wrap .uabb-creative-button-width-full .uabb-creative-button {
    display: block;
    text-align: center;
}

.uabb-creative-button-wrap .uabb-creative-button-width-custom .uabb-creative-button {
    display: inline-block;
    text-align: center;
    max-width: 100%;
}

.uabb-creative-button-wrap .uabb-creative-button-left {
    text-align: left;
}

.uabb-creative-button-wrap .uabb-creative-button-center {
    text-align: center;
}

.uabb-creative-button-wrap .uabb-creative-button-right {
    text-align: right;
}

.uabb-creative-button-wrap .uabb-creative-button i {
    font-size: 1.3em;
    height: auto;
    vertical-align: middle;
    width: auto;
}

.uabb-creative-button-wrap .uabb-creative-button .uabb-creative-button-icon-after {
    margin-left: 8px;
    margin-right: 0;
}

.uabb-creative-button-wrap .uabb-creative-button .uabb-creative-button-icon-before {
    margin-right: 8px;
    margin-left: 0;
}

.uabb-creative-button-wrap.uabb-creative-button-icon-no-text .uabb-creative-button i {
    margin: 0;
}

.uabb-creative-button-wrap .uabb-creative-button-has-icon .uabb-creative-button-text {
    vertical-align: middle;
}

.uabb-creative-button.uabb-creative-transparent-btn {
    background: transparent;
}

.uabb-creative-button.uabb-creative-transparent-btn:after {
    content: '';
    position: absolute;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
}

.uabb-transparent-fill-top-btn:after,
.uabb-transparent-fill-bottom-btn:after {
    width: 100%;
    height: 0;
    left: 0;
}

.uabb-transparent-fill-top-btn:after {
    top: 0;
}

.uabb-transparent-fill-bottom-btn:after {
    bottom: 0;
}

.uabb-transparent-fill-left-btn:after,
.uabb-transparent-fill-right-btn:after {
    width: 0;
    height: 100%;
    top: 0;
}

.uabb-transparent-fill-left-btn:after {
    left: 0;
}

.uabb-transparent-fill-right-btn:after {
    right: 0;
}

.uabb-transparent-fill-center-btn:after {
    width: 0;
    height: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.uabb-transparent-fill-diagonal-btn:after,
.uabb-transparent-fill-horizontal-btn:after {
    width: 100%;
    height: 0;
    top: 50%;
    left: 50%;
}

.uabb-transparent-fill-diagonal-btn {
    overflow: hidden;
}

.uabb-transparent-fill-diagonal-btn:after {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.uabb-transparent-fill-horizontal-btn:after {
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.uabb-creative-button-wrap .uabb-creative-threed-btn.uabb-threed_down-btn,
.uabb-creative-button-wrap .uabb-creative-threed-btn.uabb-threed_up-btn,
.uabb-creative-button-wrap .uabb-creative-threed-btn.uabb-threed_left-btn,
.uabb-creative-button-wrap .uabb-creative-threed-btn.uabb-threed_right-btn {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.perspective {
    -webkit-perspective: 800px;
    -moz-perspective: 800px;
    perspective: 800px;
    margin: 0;
}

.uabb-creative-button.uabb-creative-threed-btn:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    transition: all 200ms linear;
}

.uabb-creative-button.uabb-creative-threed-btn {
    outline: 1px solid transparent;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.uabb-creative-threed-btn.uabb-animate_top-btn:after {
    height: 40%;
    left: 0;
    top: -40%;
    width: 100%;
    -webkit-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    transform: rotateX(90deg);
}

.uabb-creative-threed-btn.uabb-animate_bottom-btn:after {
    width: 100%;
    height: 40%;
    left: 0;
    top: 100%;
    -webkit-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    -ms-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
}

.uabb-creative-threed-btn.uabb-animate_left-btn:after {
    width: 20%;
    height: 100%;
    left: -20%;
    top: 0;
    -webkit-transform-origin: 100% 0%;
    -moz-transform-origin: 100% 0%;
    -ms-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: rotateY(-60deg);
    -moz-transform: rotateY(-60deg);
    -ms-transform: rotateY(-60deg);
    transform: rotateY(-60deg);
}

.uabb-creative-threed-btn.uabb-animate_right-btn:after {
    width: 20%;
    height: 100%;
    left: 104%;
    top: 0;
    -webkit-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: rotateY(120deg);
    -moz-transform: rotateY(120deg);
    -ms-transform: rotateY(120deg);
    transform: rotateY(120deg);
}

.uabb-animate_top-btn:hover {
    -webkit-transform: rotateX(-15deg);
    -moz-transform: rotateX(-15deg);
    -ms-transform: rotateX(-15deg);
    -o-transform: rotateX(-15deg);
    transform: rotateX(-15deg);
}

.uabb-animate_bottom-btn:hover {
    -webkit-transform: rotateX(15deg);
    -moz-transform: rotateX(15deg);
    -ms-transform: rotateX(15deg);
    -o-transform: rotateX(15deg);
    transform: rotateX(15deg);
}

.uabb-animate_left-btn:hover {
    -webkit-transform: rotateY(6deg);
    -moz-transform: rotateY(6deg);
    -ms-transform: rotateY(6deg);
    -o-transform: rotateY(6deg);
    transform: rotateY(6deg);
}

.uabb-animate_right-btn:hover {
    -webkit-transform: rotateY(-6deg);
    -moz-transform: rotateY(-6deg);
    -ms-transform: rotateY(-6deg);
    -o-transform: rotateY(-6deg);
    transform: rotateY(-6deg);
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn,
.uabb-creative-flat-btn.uabb-animate_to_left-btn,
.uabb-creative-flat-btn.uabb-animate_from_top-btn,
.uabb-creative-flat-btn.uabb-animate_from_bottom-btn {
    overflow: hidden;
    position: relative;
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn i,
.uabb-creative-flat-btn.uabb-animate_to_left-btn i,
.uabb-creative-flat-btn.uabb-animate_from_top-btn i,
.uabb-creative-flat-btn.uabb-animate_from_bottom-btn i {
    bottom: 0;
    height: 100%;
    margin: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    width: 100%;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    transition: all 200ms linear;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn .uabb-button-text,
.uabb-creative-flat-btn.uabb-animate_to_left-btn .uabb-button-text,
.uabb-creative-flat-btn.uabb-animate_from_top-btn .uabb-button-text,
.uabb-creative-flat-btn.uabb-animate_from_bottom-btn .uabb-button-text {
    display: inline-block;
    width: 100%;
    height: 100%;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn i:before,
.uabb-creative-flat-btn.uabb-animate_to_left-btn i:before,
.uabb-creative-flat-btn.uabb-animate_from_top-btn i:before,
.uabb-creative-flat-btn.uabb-animate_from_bottom-btn i:before {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn:hover i,
.uabb-creative-flat-btn.uabb-animate_to_left-btn:hover i {
    left: 0;
}

.uabb-creative-flat-btn.uabb-animate_from_top-btn:hover i,
.uabb-creative-flat-btn.uabb-animate_from_bottom-btn:hover i {
    top: 0;
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn i {
    top: 0;
    left: -100%;
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn:hover .uabb-button-text {
    -webkit-transform: translateX(200%);
    -moz-transform: translateX(200%);
    -ms-transform: translateX(200%);
    -o-transform: translateX(200%);
    transform: translateX(200%);
}

.uabb-creative-flat-btn.uabb-animate_to_left-btn i {
    top: 0;
    left: 100%;
}

.uabb-creative-flat-btn.uabb-animate_to_left-btn:hover .uabb-button-text {
    -webkit-transform: translateX(-200%);
    -moz-transform: translateX(-200%);
    -ms-transform: translateX(-200%);
    -o-transform: translateX(-200%);
    transform: translateX(-200%);
}

.uabb-creative-flat-btn.uabb-animate_from_top-btn i {
    top: -100%;
    left: 0;
}

.uabb-creative-flat-btn.uabb-animate_from_top-btn:hover .uabb-button-text {
    -webkit-transform: translateY(400px);
    -moz-transform: translateY(400px);
    -ms-transform: translateY(400px);
    -o-transform: translateY(400px);
    transform: translateY(400px);
}

.uabb-creative-flat-btn.uabb-animate_from_bottom-btn i {
    top: 100%;
    left: 0;
}

.uabb-creative-flat-btn.uabb-animate_from_bottom-btn:hover .uabb-button-text {
    -webkit-transform: translateY(-400px);
    -moz-transform: translateY(-400px);
    -ms-transform: translateY(-400px);
    -o-transform: translateY(-400px);
    transform: translateY(-400px);
}

.uabb-tab-acc-content .wp-video,
.uabb-tab-acc-content video.wp-video-shortcode,
.uabb-tab-acc-content .mejs-container:not(.mejs-audio),
.uabb-tab-acc-content .mejs-overlay.load,
.uabb-adv-accordion-content .wp-video,
.uabb-adv-accordion-content video.wp-video-shortcode,
.uabb-adv-accordion-content .mejs-container:not(.mejs-audio),
.uabb-adv-accordion-content .mejs-overlay.load {
    width: 100% !important;
    height: 100% !important;
}

.uabb-tab-acc-content .mejs-container:not(.mejs-audio),
.uabb-adv-accordion-content .mejs-container:not(.mejs-audio) {
    padding-top: 56.25%;
}

.uabb-tab-acc-content .wp-video,
.uabb-tab-acc-content video.wp-video-shortcode,
.uabb-adv-accordion-content .wp-video,
.uabb-adv-accordion-content video.wp-video-shortcode {
    max-width: 100% !important;
}

.uabb-tab-acc-content video.wp-video-shortcode,
.uabb-adv-accordion-content video.wp-video-shortcode {
    position: relative;
}

.uabb-tab-acc-content .mejs-container:not(.mejs-audio) .mejs-mediaelement,
.uabb-adv-accordion-content .mejs-container:not(.mejs-audio) .mejs-mediaelement {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.uabb-tab-acc-content .mejs-overlay-play,
.uabb-adv-accordion-content .mejs-overlay-play {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto !important;
    height: auto !important;
}

.uabb-creative-button-wrap a,
.uabb-creative-button-wrap a:visited {}

.uabb-dual-button .uabb-btn,
.uabb-dual-button .uabb-btn:visited {}

.uabb-js-breakpoint {
    content: "default";
    display: none;
}

@media screen and (max-width: 1000px) {
    .uabb-js-breakpoint {
        content: "1000";
    }
}

@media screen and (max-width: 800px) {
    .uabb-js-breakpoint {
        content: "800";
    }
}

.fl-builder-row-settings #fl-field-separator_position {
    display: none !important;
}

.fl-builder-row-settings #fl-field-separator_position {
    display: none !important;
}

.fl-node-5bcead71a5a1a > .fl-col-content {}

.fl-node-5bcead71a5a0a > .fl-col-content {}

.fl-node-5bcead71a59d2 > .fl-col-content {}

.fl-node-5bfd4c4636593 > .fl-col-content {}

.fl-node-5bfd4c46365d3 > .fl-col-content {}

.fl-node-5bfd4c4636610 > .fl-col-content {}

.fl-node-5bcead71a58f4 > .fl-col-content {}

.fl-node-5bcead71a5a1a > .fl-col-content {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -webkit-transition: -webkit-box-shadow 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    -moz-transition: -moz-box-shadow 500ms ease-in-out, -moz-transform 500ms ease-in-out;
    transition: box-shadow 500ms ease-in-out, transform 500ms ease-in-out;
    will-change: box-shadow;
}

.fl-node-5bcead71a5a0a > .fl-col-content {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -webkit-transition: -webkit-box-shadow 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    -moz-transition: -moz-box-shadow 500ms ease-in-out, -moz-transform 500ms ease-in-out;
    transition: box-shadow 500ms ease-in-out, transform 500ms ease-in-out;
    will-change: box-shadow;
}

.fl-node-5bcead71a59d2 > .fl-col-content {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -webkit-transition: -webkit-box-shadow 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    -moz-transition: -moz-box-shadow 500ms ease-in-out, -moz-transform 500ms ease-in-out;
    transition: box-shadow 500ms ease-in-out, transform 500ms ease-in-out;
    will-change: box-shadow;
}

.fl-node-5bfd4c4636593 > .fl-col-content {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -webkit-transition: -webkit-box-shadow 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    -moz-transition: -moz-box-shadow 500ms ease-in-out, -moz-transform 500ms ease-in-out;
    transition: box-shadow 500ms ease-in-out, transform 500ms ease-in-out;
    will-change: box-shadow;
}

.fl-node-5bfd4c46365d3 > .fl-col-content {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -webkit-transition: -webkit-box-shadow 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    -moz-transition: -moz-box-shadow 500ms ease-in-out, -moz-transform 500ms ease-in-out;
    transition: box-shadow 500ms ease-in-out, transform 500ms ease-in-out;
    will-change: box-shadow;
}

.fl-node-5bfd4c4636610 > .fl-col-content {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -webkit-transition: -webkit-box-shadow 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    -moz-transition: -moz-box-shadow 500ms ease-in-out, -moz-transform 500ms ease-in-out;
    transition: box-shadow 500ms ease-in-out, transform 500ms ease-in-out;
    will-change: box-shadow;
}

.fl-node-5bcead71a58f4 > .fl-col-content {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -webkit-transition: -webkit-box-shadow 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    -moz-transition: -moz-box-shadow 500ms ease-in-out, -moz-transform 500ms ease-in-out;
    transition: box-shadow 500ms ease-in-out, transform 500ms ease-in-out;
    will-change: box-shadow;
}

.fl-node-5beaa363cf8c0 .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5beaa363cf8c0 .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5beaa363cf8c0 .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5beaa363cf8c0 > .fl-row-content-wrap {
    background-color: #ffffff;
}

.fl-node-5beaa363cf8c0 .fl-row-content {
    max-width: 1250px;
}

.fl-node-5beaa363cf8c0 > .fl-row-content-wrap {
    padding-top: 0px;
    padding-bottom: 0px;
}

.fl-node-5beaa36ec9bfc .fl-bg-video-audio {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    width: 20px;
}

.fl-node-5beaa36ec9bfc .fl-bg-video-audio .fl-audio-control {
    font-size: 20px;
}

.fl-node-5beaa36ec9bfc .fl-bg-video-audio .fa-times {
    font-size: 10px;
    vertical-align: middle;
    position: absolute;
    top: 5px;
    left: 11px;
    bottom: 0;
}

.fl-node-5beaa36ec9bfc > .fl-row-content-wrap {
    background-color: #ffffff;
}

.fl-node-5beaa36ec9bfc > .fl-row-content-wrap {
    padding-top: 0px;
    padding-bottom: 0px;
}

.fl-node-5beaa363cf81a {
    width: 100%;
}

@media(max-width: 1000px) {
    .fl-builder-content .fl-node-5beaa363cf81a {
        width: 100% !important;
        max-width: none;
        -webkit-box-flex: 0 1 auto;
        -moz-box-flex: 0 1 auto;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
    }
}

@media(max-width: 800px) {
    .fl-builder-content .fl-node-5beaa363cf81a {
        width: 100% !important;
        max-width: none;
        clear: none;
        float: left;
    }
}

.fl-node-5beaa36ec9b8d {
    width: 100%;
}

.bb-gf-input.input-small {
    width: 46px !important;
}

.pp-gf-content {
    position: relative;
}

.pp-gf-content:before {
    content: "";
    display: block;
    position: absolute;
    ;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.pp-gf-content .form-title,
.pp-gf-content .form-description {
    position: relative;
}

.pp-gf-content .gform_wrapper form {
    position: relative;
}

.pp-gf-content .gform_wrapper .gform_body {
    width: 100% !important;
}

.pp-gf-content .gform_wrapper ul.gform_fields {
    padding: 0;
}

.pp-gf-content .gform_wrapper ul.gform_fields li.gfield {
    padding-right: 0;
}

.pp-gf-content .gform_wrapper ul.gform_fields li.gfield.gf_left_half {
    padding-right: 16px;
}

.pp-gf-content .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
    max-width: 100% !important;
}

.pp-gf-content .gform_wrapper .gfield input[type="checkbox"]:focus,
.pp-gf-content .gform_wrapper .gfield input[type="radio"]:focus {
    width: auto !important;
    text-align: left !important;
}

.gform_wrapper .top_label li.gfield.gf_left_half input.medium,
.gform_wrapper .top_label li.gfield.gf_right_half input.medium,
.gform_wrapper .top_label li.gfield.gf_left_half select.medium,
.gform_wrapper .top_label li.gfield.gf_right_half select.medium {
    width: 100% !important;
}

.gform_wrapper .gfield input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
.gform_wrapper .gfield input:focus,
.gform_wrapper .gfield select,
.gform_wrapper .gfield textarea {
    height: auto;
}

.gform_wrapper .ginput_complex input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]) {
    width: 100% !important;
}

.gform_wrapper.gf_browser_chrome .gfield_checkbox li input,
.gform_wrapper.gf_browser_chrome .gfield_checkbox li input[type=checkbox],
.gform_wrapper.gf_browser_chrome .gfield_radio li input[type=radio] {
    margin-top: 0 !important;
}

div.gform_wrapper .field_sublabel_below .ginput_complex.ginput_container label {
    margin-bottom: 0;
}

.pp-gf-content .gform_wrapper ul li.gfield {
    margin-top: 0;
}

.pp-gf-content .gform_wrapper ul.gfield_radio li input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) {
    width: auto !important;
    display: inline-block;
}

.pp-gf-content .gform_wrapper .ginput_complex span.ginput_full {
    display: block;
}

.pp-gf-content .gform_wrapper .gfield .gfield_description {
    padding-top: 5px;
}

.pp-gf-content .gform_wrapper h2.gsection_title {
    margin-bottom: 0;
}

@media only screen and (max-width: 640px) {
    .pp-gf-content .gform_wrapper ul.gform_fields li.gfield.gf_left_half {
        padding-right: 0;
    }
}

@media (max-width: 800px) {}

.fl-node-5beaa363cf889 .pp-gf-content .gform_wrapper {
    max-width: 100%;
}

.fl-node-5beaa363cf889 .pp-gf-content {
    background-color: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    border-width: 0px;
    border-color: #94abb9;
    border-style: solid;
    border-radius: 2px;
    padding-top: 15px;
    padding-right: 30px;
    padding-bottom: 15px;
    padding-left: 30px;
}

.fl-node-5beaa363cf889 .pp-gf-content .gform_wrapper ul li.gfield {
    list-style-type: none !important;
    margin-bottom: 10px;
}

.fl-node-5beaa363cf889 .gform_wrapper .gform_title,
.fl-node-5beaa363cf889 .form-title {
    color: #ffffff;
    display: none;
    text-align: left;
}

.fl-node-5beaa363cf889 .form-title {
    display: none;
}

.fl-node-5beaa363cf889 .gform_wrapper .gform_title {}

.fl-node-5beaa363cf889 .gform_wrapper span.gform_description,
.fl-node-5beaa363cf889 .form-description {
    display: none;
    text-align: left;
}

.fl-node-5beaa363cf889 .form-description {
    display: none;
}

.fl-node-5beaa363cf889 .gform_wrapper span.gform_description {}

.fl-node-5beaa363cf889 .gform_wrapper .gfield .gfield_label {
    display: none;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield .ginput_complex.ginput_container label {
    display: none;
}

.fl-node-5beaa363cf889 .gform_wrapper .ginput_container label,
.fl-node-5beaa363cf889 .gform_wrapper table.gfield_list thead th,
.fl-node-5beaa363cf889 .gform_wrapper span.ginput_product_price_label,
.fl-node-5beaa363cf889 .gform_wrapper span.ginput_quantity_label,
.fl-node-5beaa363cf889 .gform_wrapper .gfield_html {}

.fl-node-5beaa363cf889 .gform_wrapper ul.gfield_radio li label,
.fl-node-5beaa363cf889 .gform_wrapper ul.gfield_checkbox li label {}

.fl-node-5beaa363cf889 .gform_wrapper span.ginput_product_price {
    color: #900900 !important;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield .gfield_description {
    color: #ffffff;
}

.fl-node-5beaa363cf889 .pp-gf-content .gform_wrapper .gsection {
    border-bottom-width: 1px;
    border-bottom-color: #cccccc;
    margin-bottom: 20px;
}

.fl-node-5beaa363cf889 .gform_wrapper h2.gsection_title {
    color: #333333;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
.fl-node-5beaa363cf889 .gform_wrapper .gfield select,
.fl-node-5beaa363cf889 .gform_wrapper .gfield textarea {
    color: #ffffff;
    background-color: rgba(40, 172, 226, 1);
    border-width: 0;
    /* border-color: ; */
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    border-width: 0px;
    border-style: solid;
    padding: 10px;
    text-align: center;
    font-size: 20px;
    outline: none;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
.fl-node-5beaa363cf889 .gform_wrapper .gfield select {}

.fl-node-5beaa363cf889 .gform_wrapper .ginput_complex input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=image]):not([type=file]),
.fl-node-5beaa363cf889 .gform_wrapper .ginput_complex select {
    margin-bottom: 3px;
}

.fl-node-5beaa363cf889 .gform_wrapper .ginput_complex span {
    margin-bottom: 4px;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield input::-webkit-input-placeholder {
    color: #ffffff;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield input:-moz-placeholder {
    color: #ffffff;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield input::-moz-placeholder {
    color: #ffffff;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield input:-ms-input-placeholder {
    color: #ffffff;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield textarea::-webkit-input-placeholder {
    color: #ffffff;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield textarea:-moz-placeholder {
    color: #ffffff;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield textarea::-moz-placeholder {
    color: #ffffff;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield textarea:-ms-input-placeholder {
    color: #ffffff;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']):focus,
.fl-node-5beaa363cf889 .gform_wrapper .gfield select:focus,
.fl-node-5beaa363cf889 .gform_wrapper .gfield textarea:focus {
    border-color: #2d3691;
}

.fl-node-5beaa363cf889 .gform_wrapper .top_label input.medium,
.fl-node-5beaa363cf889 .gform_wrapper .top_label select.medium {
    width: 49% !important;
}

.fl-node-5beaa363cf889 .gform_wrapper textarea.medium {}

.fl-node-5beaa363cf889 .gform_wrapper .ginput_complex .ginput_full input[type="text"],
.fl-node-5beaa363cf889 .gform_wrapper .ginput_complexinput[type="text"] {
    width: 97.5% !important;
}

.fl-node-5beaa363cf889 .gform_wrapper .ginput_complex .ginput_right {
    margin-left: 0 !important;
}

.fl-node-5beaa363cf889 .gform_wrapper .ginput_complex .ginput_right input,
.fl-node-5beaa363cf889 .gform_wrapper .ginput_complex .ginput_right select {}

.fl-node-5beaa363cf889 .gform_wrapper .gform_footer {
    text-align: center;
}

.fl-node-5beaa363cf889 .gform_wrapper .gform_footer .gform_button,
.fl-node-5beaa363cf889 .gform_wrapper .gform_page_footer .button {
    width: auto;
    color: #104fa0;
    background-color: transparent;
    border: 0px solid transparent;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 20px;
    white-space: normal;
}

.fl-node-5beaa363cf889 .gform_wrapper .gform_page_footer .button {}

.fl-node-5beaa363cf889 .gform_wrapper.gf_browser_ie .gform_footer .gform_button,
.fl-node-5beaa363cf889 .gform_wrapper.gf_browser_ie .gform_page_footer .button {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 30px;
    padding-right: 30px;
}

.fl-node-5beaa363cf889 .gform_wrapper .gform_footer .gform_button:hover,
.fl-node-5beaa363cf889 .gform_wrapper .gform_page_footer .button:hover {
    color: #28ace2;
    background: transparent;
    border-width: 0px;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield input[type=file] {
    background-color: transparent;
    border-width: px;
    border-style: none;
}

.fl-node-5beaa363cf889 .gform_wrapper .validation_error,
.fl-node-5beaa363cf889 .gform_wrapper li.gfield.gfield_error,
.fl-node-5beaa363cf889 .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    color: #790000 !important;
    border-color: #790000 !important;
}

.fl-node-5beaa363cf889 .gform_wrapper .validation_error {
    font-size: 16px !important;
    display: block !important;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield.gfield_error {
    background-color: #ffdfe0;
    Width: 100%;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield.gfield_error .gfield_label {
    color: #790000;
    margin-left: 0;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield_error .validation_message {
    display: block;
    color: #790000;
}

.fl-node-5beaa363cf889 .gform_wrapper .gfield_error input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
.fl-node-5beaa363cf889 .gform_wrapper .gfield_error .ginput_container select,
.fl-node-5beaa363cf889 .gform_wrapper .gfield_error .ginput_container textarea {
    border-color: #790000;
    border-width: 1px !important;
}

.fl-node-5beaa363cf889 > .fl-module-content {
    margin-top: 0px;
}

.fl-node-5beaa36ec9bc5 .pp-photo-container .pp-photo-content {
    background: transparent;
    border-color: #cccccc;
    border-style: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -o-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
    border-width: 1px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.fl-node-5beaa36ec9bc5 .pp-photo-container .pp-photo-content .pp-photo-content-inner {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -o-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
}

.fl-node-5beaa36ec9bc5 .pp-photo-container .pp-photo-content .pp-photo-content-inner img {
    width: 250px;
}

.fl-node-5beaa36ec9bc5 .pp-photo-container .pp-photo-content .pp-photo-content-inner a {
    display: block;
    text-decoration: none !important;
}

.fl-node-5beaa36ec9bc5 .pp-photo-container .pp-photo-content .pp-photo-content-inner img {
    border-color: #000000;
    border-style: none;
    border-width: 1px;
}

.fl-node-5beaa36ec9bc5 .pp-photo-caption {
    background: rgba(221, 221, 221, 0.5);
    color: #000000;
    font-size: 18px;
    line-height: 1.6;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.fl-node-5beaa36ec9bc5 .pp-overlay-wrap .pp-overlay-bg {
    background: rgba(221, 221, 221, 0.5);
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -o-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
}

@media only screen and (max-width: 768px) {
    /* .fl-node-5beaa36ec9bc5 .pp-photo-caption {
        font-size: px;
        line-height: ;
    } */
}

@media only screen and (max-width: 800px) {
    .pp-photo-container .pp-photo-align-responsive-left {
        text-align: left !important;
    }

    .pp-photo-container .pp-photo-align-responsive-center {
        text-align: center !important;
    }

    .pp-photo-container .pp-photo-align-responsive-right {
        text-align: right !important;
    }
}

@media only screen and (max-width: 480px) {
    /* .fl-node-5beaa36ec9bc5 .pp-photo-caption {
        font-size: px;
    } */
}

.fl-node-5beaa36ec9bc5 > .fl-module-content {
    margin-top: 0px;
}

.fl-node-5beaa363cf8c0 .uabb-top-row-separator.uabb-has-svg svg {
    width: calc(100% + 1.5px);
}

.fl-node-5beaa363cf8c0 .uabb-bottom-row-separator.uabb-has-svg svg {
    width: calc(100% + 1.5px);
}

.fl-node-5beaa363cf8c0 #uabb-top-slit2,
.fl-node-5beaa363cf8c0 #uabb-top-slit3 {
    fill: #afafaf;
}

.fl-node-5beaa363cf8c0 #uabb-bottom-slit2,
.fl-node-5beaa363cf8c0 #uabb-bottom-slit3 {
    fill: #afafaf;
}

.fl-node-5beaa363cf8c0 #uabb-top-pine-tree-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa363cf8c0 #uabb-bottom-pine-tree-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa363cf8c0 #uabb-top-pine-tree-bend-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa363cf8c0 #uabb-bottom-pine-tree-bend-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa363cf8c0 #uabb-top-slime-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa363cf8c0 #uabb-bottom-slime-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa363cf8c0 #uabb-top-wave-slide-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa363cf8c0 #uabb-bottom-wave-slide-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa363cf8c0 .uabb-top-row-separator {
    z-index: 9;
}

.fl-node-5beaa363cf8c0 .uabb-bottom-row-separator {
    z-index: 9;
}

@media(max-width: 1000px) {
    .fl-node-5beaa363cf8c0 .uabb-top-row-separator.uabb-has-svg svg {
        width: 100%;
    }

    .fl-node-5beaa363cf8c0 .uabb-bottom-row-separator.uabb-has-svg svg {
        width: 100%;
    }
}

@media(max-width: 800px) {
    .fl-node-5beaa363cf8c0 .uabb-top-row-separator.uabb-has-svg svg {
        width: 100%;
    }

    .fl-node-5beaa363cf8c0 .uabb-bottom-row-separator.uabb-has-svg svg {
        width: 100%;
    }
}

.fl-node-5beaa36ec9bfc .uabb-top-row-separator.uabb-has-svg svg {
    width: calc(100% + 1.5px);
}

.fl-node-5beaa36ec9bfc .uabb-bottom-row-separator.uabb-has-svg svg {
    width: calc(100% + 1.5px);
}

.fl-node-5beaa36ec9bfc #uabb-top-slit2,
.fl-node-5beaa36ec9bfc #uabb-top-slit3 {
    fill: #afafaf;
}

.fl-node-5beaa36ec9bfc #uabb-bottom-slit2,
.fl-node-5beaa36ec9bfc #uabb-bottom-slit3 {
    fill: #afafaf;
}

.fl-node-5beaa36ec9bfc #uabb-top-pine-tree-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa36ec9bfc #uabb-bottom-pine-tree-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa36ec9bfc #uabb-top-pine-tree-bend-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa36ec9bfc #uabb-bottom-pine-tree-bend-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa36ec9bfc #uabb-top-slime-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa36ec9bfc #uabb-bottom-slime-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa36ec9bfc #uabb-top-wave-slide-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa36ec9bfc #uabb-bottom-wave-slide-separator2 {
    fill: #ffffff;
    stroke: #ffffff;
}

.fl-node-5beaa36ec9bfc .uabb-top-row-separator {
    z-index: 9;
}

.fl-node-5beaa36ec9bfc .uabb-bottom-row-separator {
    z-index: 9;
}

@media(max-width: 1000px) {
    .fl-node-5beaa36ec9bfc .uabb-top-row-separator.uabb-has-svg svg {
        width: 100%;
    }

    .fl-node-5beaa36ec9bfc .uabb-bottom-row-separator.uabb-has-svg svg {
        width: 100%;
    }
}

@media(max-width: 800px) {
    .fl-node-5beaa36ec9bfc .uabb-top-row-separator.uabb-has-svg svg {
        width: 100%;
    }

    .fl-node-5beaa36ec9bfc .uabb-bottom-row-separator.uabb-has-svg svg {
        width: 100%;
    }
}

.fl-col-group-equal-height.fl-col-group-align-bottom .fl-col-content {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
}

.uabb-module-content h1,
.uabb-module-content h2,
.uabb-module-content h3,
.uabb-module-content h4,
.uabb-module-content h5,
.uabb-module-content h6 {
    margin: 0;
    clear: both;
}

.fl-module-content a,
.fl-module-content a:hover,
.fl-module-content a:focus {
    text-decoration: none;
}

.uabb-row-separator {
    position: absolute;
    width: 100%;
    left: 0;
}

.uabb-top-row-separator {
    top: 0;
    bottom: auto
}

.uabb-bottom-row-separator {
    top: auto;
    bottom: 0;
}

.fl-builder-content-editing .fl-visible-medium.uabb-row,
.fl-builder-content-editing .fl-visible-medium-mobile.uabb-row,
.fl-builder-content-editing .fl-visible-mobile.uabb-row {
    display: none !important;
}

@media (max-width: 992px) {

    .fl-builder-content-editing .fl-visible-desktop.uabb-row,
    .fl-builder-content-editing .fl-visible-mobile.uabb-row {
        display: none !important;
    }

    .fl-builder-content-editing .fl-visible-desktop-medium.uabb-row,
    .fl-builder-content-editing .fl-visible-medium.uabb-row,
    .fl-builder-content-editing .fl-visible-medium-mobile.uabb-row {
        display: block !important;
    }
}

@media (max-width: 768px) {

    .fl-builder-content-editing .fl-visible-desktop.uabb-row,
    .fl-builder-content-editing .fl-visible-desktop-medium.uabb-row,
    .fl-builder-content-editing .fl-visible-medium.uabb-row {
        display: none !important;
    }

    .fl-builder-content-editing .fl-visible-medium-mobile.uabb-row,
    .fl-builder-content-editing .fl-visible-mobile.uabb-row {
        display: block !important;
    }
}

.fl-responsive-preview-content .fl-builder-content-editing {
    overflow-x: hidden;
    overflow-y: visible;
}

.uabb-row-separator svg {
    width: 100%;
}

.uabb-top-row-separator.uabb-has-svg svg {
    position: absolute;
    padding: 0;
    margin: 0;
    left: 50%;
    top: -1px;
    bottom: auto;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.uabb-bottom-row-separator.uabb-has-svg svg {
    position: absolute;
    padding: 0;
    margin: 0;
    left: 50%;
    bottom: -1px;
    top: auto;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.uabb-bottom-row-separator.uabb-has-svg .uasvg-wave-separator {
    bottom: 0;
}

.uabb-top-row-separator.uabb-has-svg .uasvg-wave-separator {
    top: 0;
}

.uabb-bottom-row-separator.uabb-svg-triangle svg,
.uabb-bottom-row-separator.uabb-xlarge-triangle svg,
.uabb-top-row-separator.uabb-xlarge-triangle-left svg,
.uabb-bottom-row-separator.uabb-svg-circle svg,
.uabb-top-row-separator.uabb-slime-separator svg,
.uabb-top-row-separator.uabb-grass-separator svg,
.uabb-top-row-separator.uabb-grass-bend-separator svg,
.uabb-bottom-row-separator.uabb-mul-triangles-separator svg,
.uabb-top-row-separator.uabb-wave-slide-separator svg,
.uabb-top-row-separator.uabb-pine-tree-separator svg,
.uabb-top-row-separator.uabb-pine-tree-bend-separator svg,
.uabb-bottom-row-separator.uabb-stamp-separator svg,
.uabb-bottom-row-separator.uabb-xlarge-circle svg,
.uabb-top-row-separator.uabb-wave-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleY(-1);
    -moz-transform: translateX(-50%) scaleY(-1);
    -ms-transform: translateX(-50%) scaleY(-1);
    -o-transform: translateX(-50%) scaleY(-1);
    transform: translateX(-50%) scaleY(-1);
}

.uabb-bottom-row-separator.uabb-big-triangle svg {
    left: 50%;
    -webkit-transform: scale(1) scaleY(-1) translateX(-50%);
    -moz-transform: scale(1) scaleY(-1) translateX(-50%);
    -ms-transform: scale(1) scaleY(-1) translateX(-50%);
    -o-transform: scale(1) scaleY(-1) translateX(-50%);
    transform: scale(1) scaleY(-1) translateX(-50%);
}

.uabb-top-row-separator.uabb-big-triangle svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scale(1);
    -moz-transform: translateX(-50%) scale(1);
    -ms-transform: translateX(-50%) scale(1);
    -o-transform: translateX(-50%) scale(1);
    transform: translateX(-50%) scale(1);
}

.uabb-top-row-separator.uabb-xlarge-triangle-right svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scale(-1);
    -moz-transform: translateX(-50%) scale(-1);
    -ms-transform: translateX(-50%) scale(-1);
    -o-transform: translateX(-50%) scale(-1);
    transform: translateX(-50%) scale(-1);
}

.uabb-bottom-row-separator.uabb-xlarge-triangle-right svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleX(-1);
    -moz-transform: translateX(-50%) scaleX(-1);
    -ms-transform: translateX(-50%) scaleX(-1);
    -o-transform: translateX(-50%) scaleX(-1);
    transform: translateX(-50%) scaleX(-1);
}

.uabb-top-row-separator.uabb-curve-up-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleY(-1);
    -moz-transform: translateX(-50%) scaleY(-1);
    -ms-transform: translateX(-50%) scaleY(-1);
    -o-transform: translateX(-50%) scaleY(-1);
    transform: translateX(-50%) scaleY(-1);
}

.uabb-top-row-separator.uabb-curve-down-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scale(-1);
    -moz-transform: translateX(-50%) scale(-1);
    -ms-transform: translateX(-50%) scale(-1);
    -o-transform: translateX(-50%) scale(-1);
    transform: translateX(-50%) scale(-1);
}

.uabb-bottom-row-separator.uabb-curve-down-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleX(-1);
    -moz-transform: translateX(-50%) scaleX(-1);
    -ms-transform: translateX(-50%) scaleX(-1);
    -o-transform: translateX(-50%) scaleX(-1);
    transform: translateX(-50%) scaleX(-1);
}

.uabb-top-row-separator.uabb-tilt-left-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scale(-1);
    -moz-transform: translateX(-50%) scale(-1);
    -ms-transform: translateX(-50%) scale(-1);
    -o-transform: translateX(-50%) scale(-1);
    transform: translateX(-50%) scale(-1);
}

.uabb-top-row-separator.uabb-tilt-right-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleY(-1);
    -moz-transform: translateX(-50%) scaleY(-1);
    -ms-transform: translateX(-50%) scaleY(-1);
    -o-transform: translateX(-50%) scaleY(-1);
    transform: translateX(-50%) scaleY(-1);
}

.uabb-bottom-row-separator.uabb-tilt-left-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.uabb-bottom-row-separator.uabb-tilt-right-separator svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleX(-1);
    -moz-transform: translateX(-50%) scaleX(-1);
    -ms-transform: translateX(-50%) scaleX(-1);
    -o-transform: translateX(-50%) scaleX(-1);
    transform: translateX(-50%) scaleX(-1);
}

.uabb-top-row-separator.uabb-tilt-left-separator,
.uabb-top-row-separator.uabb-tilt-right-separator {
    top: 0;
}

.uabb-bottom-row-separator.uabb-tilt-left-separator,
.uabb-bottom-row-separator.uabb-tilt-right-separator {
    bottom: 0;
}

.uabb-top-row-separator.uabb-arrow-outward-separator svg,
.uabb-top-row-separator.uabb-arrow-inward-separator svg,
.uabb-top-row-separator.uabb-cloud-separator svg,
.uabb-top-row-separator.uabb-multi-triangle svg {
    left: 50%;
    -webkit-transform: translateX(-50%) scaleY(-1);
    -moz-transform: translateX(-50%) scaleY(-1);
    -ms-transform: translateX(-50%) scaleY(-1);
    -o-transform: translateX(-50%) scaleY(-1);
    transform: translateX(-50%) scaleY(-1);
}

.uabb-bottom-row-separator.uabb-multi-triangle svg {
    bottom: -2px;
}

.uabb-row-separator.uabb-round-split:after,
.uabb-row-separator.uabb-round-split:before {
    left: 0;
    width: 50%;
    background: inherit inherit/inherit inherit inherit inherit;
    content: '';
    position: absolute
}

.uabb-button-wrap a,
.uabb-button-wrap a:visited {
    display: inline-block;
    font-size: 16px;
    line-height: 18px;
    text-decoration: none;
    text-shadow: none;
}

.uabb-button-wrap a *,
.uabb-button-wrap a:visited * {}

.fl-builder-content .uabb-button:hover {
    text-decoration: none;
}

.fl-builder-content .uabb-button-width-full .uabb-button {
    display: block;
    text-align: center;
}

.uabb-button-width-custom .uabb-button {
    display: inline-block;
    text-align: center;
    max-width: 100%;
}

.fl-builder-content .uabb-button-left {
    text-align: left;
}

.fl-builder-content .uabb-button-center {
    text-align: center;
}

.fl-builder-content .uabb-button-right {
    text-align: right;
}

.fl-builder-content .uabb-button i,
.fl-builder-content .uabb-button i:before {
    font-size: 1em;
    height: 1em;
    line-height: 1em;
    width: 1em;
}

.uabb-button .uabb-button-icon-after {
    margin-left: 8px;
    margin-right: 0;
}

.uabb-button .uabb-button-icon-before {
    margin-left: 0;
    margin-right: 8px;
}

.uabb-button .uabb-button-icon-no-text {
    margin: 0;
}

.uabb-button-has-icon .uabb-button-text {
    vertical-align: middle;
}

.uabb-icon-wrap {
    display: inline-block;
}

.uabb-icon a {
    text-decoration: none;
}

.uabb-icon i {
    display: block;
}

.uabb-icon i:before {
    border: none !important;
    background: none !important;
}

.uabb-icon-text {
    display: table-cell;
    text-align: left;
    padding-left: 15px;
    vertical-align: middle;
}

.uabb-icon-text *:last-child {
    margin: 0 !important;
    padding: 0 !important;
}

.uabb-icon-text a {
    text-decoration: none;
}

.uabb-photo {
    line-height: 0;
    position: relative;
    z-index: 2;
}

.uabb-photo-align-left {
    text-align: left;
}

.uabb-photo-align-center {
    text-align: center;
}

.uabb-photo-align-right {
    text-align: right;
}

.uabb-photo-content {
    border-radius: 0;
    display: inline-block;
    line-height: 0;
    position: relative;
    max-width: 100%;
    overflow: hidden;
}

.uabb-photo-content img {
    border-radius: inherit;
    display: inline;
    height: auto;
    max-width: 100%;
    width: auto;
}

.fl-builder-content .uabb-photo-crop-circle img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.fl-builder-content .uabb-photo-crop-square img {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.uabb-photo-caption {
    font-size: 13px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.uabb-photo-caption-below {
    padding-bottom: 20px;
    padding-top: 10px;
}

.uabb-photo-caption-hover {
    background: rgba(0, 0, 0, 0.7);
    bottom: 0;
    color: #fff;
    left: 0;
    opacity: 0;
    visibility: hidden;
    filter: alpha(opacity=0);
    padding: 10px 15px;
    position: absolute;
    right: 0;
    -webkit-transition: visibility 200ms linear;
    -moz-transition: visibility 200ms linear;
    transition: visibility 200ms linear;
}

.uabb-photo-content:hover .uabb-photo-caption-hover {
    opacity: 100;
    visibility: visible;
}

.uabb-active-btn {
    background: #1e8cbe;
    border-color: #0074a2;
    -webkit-box-shadow: inset 0 1px 0 rgba(120, 200, 230, .6);
    box-shadow: inset 0 1px 0 rgba(120, 200, 230, .6);
    color: white;
}

.fl-builder-bar .fl-builder-add-content-button {
    display: block !important;
    opacity: 1 !important;
}

.uabb-imgicon-wrap .uabb-icon {
    display: block;
}

.uabb-imgicon-wrap .uabb-icon i {
    float: none;
}

.uabb-imgicon-wrap .uabb-image {
    line-height: 0;
    position: relative;
}

.uabb-imgicon-wrap .uabb-image-align-left {
    text-align: left;
}

.uabb-imgicon-wrap .uabb-image-align-center {
    text-align: center;
}

.uabb-imgicon-wrap .uabb-image-align-right {
    text-align: right;
}

.uabb-imgicon-wrap .uabb-image-content {
    display: inline-block;
    border-radius: 0;
    line-height: 0;
    position: relative;
    max-width: 100%;
}

.uabb-imgicon-wrap .uabb-image-content img {
    display: inline;
    height: auto !important;
    max-width: 100%;
    width: auto;
    border-radius: inherit;
    box-shadow: none;
    box-sizing: content-box;
}

.fl-builder-content .uabb-imgicon-wrap .uabb-image-crop-circle img {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.fl-builder-content .uabb-imgicon-wrap .uabb-image-crop-square img {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.uabb-creative-button-wrap a,
.uabb-creative-button-wrap a:visited {
    background: #fafafa;
    border: 1px solid #ccc;
    color: #333;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    position: relative;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
}

.uabb-creative-button-wrap a:focus {
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
}

.uabb-creative-button-wrap a .uabb-creative-button-text,
.uabb-creative-button-wrap a .uabb-creative-button-icon,
.uabb-creative-button-wrap a:visited .uabb-creative-button-text,
.uabb-creative-button-wrap a:visited .uabb-creative-button-icon {
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
}

.uabb-creative-button-wrap a:hover {
    text-decoration: none;
}

.uabb-creative-button-wrap .uabb-creative-button-width-full .uabb-creative-button {
    display: block;
    text-align: center;
}

.uabb-creative-button-wrap .uabb-creative-button-width-custom .uabb-creative-button {
    display: inline-block;
    text-align: center;
    max-width: 100%;
}

.uabb-creative-button-wrap .uabb-creative-button-left {
    text-align: left;
}

.uabb-creative-button-wrap .uabb-creative-button-center {
    text-align: center;
}

.uabb-creative-button-wrap .uabb-creative-button-right {
    text-align: right;
}

.uabb-creative-button-wrap .uabb-creative-button i {
    font-size: 1.3em;
    height: auto;
    vertical-align: middle;
    width: auto;
}

.uabb-creative-button-wrap .uabb-creative-button .uabb-creative-button-icon-after {
    margin-left: 8px;
    margin-right: 0;
}

.uabb-creative-button-wrap .uabb-creative-button .uabb-creative-button-icon-before {
    margin-right: 8px;
    margin-left: 0;
}

.uabb-creative-button-wrap.uabb-creative-button-icon-no-text .uabb-creative-button i {
    margin: 0;
}

.uabb-creative-button-wrap .uabb-creative-button-has-icon .uabb-creative-button-text {
    vertical-align: middle;
}

.uabb-creative-button.uabb-creative-transparent-btn {
    background: transparent;
}

.uabb-creative-button.uabb-creative-transparent-btn:after {
    content: '';
    position: absolute;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
}

.uabb-transparent-fill-top-btn:after,
.uabb-transparent-fill-bottom-btn:after {
    width: 100%;
    height: 0;
    left: 0;
}

.uabb-transparent-fill-top-btn:after {
    top: 0;
}

.uabb-transparent-fill-bottom-btn:after {
    bottom: 0;
}

.uabb-transparent-fill-left-btn:after,
.uabb-transparent-fill-right-btn:after {
    width: 0;
    height: 100%;
    top: 0;
}

.uabb-transparent-fill-left-btn:after {
    left: 0;
}

.uabb-transparent-fill-right-btn:after {
    right: 0;
}

.uabb-transparent-fill-center-btn:after {
    width: 0;
    height: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.uabb-transparent-fill-diagonal-btn:after,
.uabb-transparent-fill-horizontal-btn:after {
    width: 100%;
    height: 0;
    top: 50%;
    left: 50%;
}

.uabb-transparent-fill-diagonal-btn {
    overflow: hidden;
}

.uabb-transparent-fill-diagonal-btn:after {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.uabb-transparent-fill-horizontal-btn:after {
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.uabb-creative-button-wrap .uabb-creative-threed-btn.uabb-threed_down-btn,
.uabb-creative-button-wrap .uabb-creative-threed-btn.uabb-threed_up-btn,
.uabb-creative-button-wrap .uabb-creative-threed-btn.uabb-threed_left-btn,
.uabb-creative-button-wrap .uabb-creative-threed-btn.uabb-threed_right-btn {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.perspective {
    -webkit-perspective: 800px;
    -moz-perspective: 800px;
    perspective: 800px;
    margin: 0;
}

.uabb-creative-button.uabb-creative-threed-btn:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    transition: all 200ms linear;
}

.uabb-creative-button.uabb-creative-threed-btn {
    outline: 1px solid transparent;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.uabb-creative-threed-btn.uabb-animate_top-btn:after {
    height: 40%;
    left: 0;
    top: -40%;
    width: 100%;
    -webkit-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    transform: rotateX(90deg);
}

.uabb-creative-threed-btn.uabb-animate_bottom-btn:after {
    width: 100%;
    height: 40%;
    left: 0;
    top: 100%;
    -webkit-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    -ms-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
}

.uabb-creative-threed-btn.uabb-animate_left-btn:after {
    width: 20%;
    height: 100%;
    left: -20%;
    top: 0;
    -webkit-transform-origin: 100% 0%;
    -moz-transform-origin: 100% 0%;
    -ms-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -webkit-transform: rotateY(-60deg);
    -moz-transform: rotateY(-60deg);
    -ms-transform: rotateY(-60deg);
    transform: rotateY(-60deg);
}

.uabb-creative-threed-btn.uabb-animate_right-btn:after {
    width: 20%;
    height: 100%;
    left: 104%;
    top: 0;
    -webkit-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: rotateY(120deg);
    -moz-transform: rotateY(120deg);
    -ms-transform: rotateY(120deg);
    transform: rotateY(120deg);
}

.uabb-animate_top-btn:hover {
    -webkit-transform: rotateX(-15deg);
    -moz-transform: rotateX(-15deg);
    -ms-transform: rotateX(-15deg);
    -o-transform: rotateX(-15deg);
    transform: rotateX(-15deg);
}

.uabb-animate_bottom-btn:hover {
    -webkit-transform: rotateX(15deg);
    -moz-transform: rotateX(15deg);
    -ms-transform: rotateX(15deg);
    -o-transform: rotateX(15deg);
    transform: rotateX(15deg);
}

.uabb-animate_left-btn:hover {
    -webkit-transform: rotateY(6deg);
    -moz-transform: rotateY(6deg);
    -ms-transform: rotateY(6deg);
    -o-transform: rotateY(6deg);
    transform: rotateY(6deg);
}

.uabb-animate_right-btn:hover {
    -webkit-transform: rotateY(-6deg);
    -moz-transform: rotateY(-6deg);
    -ms-transform: rotateY(-6deg);
    -o-transform: rotateY(-6deg);
    transform: rotateY(-6deg);
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn,
.uabb-creative-flat-btn.uabb-animate_to_left-btn,
.uabb-creative-flat-btn.uabb-animate_from_top-btn,
.uabb-creative-flat-btn.uabb-animate_from_bottom-btn {
    overflow: hidden;
    position: relative;
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn i,
.uabb-creative-flat-btn.uabb-animate_to_left-btn i,
.uabb-creative-flat-btn.uabb-animate_from_top-btn i,
.uabb-creative-flat-btn.uabb-animate_from_bottom-btn i {
    bottom: 0;
    height: 100%;
    margin: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    width: 100%;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    transition: all 200ms linear;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn .uabb-button-text,
.uabb-creative-flat-btn.uabb-animate_to_left-btn .uabb-button-text,
.uabb-creative-flat-btn.uabb-animate_from_top-btn .uabb-button-text,
.uabb-creative-flat-btn.uabb-animate_from_bottom-btn .uabb-button-text {
    display: inline-block;
    width: 100%;
    height: 100%;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn i:before,
.uabb-creative-flat-btn.uabb-animate_to_left-btn i:before,
.uabb-creative-flat-btn.uabb-animate_from_top-btn i:before,
.uabb-creative-flat-btn.uabb-animate_from_bottom-btn i:before {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn:hover i,
.uabb-creative-flat-btn.uabb-animate_to_left-btn:hover i {
    left: 0;
}

.uabb-creative-flat-btn.uabb-animate_from_top-btn:hover i,
.uabb-creative-flat-btn.uabb-animate_from_bottom-btn:hover i {
    top: 0;
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn i {
    top: 0;
    left: -100%;
}

.uabb-creative-flat-btn.uabb-animate_to_right-btn:hover .uabb-button-text {
    -webkit-transform: translateX(200%);
    -moz-transform: translateX(200%);
    -ms-transform: translateX(200%);
    -o-transform: translateX(200%);
    transform: translateX(200%);
}

.uabb-creative-flat-btn.uabb-animate_to_left-btn i {
    top: 0;
    left: 100%;
}

.uabb-creative-flat-btn.uabb-animate_to_left-btn:hover .uabb-button-text {
    -webkit-transform: translateX(-200%);
    -moz-transform: translateX(-200%);
    -ms-transform: translateX(-200%);
    -o-transform: translateX(-200%);
    transform: translateX(-200%);
}

.uabb-creative-flat-btn.uabb-animate_from_top-btn i {
    top: -100%;
    left: 0;
}

.uabb-creative-flat-btn.uabb-animate_from_top-btn:hover .uabb-button-text {
    -webkit-transform: translateY(400px);
    -moz-transform: translateY(400px);
    -ms-transform: translateY(400px);
    -o-transform: translateY(400px);
    transform: translateY(400px);
}

.uabb-creative-flat-btn.uabb-animate_from_bottom-btn i {
    top: 100%;
    left: 0;
}

.uabb-creative-flat-btn.uabb-animate_from_bottom-btn:hover .uabb-button-text {
    -webkit-transform: translateY(-400px);
    -moz-transform: translateY(-400px);
    -ms-transform: translateY(-400px);
    -o-transform: translateY(-400px);
    transform: translateY(-400px);
}

.uabb-tab-acc-content .wp-video,
.uabb-tab-acc-content video.wp-video-shortcode,
.uabb-tab-acc-content .mejs-container:not(.mejs-audio),
.uabb-tab-acc-content .mejs-overlay.load,
.uabb-adv-accordion-content .wp-video,
.uabb-adv-accordion-content video.wp-video-shortcode,
.uabb-adv-accordion-content .mejs-container:not(.mejs-audio),
.uabb-adv-accordion-content .mejs-overlay.load {
    width: 100% !important;
    height: 100% !important;
}

.uabb-tab-acc-content .mejs-container:not(.mejs-audio),
.uabb-adv-accordion-content .mejs-container:not(.mejs-audio) {
    padding-top: 56.25%;
}

.uabb-tab-acc-content .wp-video,
.uabb-tab-acc-content video.wp-video-shortcode,
.uabb-adv-accordion-content .wp-video,
.uabb-adv-accordion-content video.wp-video-shortcode {
    max-width: 100% !important;
}

.uabb-tab-acc-content video.wp-video-shortcode,
.uabb-adv-accordion-content video.wp-video-shortcode {
    position: relative;
}

.uabb-tab-acc-content .mejs-container:not(.mejs-audio) .mejs-mediaelement,
.uabb-adv-accordion-content .mejs-container:not(.mejs-audio) .mejs-mediaelement {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.uabb-tab-acc-content .mejs-overlay-play,
.uabb-adv-accordion-content .mejs-overlay-play {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto !important;
    height: auto !important;
}

.uabb-creative-button-wrap a,
.uabb-creative-button-wrap a:visited {}

.uabb-dual-button .uabb-btn,
.uabb-dual-button .uabb-btn:visited {}

.uabb-js-breakpoint {
    content: "default";
    display: none;
}

@media screen and (max-width: 1000px) {
    .uabb-js-breakpoint {
        content: "1000";
    }
}

@media screen and (max-width: 800px) {
    .uabb-js-breakpoint {
        content: "800";
    }
}

.fl-builder-row-settings #fl-field-separator_position {
    display: none !important;
}

.fl-builder-row-settings #fl-field-separator_position {
    display: none !important;
}

.fl-node-5beaa363cf81a > .fl-col-content {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -webkit-transition: -webkit-box-shadow 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    -moz-transition: -moz-box-shadow 500ms ease-in-out, -moz-transform 500ms ease-in-out;
    transition: box-shadow 500ms ease-in-out, transform 500ms ease-in-out;
    will-change: box-shadow;
}

.fl-node-5beaa36ec9b8d > .fl-col-content {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
    -webkit-transition: -webkit-box-shadow 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    -moz-transition: -moz-box-shadow 500ms ease-in-out, -moz-transform 500ms ease-in-out;
    transition: box-shadow 500ms ease-in-out, transform 500ms ease-in-out;
    will-change: box-shadow;
}

@media only screen and (min-width: 641px){
.gform_wrapper form.gf_simple_horizontal div.gform_body {
    width: auto;
    max-width: 75%;
}

.gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label.form_sublabel_above li.gfield, .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label.form_sublabel_below li.gfield {
    vertical-align: middle;
}
}
