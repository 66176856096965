.modal {
  z-index: 9999 !important;
}
.modal > div {
  display: block;
  width: 90vw;
  max-width: 800px;
}
.modal > div > div {
  padding: 10px 30px;
  overflow-x: auto;
  background-repeat: no-repeat;
  border-radius: 15px;
  border: none;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 60px;
  width: 90vw;
  max-width: 800px;
  margin-left: 0;
}
.modal > div > div > div {
  border-bottom: none !important;
}
.modal > div > div > div > div {
  font-size: 21px !important;
}
@media (max-width: 500px) {
  #ModalWrapper .form-control {
    font-size: 14px;
  }
}
