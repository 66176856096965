.FaqAccordionCol .card {
  margin-bottom: 10px;
  border: 1px solid #134364!important;
  border-radius: 0;
  cursor: pointer;
  color: #193e6c;;
  background-color: #fff;
  font-size: 1rem;
}

.FaqAccordionCol h2 {
color: #28ace2;
}

.FaqAccordionCol .card:hover, .FaqAccordion .card:focus {
  background-color: #f2f2f2!important;
}


.FaqAccordionCol .card-header {
  display: flex;
  background-color: transparent;
  border-bottom: none;
  color: #193e6c;
  font-size: 20px;
  justify-content: space-between;
}

.FaqAccordionCol .card-header:hover, .FaqAccordion .card-header:focus {
  color: #134364; 
}

.faq-accordion .card {
  display: flex;
  flex-wrap: wrap;
}

.faq-question {
  width: 100%;
  flex-basis: 100%;
  order: 1;
}

.faq-answer {
  order: 2;
}

/* Style for when the body is closed */
.faq-question::after {
  color: grey;
  content:"+" ;
  font-weight: normal;
  font-size: 1.5rem;
}
.faq-last:last-child::after {
  position: relative;
  bottom: 12px;
}

/* Style for header when body is fully open */
.faq-answer.show + .faq-question::after {
  content:"\2013" ;
}
.show {
  display: block;
}
.mrgnbtm10{
margin-bottom: 10px;
}
@media only screen and (max-width: 991.97px) {
  .FaqAccordionCol h2 {
    text-align: center;
  }
}

@media only screen and (max-width: 768.97px) {
  .FaqAccordionCol .card-header, .FaqAccordionCol .card-body {
    font-size: 15px;
  }
  .faq-question::after {
    padding: .0em .35em;
    margin-right: -12px;
  }
  .faq-answer.show + .faq-question::after {
    padding: .0em .4em;
  }
}

.card-body{
  padding: .25rem 1rem;
}


/*FaqAccordion remove extra space when screen is minimize 
and after open any accordian option then it not over lapping on below UI*/
@media only screen 
and (max-width : 991px) 
{
    .FaqAccordionCol.mb-5.mrgncustom.col-lg-6.col-md-12.col-sm-12 
    {
        margin-bottom: 0 !important;
    }
}

@media screen and (min-width: 768px) {
  .FaqAccordionCol {
    padding-right: 30px !important;
  }
}