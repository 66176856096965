#howItWorks-wrapper .layout-column {
  position: relative;
  margin-bottom: 20px;
  margin-left: 10px;
  border: 2px solid #2d3691;
  padding: 0px;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 270px;
}

#howItWorks-wrapper .howItWorksCol {
  padding-left: 0px;
  padding-right: 0px;
}

#howItWorks-wrapper .content-boxes {
  background-color: rgba(255, 255, 255, 0);
  overflow: visible;
}

#howItWorks-wrapper .content-boxes .heading .image {
  display: block;
  position: absolute;
  left: 50%;
}

#howItWorks-wrapper .content-boxes .content-wrapper-boxed {
  overflow: visible;
  text-align: center;
}

#howItWorks-wrapper .content-boxes .heading {
  overflow: visible;
  position: relative;
  text-align: center;
}

#howItWorks-wrapper h2 {
  color: #212529;
}

#howItWorks-wrapper .content-boxes .heading h2 {
  color: #333;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
}

#howItWorks-wrapper .content-container p {
  color: #212529;
  font-size: 1rem;
  margin-bottom: 0;
}

/* @media only screen and (max-width: 998.97px) {
      #howItWorks-wrapper .content-boxes,#howItWorks-wrapper .content-boxes .content-wrapper-boxed {
          min-height: 290px;
      }
   #howItWorks-wrapper .layout-column {
      min-height: unset;
   }
  }
  @media only screen and (max-width: 768.97px) {
      #howItWorks-wrapper .content-boxes,#howItWorks-wrapper .content-boxes .content-wrapper-boxed {
          min-height: 150px;
      }
  } */

/* 
@media only screen and (min-width: 992px) {
  .howItWorksCol.col-lg-3.col-md-6.col-sm-12 .layout-column .content-boxes {
    height: max-content !important;
    min-height: 310px !important;
  }
  .howItWorksCol.col-lg-3.col-md-6.col-sm-12 .layout-column {
    min-height: max-content !important;   
  }
}
*/

#howItWorks-wrapper .content-boxes .content-wrapper-boxed {
  padding: 50px 10px 10px;
}

@media only screen and (max-width: 767.97px) {
  #howItWorks-wrapper .layout-column {
    margin-left: 20px;
    margin-right: 20px;
  }
}
