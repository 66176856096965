#ModalWrapper .modal-content {
  padding: 10px 30px !important;
  margin-bottom: 0 !important;
  margin-top: 8rem !important;
}
#generateLead .form-select{
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
#ModalWrapper .form-group {
  margin-bottom: 0px !important;
  
}
#ModalWrapper input:not([type="radio"]),
#ModalWrapper textarea:not([type="radio"]),
#ModalWrapper select {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(73, 80, 87);
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 1px solid rgb(206, 212, 218);
  border-radius: 0.25rem;
  border-left: 2px solid #1d90cc !important;
  border-right: 2px solid #1d90cc !important;
  box-shadow: 0 0 1px 0 rgba(163, 206, 214, 0.8),
    0 1px 1px 0 rgba(163, 206, 214, 0.8);
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  font-family: "Roboto" !important;
  
}
/* SWMC-800 - Pavan's request to hide states dropdown */
/* #ModalWrapper select {
  display: none;
} */
#ModalWrapper #firstName,
#ModalWrapper #lastName,
#ModalWrapper #email,
#ModalWrapper #phone,
#ModalWrapper #state {
  margin-bottom: 10px;
}
#ModalWrapper .blg-col-0 {
  display: none;
}
#ModalWrapper .blg-col-1,
#ModalWrapper .blg-col-2,
#ModalWrapper .blg-col-3,
#ModalWrapper .blg-col-4,
#ModalWrapper .blg-col-5 {
  margin-bottom: 16px !important;
}
#ModalWrapper #phone {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(79 92 105);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
#ModalWrapper #phone::placeholder {
  color: rgb(112, 112, 113);
  opacity: 1;
}
#ModalWrapper .blg-col-6 {
  display: none;
}
#ModalWrapper h6 {
  padding-top: 0 !important;
  padding-bottom: 2px;
  margin-bottom: 2px;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 700;
  color: #000000;
}
#ModalWrapper p {
  margin: 0;
  padding: 0;
  font-size: 12px;
    margin-bottom: 6px;
}
#ModalWrapper .checkIConsent.form-check {
  position: relative;
  font-size: 12px !important;
  max-height: 10px;
  display: inline-flex;
  align-items: flex-start;
  padding-left: 19px !important;
}
#ModalWrapper input[type="radio"] {
  font-size: 12px;
  position: absolute;
  top: 0px;
  left: 17px;
}
#ModalWrapper label[for="iConsent"],
#ModalWrapper label[for="iDoNotConsent"] {
  font-size: 12px;
  position: relative;
  top: 100%;
  left: 0;
  font-weight: 700;
  font-family: "Roboto";
}
#ModalWrapper .invalid-feedback {
  font-family: "Roboto";
  font-size: 16px;
}
#ModalWrapper .btn-primary {
  font-size: 14px !important;
  font-family: "Roboto";
}
#ModalWrapper h6,
#ModalWrapper p.text-justify {
  font-style: 10px !important;
  font-family: "Roboto";
}
#ModalWrapper #ModalWrapper .consent-font {
  margin: 50px !important;
}
#ModalWrapper .formHeader {
  margin: 10px 0 5px 0;
  color: #00aae4;
  font-family: "Helvetica Neue", "Open Sans", Helvetica, Arial, sans-serif;
  font-size: clamp(18px, 4vw, 22px) !important;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 34px;
}
#ModalWrapper .btn-submit {
  margin: 16px auto 0;
  border: none;
}
#ModalWrapper .btn-submit:hover {
  background-color: #0069d9;
}
#ModalWrapper .blg-col-9 {
  margin: -10px 0 0px !important;
}
#ModalWrapper .blg-col-11 {
  padding-top: 0 !important;
  margin-bottom: 7px !important;
}
#ModalWrapper .btn-submit {
  font-size: 14px !important;
  font-family: "Roboto" !important;
  text-transform: capitalize;
}
#ModalWrapper .btn-blg,
#ModalWrapper .btn-start {
  padding: 0.25em 0.5em;
}



@media (max-width: 768px) {
  #ModalWrapper .formHeader {
    font-size: 18px;
    text-align: center;
  }
}

@media screen and (min-width: 576px) {
  #ModalWrapper .blg-col-12 h4,
  #ModalWrapper .blg-col-13 h4 {
    font-size: clamp(13px, calc(1.6vw + 4px), 18px);
    padding-bottom: 0.75em;
    margin: 0;
    font-family: "Roboto" !important;
  }
  #ModalWrapper .btn-blg,
  #ModalWrapper .btn-start {
    white-space: normal;
    font-size: clamp(9px, 1.6vw, 14px) !important;
    font-family: "Roboto" !important;
    text-transform: capitalize !important;
  }
}

@media screen and (max-width: 575px) {
  #ModalWrapper .blg-col-12 h4,
  #ModalWrapper .blg-col-13 h4 {
    font-size: clamp(14px, calc(3.2vw + 4px), 18px);
    padding-bottom: 0.75em;
    margin: 0;
    font-family: "Roboto" !important;
  }
  #ModalWrapper .btn-blg,
  #ModalWrapper .btn-start {
    white-space: normal;
    font-size: clamp(10px, 3.2vw, 14px) !important;
    font-family: "Roboto" !important;
    text-transform: capitalize !important;
  }
}
