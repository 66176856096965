#joinus-wrapper .fl-row-content-wrap {
    position: relative;
    background-image: url('../images/home-page-img.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
    z-index: 2;
}

#joinus-wrapper .fl-row-content-wrap::before {
    display: table;
    content: " ";
}

#joinus-wrapper .fl-row-content-wrap:after {
    border-radius: inherit;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}

#joinus-wrapper .fl-row-content-wrap:after {
    background-color: rgba(255,255,255,0.3);
}

#joinus-wrapper .fl-row-content-wrap {
    padding-top: 150px;
    padding-right: 0px;
    padding-bottom: 50px;
    padding-left: 0px;
    margin-top: 70px;
}

#joinus-wrapper .uabb-top-row-separator {
    z-index: 9;
}

#joinus-wrapper .fl-module-info-box .fl-module-content {
    position: relative;
    z-index: 2;
}

#joinus-wrapper .uabb-creative-button-wrap a .uabb-creative-button-text {
    transition: unset;
}

#joinus-wrapper .fl-node-5bd1621911321 .uabb-creative-button-wrap a {
    transition: .06 ease-in-out!important;
}

#joinus-wrapper .fl-node-5bd1621911321 .uabb-creative-button-wrap a:hover {
    color: #fff;
    background: #28ace2;
    border: 1px solid #28ace2;
}

#joinus-wrapper .fl-node-5bcdc8eb9e014 {
    position: relative;
    z-index: 2;
}