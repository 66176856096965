#recentBlogs {
    position: relative;
}

#recentBlogs .heading-title {
    font-family: "Arial", Helvetica, Verdana, sans-serif;
    font-weight: 700;
    font-size: 35px;
    letter-spacing: 3px;
    line-height: 1.4;
    margin-top: 10px;
    margin-bottom: 10px;
}

#recentBlogs .pp-content-post .pp-post-title {
    color: #ffffff;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 28px;
}

.pp-content-post.pp-grid-style-4:hover .pp-post-title {
    background: #6393c9;
}

@media only screen and (max-width: 1499.97px) {
    .pp-posts-wrapper .pp-content-post-grid.pp-equal-height {
        height: 450px!important;
    }

    .fl-node-5bd18ed2eaba6 .pp-content-post {
        height: 420px!important;
    }
}

@media only screen and (max-width: 1199.97px) {
    #recentBlogs .pp-posts-wrapper .pp-content-post-grid.pp-equal-height {
        height: 350px!important;
    }

    #recentBlogs .fl-node-5bd18ed2eaba6 .pp-content-post {
        height: 370px!important;
    }

    #recentBlogs .fl-row-content-wrap {
        padding-top: 0px;
        padding-right: 20px;
        padding-bottom: 0px;
        padding-left: 20px;
    }
}

@media only screen and (max-width: 1199.97px) {
    #recentBlogs .pp-posts-wrapper .pp-content-post-grid.pp-equal-height {
        height: 300px!important;
    }

    #recentBlogs .fl-node-5bd18ed2eaba6 .pp-content-post {
        width: 33%;
        height: 300px!important;
        padding-left: 0px;
        padding-right: 5px;
    }
}

@media only screen and (max-width: 767.97px) {
    #recentBlogs .pp-posts-wrapper .pp-content-post-grid.pp-equal-height {
        height: 300px!important;
    }

    #recentBlogs .fl-node-5bd18ed2eaba6 .pp-content-post {
        width: 100%;
        height: 300px!important;
        padding-left: 0px;
        padding-right: 5px;
    }
}