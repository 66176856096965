.gform_wrapper .gfield .gfield_label {
    color: #000000;
    display: block;
    font-size: 16px;
    font-family: "trajan-pro-regular",Verdana, Arial, sans-serif;
    font-weight: 400;
}

.contact-wrapper .contactus-banner-bg-color+div, .contact-wrapper #contactForm {
    background: transparent!important;
}

.contact-wrapper .contactus-banner-bg-color h1 {
    display: none!important;
}
#ContactLead .phoneLink {
    font-size: clamp(32px,8.5vw,56px);
    display: block;
    margin: 0 auto 34px auto;
    width: fit-content;
    padding-top: 50px;
}
#ContactLead .blg-container.container-fluid {
    padding-bottom: 0;
}
#ContactLead .blg-col-0 {
    display: none;
}
@media screen and (min-width: 992px) {
    #ContactLead {
        max-width: 900px;
    }
}
@media screen and (min-width: 768px) {
    #ContactLead .blg-col-1,
    #ContactLead .blg-col-3 {
        padding-right: 4px;
    }
    #ContactLead .blg-col-2,
    #ContactLead .blg-col-4 {
        padding-left: 4px;
    }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
    #ContactLead h4 {
        font-size: 18px;
    }
    #ContactLead .btn-start,
    #ContactLead .btn-blg {
        font-size: 9px;
    }
}
@media screen and (max-width: 575px) {
    #ContactLead h4 {
        font-size: 18px;
    }
    #ContactLead .btn-start,
    #ContactLead .btn-blg {
        font-size: 13px;
    }
}
#ContactLead .form-group {
    margin-bottom: 0;
}
#ContactLead .consent-font {
    font-size: 10px;
}
#ContactLead h6,
#ContactLead .checkIConsent {
    font-size: 12px;
    font-weight: 700;
    color: #000000;
}
#ContactLead .btn-submit {
    padding: 20px 30px;
    border-radius: 0;
    margin-bottom: 0;
    font-size: 14px;
    margin-left: -18px;
}

@media screen and (min-width: 320px) and (max-width: 991px)
{
    #ContactLead .btn-submit {
        padding: 5px 5px;
        margin-left: 2px;
    }
}
#ModalWrapper .btn-submit {
    margin-top:1px;
}
@media screen and (min-width: 320px) and (max-width: 370px) {
    #ModalWrapper .btn-submit {
        margin-left:-15px;  
        }
    } 
@media screen and (min-width: 320px) and (max-width: 991px) {
    .modal-content .btn-success {
      padding: 7px 7px !important;
      margin-left: 14px !important;
        
    }
  } 
