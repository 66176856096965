.bottomLinks a {
    color: #222222;
    padding: 5px; 
}
.bottomLinks a:hover {
    color: #15549a; 
}

.sub-footer {
    background-color: #104fa0;
    border-top-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    color: #fff !important;
}
.sub-footer h3 {
    font-size: 1.75rem;
    font-weight: 600;
}
.sub-footer h3,
.sub-footer a,
.sub-footer .btn-link,
.sub-footer h3:hover,
.sub-footer a:hover,
.sub-footer .btn-link:hover {
    color: #fff;
}
.sub-footer p {
    font-size: 18px;
}

.sub-footer .react-icons {
    border-radius: 100px;
    font-size: 30px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
}