#mainBanner {
    min-height: 100vh;
    background: url('../images/mainBanner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: scroll;
}

@media only screen and (max-width: 767.97px) {
    .apply_today_btn .pp-button-wrap .pp-button::before, .apply_today_btn .pp-button-wrap .pp-button::after{display:none!important;}
}


