@media (min-width: 1380px) {
  .footerrow1 {
    min-width: 100%;
    max-width: 100%;
    padding: 0 50px;
  }
  .footerwrapper .awardsSecondCol.col-md-5.col-sm-12.col-12 {
    flex: 28%;
    max-width: 28%;
  }
  .footer_link_banner {
    max-width: 100%;
    padding: 0 50px;
  }
}
@media (min-width: 1200px) and (max-width: 1379px) {
  .footerrow1 {
    max-width: 1140px !important;
    padding: 0 15px;
    margin: auto;
  }
  .footer_link_banner {
    max-width: 1140px;
    padding: 0 15px;
    margin: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .footerrow1 {
    max-width: 960px !important;
    padding: 0 15px;
    margin: auto;
  }
  .footer_link_banner {
    max-width: 960px;
    padding: 0 15px;
    margin: auto;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .footerrow1 {
    max-width: 720px !important;
    padding: 0 15px;
    margin: auto;
  }
  .footer_link_banner {
    max-width: 720px;
    padding: 0 15px;
    margin: auto;
  }
}
footer {
  background-color: #083f88;
  color: #fff;
  font-size: 12px;
  padding: 20px 0;
}
.ul_footer {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.copyrighttxt p {
  font-size: 12px;
}
.ul_footer a {
  text-decoration: none !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.ul_footer a:hover {
  text-decoration: underline !important;
}
.footcont {
  border-top: 1px solid #fff;
  margin-bottom: 45px;
}
.footer_logo {
  width: 280px;
  height: 48px;
}
.ul_footer li p {
  /* margin: 0; */
  margin-bottom: 10px;
  padding: 0 15px;
  font-size: 15px;
}
.ul_footer p {
  /* margin: 0; */
  margin-bottom: 10px;
  padding: 0 15px;
  font-size: 15px;
}
.ul_footer li i {
  margin-top: 7px;
  width: 10px;
}
.ul_footer li {
  display: flex;
}
.container_l {
  padding: 0 118px;
}
.main_text_container {
  display: flex;
  font-size: 16px;
  line-height: 30px;
  padding-bottom: 36px;
  padding-top: 33px;
}
.main_text_container_left {
  width: 60%;
}
.main_text_container_left .footer_desc_text {
  width: 90%;
}
.main_text_container_right {
  width: 40%;
}
.main_text_container_right .ul_footer {
  width: 311px;
}
.footer_desc_text {
  margin-top: 0px;
  font-size: 15px;
  margin-top: 10px;
}
.footer_desc_text a {
  color: #fff;
  text-decoration: underline;
}
.footer_desc_text a,
.footer_desc_text p,
.footer_desc_text b {
  font-size: 15px;
}
.padleft0 {
  padding-left: 0px !important;
}
.padright0 {
  padding-right: 0px !important;
}
.linklist {
  justify-content: flex-end;
}
.homicon {
  border-radius: 8px;
  width: 60px;
  margin: 10px;
  margin-left: 20px;
  height: 68px;
}
.footer_link_banner {
  border-top: 1px solid #083f88;
  margin-top: 10px;
}
.footer_link_banner .container_l {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.footer_copyright {
  height: 62px;
  display: flex;
  align-items: center;
}
.footer_link_banner .ul_footer li {
  min-width: 40px;
}
.footer_link_banner .ul_footer li .ul_footer i {
  font-size: 23px;
  margin-top: 0px;
}
.footer_link_banner .ul_footer {
  display: flex;
  padding: 10px 0px;
}
.footer_link_banner .footer_list:nth-child(1) li {
  padding: 0 9px;
  border-right: solid #fff 1px;
}
.footer_link_banner .ul_footer:nth-child(1) li:last-child {
  border: none;
}
.contactus h4 {
  margin-bottom: 26px;
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 24px;
  padding: 0;
}
.contactus .ul_footer a {
  font-size: 15px;
}
.awardsSecondCol {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.awardsReviewCol {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: end;
  padding: 0;
}
.awardrow > a:nth-child(3) img {
  margin-right: 0 !important;
}
.topIcons {
  max-width: 125px;
  margin: 15px 0;
  height: 180px;
  width: 114px;
}
.ehoIcon {
  max-width: 55px;
}
.memberIcon {
  max-width: 125px;
  width: 125px;
  height: 70px;
  margin: 20px 10px 10px 10px;
}
.reviewIcon {
  margin: 20px 0 0 20px;
  max-width: 145px !important;
  height: 75px;
}
.ehoBirdeye {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.social_footer {
  /* margin-left: 16px; */
  margin-top: 10px;
}
.social_footer i {
  font-size: 23px;
  color: #fff;
  margin-right: 20px;
}
.ul_footer li .ul_footer i {
  color: #fff;
}
.reviewIcon {
  max-width: 127px;
}
.birdeye {
  width: 100%;
  font-size: 12px;
}
.birdeye a {
  color: #007bff;
  text-decoration: none !important;
}
.birdeye a:hover {
  text-decoration: underline !important;
}
.bbbIcon {
  margin: 20px 0px 10px 0px;
  max-width: 140px;
  width: 140px;
  height: 69px;
}
.changePadMobile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact_block {
  margin-top: 8%;
  margin-left: 10%;
}
.social_footer {
  margin: 0 auto;
}
.contactOptions {
  display: flex;
}
.email {
  display: flex;
  margin-left: inherit;
}
.copyrighttxt i {
  margin: 0px 5px;
  font-size: 18px;
}
.Taca {
  margin-top: 20px;
  width: 100%;
  max-width: 350px;
}
.mobileBbbMember {
  flex-direction: column !important;
}
.cellLogoDiv {
  text-align: end;
}
.tacaDiv {
  justify-content: center;
}
@media (max-width: 1199px) {
  .cellLogoDiv {
    text-align: center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .Taca {
    margin-left: -10%;
    max-width: 400px;
  }
}
@media (min-width: 1200px) {
  .Taca {
    margin-top: 11%;
    height: 57px;
  }
}
@media screen and (min-width: 767px) and (max-width: 1200px) {
  .copyrighttxt,
  .dislinks {
    flex: 0 0 100%;
    min-width: 100% !important;
    text-align: center;
  }
  .footer_link_banner .ul_footer {
    justify-content: center;
  }
  .footcont {
    border-top: 1px solid #fff;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .copyrighttxt p,
  .ul_footer a {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .footerrow1 {
    max-width: 540px !important;
    padding: 0 15px;
    margin: auto;
  }
  .footer_link_banner {
    max-width: 540px !important;
    padding: 0 15px;
    margin: auto;
    margin-top: 10px;
  }
  .bbbIcon {
    margin-right: 10px;
  }
  .footer_list {
    flex-direction: column;
  }
  .footer_list li {
    border-right: none !important;
  }
  .footcont {
    flex-direction: column-reverse;
  }
  .footer_link_banner .ul_footer li {
    padding: 4px 0px !important;
  }
  .imagearard {
    margin-top: 20px;
  }
  .padleft0 {
    padding-left: 15px !important;
  }
  .padright0 {
    padding-right: 15px !important;
  }
  .linklist {
    justify-content: flex-start;
  }
  .topIcons {
    /* margin-right: 25px; */
    /* max-width: 79px; */
    max-width: 130px;
    margin: 15px auto;
    display: block;
    height: 180px;
  }
  .awardsSecondCol {
    flex-direction: column;
  }
  .copyrighttxt p,
  .ul_footer a {
    font-size: 15px;
  }
}
@media screen and (min-width: 320px) and (max-width: 575px) {
  .awardsSecondCol {
    /* flex-direction: row; */
    flex-direction: column;
    justify-content: left;
    text-align: left;
    margin: 20px 0;
    padding: 0 !important;
  }
  .awardrow {
    padding: 0 20px;
  }
  .awardsHeader {
    padding: 0 20px;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .awardsSecondCol {
    padding: 0;
  }
  .Taca {
    margin: 20px auto 0 auto !important;
  }
  .tacaDiv a {
    width: fit-content;
  }
}
@media screen and (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1000px;
  }
}
@media screen and (max-width: 992px) {
  .birdeye {
    margin: 0 !important;
    text-align: left;
  }
}
@media (max-width: 767px) {
  .memberIcon {
    margin: 0 auto;
    margin-top: 20px;
  }
  .bbbIcon {
    margin: 0 auto;
    margin-top: 20px;
  }
  .birdeye {
    width: 100%;
    margin-left: 0;
    text-align: center !important;
  }
  .homicon {
    margin-left: 0 !important;
  }
  .homicon {
    width: 75px;
  }
  .setPad {
    padding: 1% 8%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .ehoBirdeye {
    padding-top: 30px;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .memberDiv {
    text-align: center !important;
  }
  .changePadMobile {
    padding: 0;
  }
  .social_footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contactus h4 {
    text-align: center;
    color: #ffffff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    font-size: 24px;
    font-weight: 500;
  }
  .logosize {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: auto;
    min-width: 280px;
  }
  .footer_link_banner .ul_footer {
    font-size: 15px;
  }
  .contact_block {
    margin-left: 0;
  }
  .contactOptions {
    display: block;
  }
  .email {
    margin-left: auto;
  }
  .copyrighttxt {
    text-align: center;
  }
}
@media (max-width: 575px) {
  .awardrow {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .topIcons {
    margin: 0 auto !important;
    margin-bottom: 20px !important;
  }
  .bbbIcon {
    margin-top: 0px !important;
  }
  .footer_desc_text {
    text-align: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .footer_link_banner .footer_list:nth-child(1) li {
    padding: 0 5px;
    border-right: solid #fff 1px;
  }
  .ul_footer p {
    margin-bottom: 10px;
    padding: 0 5px !important;
    font-size: 15px;
  }
}
@media screen and (min-width: 320px) and (max-width: 575px) {
  .Taca {
    margin-top: 15px;
  }
}
.birdeyeContainer > p {
  margin-bottom: 0px;
}
