.requestCallback-form-row {
    position: relative;
    background-color: #104fa0;
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: 0px;
    padding-bottom: 0px;
    /*50px;*/
    /* padding-left: 30px; */
    color: #fff;
    z-index: 10;
    width: 100%;
    margin: 0 auto;
}

.form-col {
    background-color: #104fa0;
    padding: 8% 0px 0px 0px;
    border-radius: 50%;
}

.requestCallback-form-row h2 {
    font-size: 1.75rem;
    line-height: 25px;
    /* margin-bottom: 15px!important; */
}

.requestCallback-form-row p {
    padding-bottom: 2rem;
}

.requestCallback-form-row .col-wrapper {
    margin: -60px auto 20px auto;
    width: 60%;
}

.requestCallback-form-row .text-wrapper {
    overflow: visible;
    padding: 20px 35px 2px;
    /*0px 35px 35px;*/
    text-align: center;
}

.requestCallback-form-row form .form-group {
    margin-top: 8px;
}

/* .requestCallback-form-row form .form-control {
    height: 45px;
    border-radius: 22px;
    padding-left: 50px !important;
    border: 1px solid transparent;
} */

.requestCallback-form-row .contact-btn:hover,
.requestCallback-form-row .contact-btn:focus {
    background: transparent;
    color: #fff;
}

.consentDesc p {
    padding-bottom: 0px !important;
}

.react-icons {
    vertical-align: middle;
    font-size: 2.8rem;
}

@media only screen and (max-width: 998.97px) {
    .requestCallback-form-row .col-wrapper {
        margin: -60px auto 20px auto;
        width: 100%;
    }

    .requestCallback-form-row .text-wrapper {
        padding: 0px 15px 2px;
        /*0px 15px 35px;*/
    }
}

.text-wrapper p {
    margin-bottom: 0.25rem;
}

.text-wrapper h2 {
    margin-bottom: 0.5rem !important;
}


.requestCallback-banner-bg-color {
    background-color: #0072bc;
}

.d-flex.justify-content-center.poppins-bold.form-row {
    text-align: center;
}

/*call Me Form */

.callMe-Form {
    min-height: 210px;
    /*184px;*/
    width: 100%;
    background-color: #104fa0;
    border-radius: .5rem;
}

#frmCallMeForm {
    border-color: transparent;
}